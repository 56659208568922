import React, { useState, useEffect } from "react";
import { Input, Form, Select, DatePicker, Space, Checkbox, Button } from "antd";
import { Modal, Container, Row, Col } from "react-bootstrap";

import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import "antd/dist/antd.css";
import axios from "axios";
import { APILINK } from "../../Endpoint";
import TextArea from "antd/lib/input/TextArea";
import { ToastContainer, toast } from "react-toastify";

const axiosApiInstance = axios.create();

function SupplierCreateOffer({
  show,
  hide,
  chosenProduct,
  closeForm,
  id,
  onConfirmed,
  type,
  cat,
  keys,
  user
}) {
  const [keywords, setKeys] = useState([]);
  const [categories, setCat] = useState([]);
  const [isDeliver, setIsDeliver] = useState(false);

  const getCategories = async () => {
    const result = await axiosApiInstance
      .get(APILINK + "/categories/?limit=10000")
      .then((res) => {
        setCat(res.data.results);
      });
  };
  const getKeys = async () => {
    const result = await axiosApiInstance
      .get(APILINK + `/keywords/?limit=10000`)
      .then((result) => setKeys(result.data.results));
  };
  useEffect(() => {
    getKeys();
    getCategories();
  }, []);

  const onFinish = async (values) => {
    console.log(values);

    let data = {
      request: {
        request_type: type,
        request_status: "3",
        is_deliver: false,
        // is_deliver: isDeliver,
        // deliver_time: isDeliver ? values.deliver_time : "",
        // deliver_cost: isDeliver ? values.deliver_cost : "",
        yanzo_price: values.yanzo_price,
        note: values.note
      },
      product: {
        name: values.name,
        code: values.code,
        quantity: values.quantity,
        price:
          chosenProduct !== null ? values.offeredPrice : values.marketPrice,
        supplier: user.user_type === "3" ? user.supplier_id : user.id,
        // keyword: keys.map((key) => key.id),
        // category: cat.map((category) => category.id),
        yanzo_price: values.yanzo_price
      },
      save_as_product: values.save_as_product ?? false
    };

    console.log(data);
    const result = await axiosApiInstance
      .put(APILINK + `/status_request/${id}`, data)
      .catch((err) => {
        console.log(err.response);
        for (const error in err.response.data.error) {
          console.log(err.response.data.error);

          setTimeout(() => {
            toast.error(
              `${
                error +
                " : " +
                err.response.data.error[error][0].charAt(0).toUpperCase() +
                err.response.data.error[error][0].slice(1)
              }`
            );
          }, 500);
        }
      });
    if (result) {
      hide();
      setTimeout(() => {
        toast.info(`Offer has been created successfully`);
      }, 500);
      onConfirmed();
    }
  };
  const handleIsDeliver = (e) => {
    console.log(e);
    setIsDeliver(e.target.checked);
  };
  return (
    <Modal show={show} onHide={hide}>
      <Container className="p-4">
        <Row>
          <Col sm={12}>
            <p style={{ color: "#7c8798", fontSize: "20px" }}>Create Offer</p>
          </Col>
        </Row>
        <Form onFinish={onFinish} layout="vertical">
          <Row>
            <Col sm="12" md="6">
              <Form.Item
                label="Price for Yanzo"
                name="yanzo_price"
                // rules={[
                //   {
                //     required: true,
                //     message: "This field is required"
                //   }
                // ]}
                initialValue={chosenProduct?.yanzo_price}
              >
                <Input
                  defaultValue={chosenProduct?.yanzo_price}
                  disabled={chosenProduct !== null ?? false}
                />
              </Form.Item>
            </Col>
            {chosenProduct !== null ? (
              <Col sm="12" md="6">
                <Form.Item
                  label="Offered Price"
                  name="offeredPrice"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "This field is required"
                  //   }
                  // ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            ) : null}
          </Row>
          <Row>
            <Col sm="12" md="6">
              <Form.Item valuePropName="checked" label="" name="is_deliver">
                <Checkbox values={isDeliver} onChange={handleIsDeliver}>
                  Delivery available?
                </Checkbox>
              </Form.Item>
            </Col>
            {chosenProduct == null ? (
              <Col sm="12" md="6">
                <Form.Item
                  valuePropName="checked"
                  label=""
                  name="save_as_product"
                >
                  <Checkbox>Save as New Product?</Checkbox>
                </Form.Item>
              </Col>
            ) : null}
          </Row>
          {isDeliver ? (
            <Row>
              <Col sm="12" md="6">
                <Form.Item label="Deliver Cost" name="deliver_cost">
                  <Input />
                </Form.Item>
              </Col>
              <Col sm="12" md="6">
                <Form.Item label="Deliver Time" name="deliver_time">
                  <DatePicker
                    style={{
                      width: "100%"
                    }}
                    showTime={{
                      format: "HH:mm"
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          ) : null}

          <Row>
            <Col sm="12" md="6">
              <Form.Item
                initialValue={chosenProduct?.name}
                label="Product Name"
                name="name"
              >
                <Input
                  defaultValue={chosenProduct?.name}
                  disabled={chosenProduct !== null ?? false}
                />
              </Form.Item>
            </Col>
            <Col sm="12" md="6">
              <Form.Item
                initialValue={chosenProduct?.code}
                label="Product Code"
                name="code"
              >
                <Input
                  defaultValue={chosenProduct?.code}
                  disabled={chosenProduct !== null ?? false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="6">
              <Form.Item
                initialValue={chosenProduct?.price}
                label="Offered Price"
                name="marketPrice"
              >
                <Input
                  defaultValue={chosenProduct?.price}
                  disabled={chosenProduct !== null ?? false}
                />
              </Form.Item>
            </Col>
            <Col sm="12" md="6">
              <Form.Item
                initialValue={chosenProduct?.quantity}
                label="Product Quantity"
                name="quantity"
              >
                <Input defaultValue={chosenProduct?.quantity} />
              </Form.Item>
            </Col>
          </Row>
          {/*<Row>*/}
          {/*  <Col sm="12" md="6">*/}
          {/*    <Form.Item label="Keywords" name="keyword">*/}
          {/*      <Select*/}
          {/*        mode="multiple"*/}
          {/*        placeholder="Select keywords"*/}
          {/*        style={{ width: "100%" }}*/}
          {/*        disabled*/}
          {/*        defaultValue={keys.map((key) => key.id)}*/}
          {/*      >*/}
          {/*        {keywords.map((item) => (*/}
          {/*          <Select.Option key={item.id} value={item.id}>*/}
          {/*            {item.name}*/}
          {/*          </Select.Option>*/}
          {/*        ))}*/}
          {/*      </Select>*/}
          {/*    </Form.Item>*/}
          {/*  </Col>*/}
          {/*  <Col sm="12" md="6">*/}
          {/*    <Form.Item label="Categories" name="category">*/}
          {/*      <Select*/}
          {/*        mode="multiple"*/}
          {/*        placeholder="select category"*/}
          {/*        defaultValue={cat.map((category) => category.id)}*/}
          {/*        style={{ width: "100%" }}*/}
          {/*        disabled*/}
          {/*      >*/}
          {/*        {categories.map((item) => (*/}
          {/*          <Select.Option key={item.id} value={item.id}>*/}
          {/*            {item.name}*/}
          {/*          </Select.Option>*/}
          {/*        ))}*/}
          {/*      </Select>*/}
          {/*    </Form.Item>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          <Row>
            <Col sm="12" md="12">
              <Form.Item label="Notes" name="note">
                <TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="12">
              <Button
                style={{ color: "#fff" }}
                className="btn3"
                htmlType="submit"
              >
                Confirm Offer
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </Modal>
  );
}

export default ErrorHandler(SupplierCreateOffer, axiosApiInstance);
