import React, {useEffect, useRef, useState} from "react";
import styles from "./Notifications.module.css";
import {Spin} from "antd";
import NotificationCard from "./NotificationCard";
import axios from "axios";
import {APILINK} from "../../Endpoint";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import useOnScreen from "../../hooks/onscreen";

const axiosApiInstance = axios.create();

function Notifications({className, isCollapsed, newNotification, onChange}) {
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [notificationData, setNotificationData] = useState({
        count: 0,
        notifications: [],
        lastFetchedPage: 0,
    });

    useEffect(() => {
        if (!newNotification) return;
        setNotificationData((prev) => ({
            ...prev,
            count: prev.count + 1,
            notifications: [newNotification, ...prev.notifications],
        }));


    }, [newNotification]);

    useEffect(() => {
        onChange(notificationData.count)
    }, [notificationData.count])
    const lastElementRef = useRef();
    const isLastElementVisible = useOnScreen(lastElementRef);

    async function getNotifications() {
        if (page == notificationData.lastFetchedPage) return;

        setLoading(true);

        const result = await axiosApiInstance
            .get(APILINK + `/notification?page=${page}`)
            .catch((error) => {
                console.log({error});
                setLoading(false);
            });
        console.log({notify: result});

        if (result?.data.results) {
            const newNotifications = result.data.results;
            const count = result.data.count_unread;

            setNotificationData({
                count: page == 1 ? count : notificationData.count,
                notifications: [
                    ...notificationData.notifications,
                    ...newNotifications,
                ],
                lastFetchedPage: page,
            });

            if (result.data.next) setPage(page + 1);


        }
        setLoading(false);
    }

    useEffect(() => {
        getNotifications();
    }, []);

    useEffect(() => {
        if (!isCollapsed || notificationData.count === 0) return;
        setNotificationData(prev => ({
            ...prev,
            notifications: prev.notifications.map(it => ({...it, readed: true}))
        }))
    }, [isCollapsed])

    useEffect(() => {
        if (!loading && isLastElementVisible) getNotifications();
    }, [isLastElementVisible]);
    return (
        <div
            className={`${styles.container} ${
                isCollapsed && styles.collapsed
            } ${className}`}
        >
            <header className={`${styles.title} noselect`}>
                <h1>Notifications</h1>
                <div className={[styles.arrow, styles.headerIcon].join(" ")}>
                    {/*<ArrowIcon />*/}
                </div>
            </header>
            {
                <div className={styles.list}>
                    {notificationData.notifications.length > 0
                        ? notificationData.notifications.map((it) => {
                            // notificationTypes[it.notification_type];
                            return <NotificationCard key={it.id} {...it} />;
                        })
                        : !loading && (
                        <div className={styles.notificationInfoMessage}>
                            No New Notifications.
                        </div>
                    )}
                    <div
                        className={styles.notificationInfoMessage}
                        ref={lastElementRef}
                    >
                        {loading && <Spin/>}
                    </div>
                </div>
            }
        </div>
    );
}

export default ErrorHandler(Notifications, axiosApiInstance);
