import React, { Component } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Tooltip,
  OverlayTrigger,
  Modal
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../style-sheets/products.css";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import { APILINK } from "../../Endpoint";
import { Spin } from "antd";

const axiosApiInstance = axios.create();

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showMsg: false,
      tableData: [],
      keyWord: "",
      nextUrl: null,
      prevUrl: null,
      dis1: true,
      dis2: false,
      columns: [
        {
          name: "Name",
          selector: "name",
          sortable: true,
          right: true
        },
        {
          name: "Type",
          selector: "type",
          sortable: true,
          right: true
        },
        {
          name: "Description",
          selector: "description",
          sortable: true,
          right: true
        },

        {
          name: "Actions",
          button: true,
          cell: (row) => (
            <div className="courierTable">
              <OverlayTrigger
                key={"top"}
                placement={"top"}
                overlay={
                  <Tooltip id={`tooltip-${"top"}`}>Edit category</Tooltip>
                }
              >
                <>
                  {" "}
                  <Link to={`/edit_category/${row.id}`}>
                    {" "}
                    <i class="fas fa-edit"></i>
                  </Link>
                </>
              </OverlayTrigger>
              <i id={row.id} onClick={this.show} class="fas fa-trash px-2"></i>{" "}
            </div>
          )
        }
      ]
    };
  }

  getCategories = async () => {
    this.setState({ loading: true });

    const result = await axiosApiInstance.get(
      APILINK + "/categories/?limit=10"
    );
    if (result) {
      this.setState({ tableData: result.data.results, loading: false });
      console.log("cate", result.data);

      if (result.data.next !== null) {
        this.setState({
          nextUrl: result.data.next
        });
      }

      if (result.data.previous !== null) {
        this.setState({
          prevUrl: result.data.previous
        });
      }
      if (result.data.next === null) {
        this.setState({ dis2: true });
      }
    }
  };

  componentDidMount() {
    this.getCategories();
  }

  getNext = async () => {
    const result = await axiosApiInstance.get(`${this.state.nextUrl}`);

    if (result) {
      console.log(result.data);
      this.setState({
        tableData: result.data.results
        // nextUrl: result.data.next,
        // prevUrl: result.data.previous,
      });
      if (result.data.next !== null) {
        this.setState({
          nextUrl: result.data.next
          // prevUrl: result.data.previous,
        });
      }
      if (result.data.previous !== null) {
        this.setState({
          prevUrl: result.data.previous,
          dis1: false
        });
      }
      if (result.data.next === null) {
        this.setState({ dis2: true, dis1: false });
      }
    }
  };

  getPrev = async () => {
    const result = await axiosApiInstance.get(`${this.state.prevUrl}`);
    if (result) {
      this.setState({
        tableData: result.data.results
        // nextUrl: result.data.next,
        // prevUrl: result.data.previous,
      });
      if (result.data.next !== null) {
        this.setState({
          nextUrl: result.data.next,
          dis2: false

          // prevUrl: result.data.previous,
        });
      }
      if (result.data.previous !== null) {
        this.setState({
          prevUrl: result.data.previous
        });
      }
      if (result.data.previous === null) {
        this.setState({ dis1: true, dis2: false });
      }
    }
  };

  onSearch = (e) => {
    console.log(e.target);
    this.setState({ keyWord: e.target.value });
    let x = this.state.tableData.filter(
      (item) =>
        (item.name &&
          item.name.toLowerCase().includes(e.target.value.toLowerCase())) ||
        (item.username &&
          item.username.toLowerCase().includes(e.target.value.toLowerCase()))
    );
    console.log(x);
    this.setState({ tableData: x });
    if (e.target.value === "") {
      this.getCategories();
    }
  };
  show = (e) => {
    this.setState({ showMsg: true, proId: e.target.id });
    console.log(e.target.id);
  };
  hide = (e) => {
    this.setState({ showMsg: false });
  };

  deleteCategory = async (e) => {
    console.log("deleted");
    // var newID = this.props.user.id.replace(/-/g, "");
    const res = await axiosApiInstance
      .delete(APILINK + `/category/${this.state.proId}`)
      .then((res) =>
        this.setState((state, props) => {
          return { showMsg: false };
        })
      )
      .catch((err) => console.log(err));
    console.log(res);
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState.showMsg !== this.state.showMsg) {
      this.getCategories();
    }
  }
  render() {
    return (
      <div>
        <Container
          style={{
            marginTop: "100px"
            //  marginLeft: "350px"
          }}
          className="pt-3"
        >
          <Row>
            <Col className="mb-4" style={{ textAlign: "initial" }} sm={12}>
              <h2>
                {" "}
                <i
                  style={{ cursor: "pointer" }}
                  class="fas fa-chevron-left px-1"
                  onClick={this.props.history.goBack}
                ></i>{" "}
                <i class="fas fa-sitemap pr-2"></i>Categories{" "}
              </h2>
            </Col>
          </Row>
          <Row>
            <Col sm={8}></Col>
            <Col style={{ textAlign: "end" }} sm={4}>
              <Link to="/add_category">
                {" "}
                <Button className="headBtn"> Add Category</Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              {this.state.loading === false ? (
                <DataTable
                  columns={this.state.columns}
                  data={this.state.tableData}
                  onSelectedRowsChange={this.handleSelected}
                  subHeader
                  subHeaderComponent={
                    <React.Fragment>
                      <Col
                        style={{ textAlign: "initial", color: "#7c8798" }}
                        sm={12}
                        md={6}
                      >
                        {/* {" "}
                      Show{" "}
                      <select
                        onChange={this.handleOption}
                        className="js-select2 px-2 mx-1"
                        name="Action"
                      >
                        <option selected="selected">10</option>
                        <option selected="selected">30</option>
                        <option selected="selected">60</option>
                      </select>
                      entries */}
                      </Col>
                      <Col style={{ textAlign: "right" }} sm={12} md={6}>
                        <label style={{ color: "#7c8798" }}>Search:</label>
                        <input
                          id="search"
                          type="text"
                          placeholder=""
                          aria-label="Search Input"
                          className="seachInput"
                          value={this.state.keyWord}
                          onChange={this.onSearch}
                        />
                      </Col>
                    </React.Fragment>
                  }
                />
              ) : (
                <Col className="text-center" sm={12}>
                  {this.state.loading ? (
                    <Spin tip="Loading..."></Spin>
                  ) : (
                    "No data to be displayed"
                  )}
                </Col>
              )}
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: "initial" }} sm={12}>
              {this.state.tableData.length === 0 ? null : (
                <div className="p-3">
                  {" "}
                  <Button
                    disabled={this.state.dis1}
                    onClick={this.getPrev}
                    className="nextBtn"
                  >
                    <i class="fas fa-angle-double-left"></i> Previous
                  </Button>
                  <Button
                    disabled={this.state.dis2}
                    onClick={this.getNext}
                    className="nextBtn"
                  >
                    Next<i class="fas fa-angle-double-right pl-1"></i>
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </Container>
        <Modal show={this.state.showMsg} onHide={this.hide}>
          <Container className="p-4">
            <Row>
              <Col className="text-center" sm={12}>
                {" "}
                <h6>Are u sure u want to delete this product?</h6>
              </Col>
              <Row style={{ margin: "auto" }}>
                <Col md={12}>
                  <Button onClick={this.deleteCategory} className="headBtn">
                    Delete
                  </Button>
                  <Button className="headBtn" onClick={this.hide}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Row>
          </Container>
        </Modal>
      </div>
    );
  }
}

export default ErrorHandler(Categories, axiosApiInstance);
// export default Categories
