import React, { Component } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import "../../style-sheets/home.css";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ViewMap from "../ViewMap";
import { ProgressBar, Step } from "react-step-progress-bar";
import {
  searchResultSuppliers,
  clearRes,
  agentRequestData,
  getRequestData,
} from "../../global-state/actions/agentRequestAction";
//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import AddMoreSuppliers from "../yanzo-Modals/AddMoreSuppliers";
import SearchCouriers from "../yanzo-Modals/SearchCouriers";
import img from "../../imgs/no-img.png";
import { APILINK } from "../../Endpoint";
import MarketPrice from "../MarketPrice";
import { DatePicker, Form } from "antd";
import RescheduleOrder from "../reschedule_order";

const axiosApiInstance = axios.create();

class TestDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openConfirm: false,
      opencreate: false,
      stillAvailableDone: false,
      showRej: false,
      lastElement: "",
      currentValidate: "",
      currentValidateSupp: {},
      showOffer: false,
      showSuppliers: false,
      suppliers: [],
      prereqData: {},
      is_offer: false,
      percent: 0,
      openCourierModal: false,
      orderStates: {
        1: "Accepted",
        2: "Assigned to Delegate",
        3: "On Pick Up",
        4: "Picked Up",
        5: "On Delivery",
        6: "Delivered",
        7: "Canceled",
        8: "Pending",
      },
    };
  }

  showCourierModal = (e) => {
    this.setState({ openCourierModal: true });
    this.props.getRequestData(this.props.details);
  };
  closeCourier = (e) => {
    this.setState({ openCourierModal: e });
  };
  showOffer = (e) => {
    this.setState({ showOffer: true });
  };
  closeOffer = (e) => {
    this.setState({ showOffer: false });
  };

  closeSuppliers = (e) => {
    this.setState({ showSuppliers: e });
  };
  createOffer = async (e) => {
    const result = await axiosApiInstance.get(
      APILINK + `/create_offer/${e.target.id}`
    );
    // console.log(result);
    if (result) {
      if (result.data.data.id) {
        this.setState({ is_offer: true });
        setTimeout(() => {
          toast.info(`Offer has been accepted`);
        }, 500);
      }
    }
  };

  getRequestsValidate = async (e) => {
    if (this.props.details.RequestsValidate.length !== 0) {
      let req = this.props.details.RequestsValidate.find(
        (item) => item.validate_status === "1"
      );
      console.log(req);
      if (req) {
        this.setState({ lastElement: req });
      }
    } else {
      this.setState({ lastElement: "" });
    }
    // this.setState({ validateData: current_validate });
  };

  componentDidMount() {
    console.log({ details: this.props.details });
    this.getRequestsValidate();
    if (this.props.details) {
      if (this.props.details.request_type === "1") {
        this.setState({ percent: 0 });
      } else if (this.props.details.request_type === "2") {
        this.setState({ percent: 50 });
      } else {
        this.setState({ percent: 100 });
      }

      // this.setState({usedOrder: this.props.details.offer?.})
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.details?.id !== this.props.details?.id) {
      console.log("changeeeeee");
      this.getRequestsValidate();

      if (this.props.details.request_type === "1") {
        this.setState({ percent: 0 });
      } else if (this.props.details.request_type === "2") {
        this.setState({ percent: 50 });
      } else {
        this.setState({ percent: 100 });
      }
    }

    if (prevState.is_offer !== this.state.is_offer) {
      this.setState({ percent: 50 });
    }
  }

  confirmAvail = async (e) => {
    let data = {
      request: {
        validate_status: "2",
      },
    };

    const result = await axiosApiInstance.put(
      APILINK + `/status_validate/${e.target.id}`,
      data
    );
    if (result) {
      console.log(result);
      if (result.data.data.id) {
        this.setState({ stillAvailableDone: true });
        setTimeout(() => {
          toast.info(`confirmed successfully`);
        }, 500);
      }
    }
  };

  rejectAvail = async (e) => {
    let data = {
      request: {
        validate_status: "3",
      },
    };

    const result = await axiosApiInstance.put(
      APILINK + `/status_validate/${e.target.id}`,
      data
    );
    if (result) {
      console.log(result);
      if (result.data.data.id) {
        this.setState({ stillAvailableDone: true });
        setTimeout(() => {
          toast.dark(`Rejected successfully`);
        }, 500);
      }
    }
  };

  getPreReqData = async () => {
    const result = await axiosApiInstance.get(
      APILINK + `/prerequests/?id=${this.props.details.pre_request.id}`
    );
    if (result.data) {
      console.log(result.data[0]);
      let PreData = result.data[0];
      this.setState({ prereqData: result.data[0] });
      let cat = PreData.category.map((c) => c.id);
      let keys = PreData.keyword.map((k) => k.id);

      // let catID = this.props.details.category.map((c) => c.id);
      // let keysID = this.props.details.keyword.map((k) => k.id);
      let link;
      let x = cat.toString();
      var res = x.replace(/,/g, "__");
      let y = keys.toString();
      var res2 = y.replace(/,/g, "__");

      console.log({ res, res2 });

      link = APILINK + `/suppliers/?categories__in=${res}&keywords__in=${res2}`;
      console.log(link);
      const suppliers = await axiosApiInstance.get(`${link}`).then((res) => {
        this.setState({ showSuppliers: true });
        console.log(res.data);
        this.setState({ suppliers: res.data });
      });
      // console.log(suppliers.data);
    }
  };

  rescheduleOrder = (e) => {
    e.preventDefault();
    this.setState({ showReschedule: true });
  };

  rescheduleOrderSubmit = async (data) => {
    console.log({ data, details: this.props.details });
    this.setState({ rescheduleLoading: true });
    const res = await axiosApiInstance
      .put(APILINK + `/order-edit/${this.props.details.id}`, {
        scheduled_date: data.scheduled_date.format("YYYY-MM-DD"),
      })
      .catch((err) => {
        this.setState({
          rescheduleLoading: false,
          rescheduleError: err.response.data?.detail ?? "Something went wrong",
        });
      });

    if (res) {
      console.log(res);
      if (res.data) {
        this.setState({ showReschedule: false });
        setTimeout(() => {
          toast.success(`Order Rescheduled successfully`);
        }, 500);
      }
    }

    this.setState({ rescheduleLoading: false });
  };

  render() {
    console.log(this.props.details);
    // console.log(this.state.is_offer);

    return (
      <div style={{ borderLeft: "solid 1px yellow" }}>
        <Container className="pt-3">
          <Row>
            <Col sm={6}>
              <h4>Order Details</h4>
            </Col>
            <Col sm={6}>
              {this.props.details.request_type === "1" ? (
                // <Link to={"/view_suppliers"}>
                <Button
                  // id={this.state.lastElement.id}
                  onClick={this.getPreReqData}
                  className="addBtn px-4"
                >
                  <i className="fas fa-plus-circle"></i> Add New Suppliers
                </Button>
              ) : // </Link>
              null}
              {this.props.details.request_type === "3" && (
                <Button
                  // id={this.state.lastElement.id}
                  onClick={this.rescheduleOrder}
                  className="addBtn px-4"
                >
                  <i className="fas fa fa-retweet"></i> Reschedule Order
                </Button>
              )}
            </Col>
          </Row>

          <Row className="mb-4">
            <Col sm={9}>
              <p style={{ color: "grey", fontSize: "16px" }}>
                {this.props.details.pre_request.name} -{" "}
                {this.props.details.supplier.name}
              </p>
            </Col>
            <Col sm={3}>
              {" "}
              {this.props.details.offer_status === "2" ||
              this.props.details.request_status === "2" ? (
                <p style={{ color: "red", fontWeight: "bold" }}>Rejected</p>
              ) : null}
            </Col>
          </Row>

          <Row>
            <Col style={{ background: "#993b78", color: "white" }} sm={12}>
              <p style={{ marginBottom: "0rem" }}>
                Ticket Number {this.props.details.pre_request.ticket_code}
              </p>
            </Col>
          </Row>
          <Row className="my-4">
            <Col sm={12} md={6}>
              <p className="orderDetailsInfo1">
                Request Name :{" "}
                <span className="orderDetailsInfo2">
                  {this.props.details.pre_request.name}
                </span>
              </p>
              <p className="orderDetailsInfo1">
                Yanzo Price :{" "}
                <span className="orderDetailsInfo2">
                  {Object.keys(this.props.details.product).length !== 0 &&
                  Object.keys(this.props.details.offers).length === 0
                    ? this.props.details.product?.yanzo_price
                    : this.props.details.yanzo_price}
                  {/* {this.props.details.yanzo_price} */}
                </span>
              </p>
              <p className="orderDetailsInfo1">
                Product Quantity :{" "}
                <span className="orderDetailsInfo2">
                  {this.props.details.pre_request.product_quantity}
                </span>{" "}
              </p>
              <p className="orderDetailsInfo1">
                Expected Delivery Date:{" "}
                <span className="orderDetailsInfo2">
                  {this.props.details?.orders?.scheduled_date ??
                    this.props.details.pre_request.expected_delivery_date}
                </span>{" "}
              </p>
              <p className="orderDetailsInfo1">
                Client Address :{" "}
                <span className="orderDetailsInfo2">
                  {this.props.details.pre_request.client_address}
                </span>{" "}
              </p>{" "}
              {this.props.details.offers &&
              Object.keys(this.props.details.offers).length === 0 &&
              Object.getPrototypeOf(this.props.details.offers) ===
                Object.prototype ? (
                <>
                  <p className="orderDetailsInfo1">
                    Product Code : {this.props.details.product?.code}{" "}
                  </p>
                  <p className="orderDetailsInfo1">
                    Product Name : {this.props.details.product?.name}{" "}
                  </p>
                  <p className="orderDetailsInfo1">
                    Market Price : <MarketPrice row={this.props.details} />
                  </p>
                </>
              ) : (
                <>
                  <p className="orderDetailsInfo1">
                    Product Code :{" "}
                    {this.props.details.offers?.is_offer
                      ? this.props.details.offers.offer_data.code
                      : this.props.details.offers?.product?.code}{" "}
                  </p>
                  <p className="orderDetailsInfo1">
                    Product Name :{" "}
                    {this.props.details.offers?.is_offer
                      ? this.props.details.offers.offer_data.name
                      : this.props.details.offers?.product?.name}{" "}
                  </p>

                  <p className="orderDetailsInfo1">
                    Market Price : <MarketPrice row={this.props.details} />
                  </p>
                  {this.props.details.offers?.is_offer ? (
                    <>
                      <p className="orderDetailsInfo1">
                        Offered Quantity :{" "}
                        <strong>
                          {this.props.details.offers.is_offer
                            ? this.props.details.offers.offer_data.quantity
                            : this.props.details.offers.product.quantity}
                        </strong>
                      </p>
                      <p className="orderDetailsInfo1">
                        Offered Price :{" "}
                        {this.props.details.offers.offer_data.price}{" "}
                      </p>
                    </>
                  ) : (
                    <p className="orderDetailsInfo1">
                      Market Price : <MarketPrice row={this.props.details} />
                    </p>
                  )}
                </>
              )}
              <p className="orderDetailsInfo1">
                Order Delivery Status :{" "}
                <span className="orderDetailsInfo2">
                  {this.state.orderStates[
                    this.props.details?.orders?.order_status
                  ] ?? "Not stated yet"}
                </span>
              </p>
              <p className="orderDetailsInfo1">
                {/* Is Deliver? */}
                Supplier Delivery availability
                {this.props.details.is_deliver === false ||
                this.props.details.is_deliver === null ? (
                  <span className="orderDetailsInfo1 px-2">
                    No{" "}
                    {this.props.details.request_type === "3" &&
                    (this.props.details.is_deliver === false ||
                      this.props.details.is_deliver === null) ? (
                      this.props.details.orders.courier_order?.id ? null : (
                        <Button
                          onClick={this.showCourierModal}
                          className="addBtn"
                        >
                          Order Courier
                        </Button>
                      )
                    ) : null}
                  </span>
                ) : (
                  <React.Fragment>
                    <span className="orderDetailsInfo1 px-2">Yes</span>
                  </React.Fragment>
                )}
              </p>
              <p className="orderDetailsInfo1">
                Delivery Cost :{" "}
                <span className="orderDetailsInfo2">
                  {this.props.details.deliver_cost}
                </span>{" "}
              </p>
              <p className="orderDetailsInfo1">
                Delivery Date :{" "}
                <span className="orderDetailsInfo2">
                  {this.props.details?.deliver_time?.split("T")[0]}
                </span>{" "}
              </p>
              <p className="orderDetailsInfo1">
                Admin's Notes :{" "}
                <span className="orderDetailsInfo2">
                  {this.props.details.pre_request?.notes}
                </span>{" "}
              </p>
              <p className="orderDetailsInfo1">
                Supplier's Notes :{" "}
                <span className="orderDetailsInfo2">
                  {this.props.details.note}
                </span>{" "}
              </p>
            </Col>
            <Col sm={12} md={6}>
              {this.props.details.pre_request.image === "" ? (
                <img className="img-fluid" src={img} alt="product" />
              ) : (
                <img
                  className="img-fluid"
                  src={APILINK + `${this.props.details.pre_request.image}`}
                  alt="product"
                />
              )}
            </Col>
          </Row>

          <Row style={{ paddingLeft: "20px" }}>
            <Col className="mb-2" style={{ textAlign: "initial" }} sm={12}>
              <Row>
                <Col
                  style={{
                    marginLeft: "-15px",
                    color: "#993b78",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  sm={4}
                >
                  <p>Requested</p>
                </Col>
                <Col
                  style={{
                    textAlign: "right",
                    color: "#993b78",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  sm={4}
                >
                  <p style={{ marginRight: "18px" }}>Offered</p>
                </Col>

                <Col style={{ textAlign: "right" }} sm={4}>
                  <p
                    style={{
                      marginRight: "-41px",
                      color: "#993b78",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    Ordered
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row style={{ paddingLeft: "20px" }}>
            <Col className="mb-5" style={{ textAlign: "initial" }} sm={12}>
              <ProgressBar className="bar1" percent={this.state.percent}>
                <Step>
                  {({ accomplished, index }) => (
                    <div
                      className={`indexedStep ${
                        accomplished ? "accomplished" : null
                      }`}
                    ></div>
                  )}
                </Step>
                <Step>
                  {({ accomplished, index }) => (
                    <div
                      className={`indexedStep ${
                        accomplished ? "accomplished" : null
                      }`}
                    ></div>
                  )}
                </Step>
                <Step>
                  {({ accomplished, index }) => (
                    <div
                      className={`indexedStep ${
                        accomplished ? "accomplished" : null
                      }`}
                    ></div>
                  )}
                </Step>
              </ProgressBar>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col sm={12} md={12}>
              <ViewMap location={this.props.details.pre_request.location} />
            </Col>
          </Row>

          {this.props.details.request_type !== "3" &&
          this.state.lastElement !== "" &&
          !this.state.stillAvailableDone ? (
            <React.Fragment>
              <Row>
                <Col sm={12}>
                  <p>Is it still available?</p>
                </Col>
              </Row>
              <Row className="my-3">
                <Col sm={12}>
                  <Button
                    id={this.state.lastElement.id}
                    onClick={this.confirmAvail}
                    className="btn1 px-4"
                  >
                    Confirm
                  </Button>
                  <Button
                    id={this.state.lastElement.id}
                    onClick={this.rejectAvail}
                    className="btn2 px-4"
                  >
                    Reject
                  </Button>
                </Col>
              </Row>
            </React.Fragment>
          ) : null}
          {/* {this.props.details.offer_status !== null ||
          this.props.details.offer_status ===
            null ? null : ((
              this.props.details.request_status === "1" ||
              this.props.details.request_status === "3") &&
              this.props.details.request_type === "1") ||
            this.props.details.request_type === "2" ? (
            <Button
              id={this.props.details.id}
              onClick={this.createOffer}
              className="btn1 px-4"
            >
              Create Offer
            </Button>
          ) : null} */}
          {(this.props.details.request_status === "1" ||
            this.props.details.request_status === "3") &&
          (this.props.details.request_type === "2" ||
            this.props.details.request_type === "1") &&
          this.props.details.offer_status !== "2" &&
          !this.state.is_offer ? (
            <React.Fragment>
              {this.props.details.offers &&
              Object.keys(this.props.details.offers).length === 0 &&
              this.props.details.offers.constructor === Object ? null : (
                <Button onClick={this.showOffer} className="addBtn px-1">
                  Check the offer
                </Button>
              )}

              <Button
                id={this.props.details.id}
                onClick={this.createOffer}
                className="btn1 px-4"
              >
                Accept Offer
              </Button>
            </React.Fragment>
          ) : null}
        </Container>
        {this.props.details.offers &&
        Object.keys(this.props.details.offers).length === 0 &&
        this.props.details.offers.constructor === Object ? null : (
          <Modal show={this.state.showOffer} onHide={this.closeOffer}>
            <Container className="p-3">
              <Row>
                <Col sm={12}>
                  <p>
                    Product Code :{" "}
                    <strong>
                      {this.props.details.offers.is_offer
                        ? this.props.details.offers.offer_data.code
                        : this.props.details.offers.product.code}
                    </strong>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <p>
                    Product Name :{" "}
                    <strong>
                      {this.props.details.offers.is_offer
                        ? this.props.details.offers.offer_data.name
                        : this.props.details.offers.product.name}
                    </strong>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <p>
                    Offered Price :{" "}
                    <strong>
                      {this.props.details?.offers.is_offer
                        ? this.props.details?.offers.offer_data.price
                        : this.props.details?.offers.product?.price}
                    </strong>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <p>
                    Yanzo Price :{" "}
                    <strong>{this.props.details.yanzo_price}</strong>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <p>
                    {/* Is Deliver? */}
                    Delivery Available :{" "}
                    {this.props.details.is_deliver === true ? (
                      <strong>Yes</strong>
                    ) : (
                      <strong>No</strong>
                    )}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <p>
                    Offered Quantity :{" "}
                    <strong>
                      {this.props.details.offers.is_offer
                        ? this.props.details.offers.offer_data.quantity
                        : this.props.details.offers.product.quantity}
                    </strong>
                  </p>
                </Col>
              </Row>
            </Container>
          </Modal>
        )}
        {this.props.details.request_type === "1" ? (
          <AddMoreSuppliers
            suppliers={this.state.suppliers}
            show={this.state.showSuppliers}
            closeSuppliers={this.closeSuppliers}
            prereqData={this.state.prereqData}
          />
        ) : null}

        <SearchCouriers
          show={this.state.openCourierModal}
          details={this.props.details}
          hideCourier={this.closeCourier}
        />
        <RescheduleOrder
          endpoint={"schedule-order"}
          open={this.state.showReschedule}
          onClose={() => this.setState({ showReschedule: false })}
          id={this.props.details?.orders?.uuid}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuth,
  user: state.auth.user,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { searchResultSuppliers, clearRes, agentRequestData, getRequestData },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorHandler(TestDetails, axiosApiInstance));
