import React, { useEffect, useMemo } from "react";
import ChatFragment from "./chatFragment";
import firebaseObject from "../../firebase/firebaseObject";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { useSelector } from "react-redux";
import { ChatRepository } from "../../firebase/templates";

export default function SupplierChat({ details } = { details: {} }) {
  const chatId = details.id;
  const fcmToken = localStorage.getItem("firebaseToken");

  console.log({ chatId });
  const chatIdRef = firebaseObject.firestore().collection("chats").doc(chatId);

  const [currentChat, chatLoading] = useDocumentData(chatIdRef);

  const messagesRef = chatIdRef.collection("messages").orderBy("sentAt");
  // .limitToLast(messagesLimit)
  const [initialMessages, messagesLoading] = useCollectionData(messagesRef, {
    idField: "messageId",
  });

  useEffect(() => {
    if (currentChat && !currentChat.ticket_code) {
      chatIdRef.set(
        {
          ticket_code: details.pre_request.ticket_code,
        },
        { merge: true }
      );
    }

    if (!chatLoading && !currentChat) {
      chatIdRef.set(
        {
          supplier: {
            id: details.supplier.id,
            name: details.supplier.name,
          },
          product: details.pre_request.name,
          ticket_code: details.pre_request.ticket_code,
        },
        { merge: true }
      );

      console.log("New One");
    }
    console.log({ currentChat });
  }, [chatLoading]);

  const user = useSelector((state) => state.auth.user);
  const userType = user.user_type;
  const chattingWithName = currentChat
    ? userType == 1
      ? currentChat.supplier?.name
      : "Agents"
    : "";

  const chatRepository = useMemo(() => {
    const sender = {
      email: user.id,
      name: user.user_name,
      userType: user.user_type,
      fcmToken,
    };
    const extraInformation = {
      ticket_code: details.pre_request?.ticket_code,
    };
    return new ChatRepository(
      firebaseObject,
      "chats",
      "messages",
      sender,
      extraInformation
    );
  }, [details]);

  console.log({ currentChat, chattingWithName });
  return (
    <ChatFragment
      chatId={chatId}
      initialMessages={initialMessages}
      messagesLoading={messagesLoading}
      chatLoading={chatLoading}
      currentChat={currentChat}
      chattingWithName={chattingWithName}
      chatTitle={currentChat?.product}
      chatSubTitle={`Ticket: #${details.pre_request?.ticket_code}`}
      chatRepository={chatRepository}
      checkSenderIsMe={(messageItem) =>
        +messageItem.sender.userType === +userType
      }
    />
  );
}
