import React, { useMemo } from "react";
import styles from "./NotificationCard.module.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import defaultIcon from "./assets/update.svg";
import formatDate from "../../../utils/format-date";

export default function NotificationCard({
    className,
    icon,
    title,
    create_date: date,
    body,
    secondary,
    extraText,
    buttonText,
    children: actions,
    onClick,
    readed,
    ...rest
}) {
    const notificationDate = date ? new Date(date) : new Date();
    const formattedDate = useMemo(() => formatDate(notificationDate), []);

    return (
        <div
            className={`${styles.container} ${className} ${
                readed ? styles.read : ""
            }`}
            {...rest}
        >
            <div className={styles.header}>
                <div className={styles.title}>
                    <img className={styles.icon} src={icon ?? defaultIcon} />
                    <h1>{title}</h1>
                    <span className={styles.notificationDot} />
                </div>
            </div>
            <div className={styles.main}>{secondary}</div>
            <div className={[styles.contentText, styles.info].join(" ")}>
                {body}
            </div>
            {extraText && (
                <div className={[styles.contentText, styles.extra].join(" ")}>
                    {extraText}
                </div>
            )}
            <div className={styles.actions}>
                <span className={`${styles.contentText} ${styles.date}`}>
                    {formattedDate}
                </span>
            </div>
        </div>
    );
}
