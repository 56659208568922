import Resizer from "react-image-file-resizer";

export function resizeFile(file, compressionLevel) {
    return new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            300,
            300,
            "JPEG",
            compressionLevel,
            0,
            (image) => {
                resolve(image);
            },
            "base64"
        );
    });
}



