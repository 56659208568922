import React, { useEffect, useState } from "react";
import { Button, Divider, Form, Input, Select, Space } from "antd";
import styles from "./CourierServicesForm.module.css";
import ErrorHandler from "../../../../../ErrorHandler/ErrorHandler";
import { APILINK } from "../../../../../Endpoint";
import axios from "axios";

const axiosApiInstance = axios.create();

function ServicesListItem({
  index,
  value,
  deleteDisabled,
  vehicles,
  onDelete,
  onChange,
  delivery,
  getVehicles,
  getService,
  emirates,
}) {
  const [form] = Form.useForm();
  const [vname, setName] = useState("");
  const [service, setService] = useState("");
  const [pickUpAreas, setPickUpAreas] = useState([]);
  const [dropOffAreas, setDropOffAreas] = useState([]);
  console.log({ value });

  async function getAreas(emirate, setAreas) {
    if (!emirate) {
      setAreas([]);
    } else {
      const res = await axiosApiInstance.get(APILINK + "/areas/" + emirate);
      setAreas(res.data);
    }
  }

  useEffect(() => {
    getAreas(value.pick_up_emirate, setPickUpAreas);
    form.setFieldValue("pick_up_area", []);
  }, [value.pick_up_emirate]);

  useEffect(() => {
    getAreas(value.drop_off_emirate, setDropOffAreas);
    form.setFieldValue("drop_off_area", []);
  }, [value.drop_off_emirate]);
  const addVehicle = async (e) => {
    const result = await axiosApiInstance.post(APILINK + `/vehicle`, {
      name: vname,
    });
    if (result) {
      console.log(result);
      if (result) {
        getVehicles();
        setName("");
      }
    }
  };

  const addService = async (e) => {
    const result = await axiosApiInstance.post(APILINK + `/delivery_service`, {
      name: service,
    });
    if (result) {
      console.log(result);
      if (result) {
        getService();
        setService("");
      }
    }
  };
  return (
    <Form className={styles.service} form={form}>
      <header>
        <h5>Service No. {index + 1}</h5>
        <Button
          className="addBtn2"
          disabled={deleteDisabled}
          onClick={onDelete}
        >
          Delete
        </Button>
      </header>
      <Form.Item label="Select Pickup Emirate" name="pick_up_emirate">
        <Select
          placeholder="Pickup Emirate"
          value={value.pick_up_emirate}
          onChange={(v) => onChange("pick_up_emirate", v)}
        >
          {emirates.map((it) => (
            <option key={it.id} value={it.id}>
              {it.name}
            </option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Select Pickup Area" name="pick_up_area">
        <Select
          // mode="multiple"
          placeholder="Pickup Area"
          value={value.pick_up_area}
          onChange={(v) => onChange("pick_up_area", v)}
        >
          {pickUpAreas.map((it) => (
            <option key={it.id} value={it.id}>
              {it.name}{" "}
            </option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Select Drop Off Emirate" name="drop_off_emirate">
        <Select
          value={value.drop_off_emirate}
          placeholder="Drop Off Emirate"
          onChange={(v) => onChange("drop_off_emirate", v)}
        >
          {emirates.map((it) => (
            <option key={it.id} value={it.id}>
              {it.name}
            </option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Select Drop Off Area" name="drop_off_area">
        <Select
          // mode="multiple"
          value={value.drop_off_area}
          placeholder="Drop Off Area"
          onChange={(v) => onChange("drop_off_area", v)}
        >
          {dropOffAreas.map((it) => (
            <option key={it.id} value={it.id}>
              {it.name}{" "}
            </option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Select Vehicles" name="vehicle">
        <Select
          value={value?.vehicle}
          placeholder="Select Vehicles"
          style={{ width: "100%" }}
          showSearch
          onChange={(v) => onChange("vehicle", v)}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider
                style={{
                  margin: "8px 0",
                }}
              />
              <Space
                style={{
                  padding: "0 8px 4px",
                }}
              >
                <Input
                  placeholder="Please enter vehicle"
                  value={vname}
                  onChange={(e) => setName(e.target.value)}
                />
                <Button type="text" onClick={addVehicle} className="addBtn2">
                  Create
                </Button>
              </Space>
            </>
          )}
        >
          {vehicles.map((item) => (
            <Select.Option key={item.id} id={item.id} value={item.id}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Delivery Service" name="delivery">
        <Select
          value={value?.delivery}
          placeholder="Select Services"
          style={{ width: "100%" }}
          showSearch
          onChange={(v) => onChange("delivery", v)}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider
                style={{
                  margin: "8px 0",
                }}
              />
              <Space
                style={{
                  padding: "0 8px 4px",
                }}
              >
                <Input
                  placeholder="Please enter service"
                  value={service}
                  onChange={(e) => setService(e.target.value)}
                />
                <Button type="text" onClick={addService} className="addBtn2">
                  Create
                </Button>
              </Space>
            </>
          )}
        >
          {delivery.map((item) => (
            <Select.Option key={item.id} id={item.id} value={item.id}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Price" name="price">
        <Input
          type={"number"}
          min={1}
          onChange={(e) => onChange("price", +e.target.value)}
        />
      </Form.Item>
    </Form>
  );
}

export default ErrorHandler(ServicesListItem, axiosApiInstance);
