import { Route } from "react-router-dom";
import AllSuppliers from "../components/agent-view/AllSuppliers";
import CreateSupplier from "../components/agent-view/CreateSupplier";
import ViewSuppliers from "../components/agent-view/ViewSuppliers";
import Home from "../components/supplier-pages/Home";
import Profile from "../components/supplier-pages/Profile";

export default function SupplierRoutes() {
  return (
    <>
      <Route exact path="/home" component={Home} />
      <Route exact path="/profile" component={Profile} />

      <Route exact path="/add_supplier" component={CreateSupplier} />
      <Route exact path="/create_supplier" component={CreateSupplier} />

      <Route exact path="/view_suppliers" component={ViewSuppliers} />
      <Route exact path="/suppliers" component={AllSuppliers} />
      {/* <Route exact path="/edit_supplier/:id" component={EditSupplier} /> */}
    </>
  );
}
