import React, { useState, useEffect } from "react";

import {
  Container,
  Row,
  Col,
  Tooltip,
  OverlayTrigger,
  Button
} from "react-bootstrap";
import "../../../../style-sheets/products.css";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Pagination, Spin } from "antd";
import ErrorHandler from "../../../../ErrorHandler/ErrorHandler";
import { APILINK } from "../../../../Endpoint";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";

const axiosApiInstance = axios.create();

function AllEmirates() {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [current, setCurrent] = useState(1);
  const [loading, setLoading] = useState(false);

  let columns = [
    {
      name: "Emirate Name",
      selector: "name",
      sortable: true,
      right: true
    },

    {
      name: "Actions",
      button: true,
      width: "190px",
      cell: (row) => (
        <div className="courierTable">
          <OverlayTrigger
            key={"top"}
            placement={"top"}
            overlay={<Tooltip id={`tooltip-${"top"}`}>Edit Emirate</Tooltip>}
          >
            <Link to={`/edit_emirate/${row.id}`}>
              {" "}
              <i class="fas fa-edit"></i>
            </Link>
          </OverlayTrigger>
          <OverlayTrigger
            key={"top"}
            placement={"top"}
            overlay={<Tooltip id={`tooltip-${"top"}`}>Add Area</Tooltip>}
          >
            <Link to={`/create_area/${row.id}`}>
              {" "}
              <i class="fas fa-plus-circle"></i>
            </Link>
          </OverlayTrigger>
          <OverlayTrigger
            key={"top"}
            placement={"top"}
            overlay={<Tooltip id={`tooltip-${"top"}`}> View Areas</Tooltip>}
          >
            <Link to={`all_areas/${row.id}`}>
              <i id={row.id} class="fas fa-eye"></i>{" "}
            </Link>
          </OverlayTrigger>
          {/* <OverlayTrigger
            key={"top"}
            placement={"top"}
            overlay={<Tooltip id={`tooltip-${"top"}`}> Delete Emirate</Tooltip>}
          >
            <>
              <i
                id={row.id}
                onClick={() => deleteEmirate(row.id)}
                class="fas fa-trash"
              ></i>{" "}
            </>
          </OverlayTrigger> */}
        </div>
      )
    }
  ];
  const getEmirates = async () => {
    setLoading(true);
    const result = await axiosApiInstance
      .get(APILINK + `/emirate?page=${current}`)
      .catch((err) => {
        console.log(err.response);
      });
    if (result) {
      setData(result.data.results);
      setCount(result.data.count);
      setLoading(false);
    }
  };

  // const deleteEmirate = async (id) => {
  //   const result = await axiosApiInstance
  //     .delete(APILINK + `/emirate/${id}`)
  //     .catch((err) => {
  //       console.log(err.response);
  //     });
  //   getEmirates();
  // };
  useEffect(() => {
    getEmirates();
  }, []);
  useEffect(() => {
    if (data.length > 0) {
      getEmirates();
    }
  }, [current]);
  const handleChange = async (page) => {
    setCurrent(page);
  };
  return (
    <Container>
      <Row>
        <Col className="p-4 mb-4" style={{ textAlign: "initial" }} sm={12}>
          <h2>
            {" "}
            <i className="fas fa-truck pr-2"></i> All Emirates
          </h2>
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: "end" }} sm={12}>
          <Link to="/create_emirate">
            {" "}
            <Button className="headBtn"> Add Emirate</Button>
          </Link>
        </Col>
      </Row>
      {loading ? (
        <Spin tip="loading..." />
      ) : (
        <>
          {" "}
          <Row>
            <Col sm="12">
              <DataTable columns={columns} data={data} />
            </Col>
          </Row>
          <Row>
            <Col className="text-center mt-3" sm="12">
              {data?.length > 0 ? (
                <Pagination
                  onChange={handleChange}
                  total={count}
                  defaultCurrent={current}
                  current={current}
                />
              ) : null}
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

export default ErrorHandler(AllEmirates, axiosApiInstance);
