import React, {Component} from "react";
import {
    Navbar,
    Form,
    Nav,
    FormControl,
    NavDropdown,
    Dropdown,
} from "react-bootstrap";
import "../../style-sheets/header.css";
import logo from "../../imgs/logo.png";
//redux
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {LogOut} from "../../global-state/actions/authActions";
import Notifications from "../Notifications";
import {ReactComponent as NotificationIcon} from "../Notifications/NotificationCard/assets/notification.svg";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notificationsOpen: false,
        };
    }

    onLogout = (e) => {
        this.props.LogOut();
    };

    render() {
        return (
            <div>
                <Notifications
                    isCollapsed={!this.state.notificationsOpen}
                    newNotification={this.props.newNotification}
                    onChange={(v) => this.setState({unreadNotificationCount: v})}
                />
                <Navbar fixed="top" className="myNav" bg="light" expand="sm">
                    <Navbar.Brand>
                        <img className="img-fluid logo" src={logo} alt="logo"/>
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>

                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav
                            style={{paddingLeft: "5%"}}
                            className="mr-auto"
                        ></Nav>
                        <button
                            className="notificationButton"
                            onClick={() =>
                                this.setState({
                                    notificationsOpen:
                                        !this.state.notificationsOpen,
                                    unreadNotificationCount: 0,
                                })
                            }
                        >
                            <NotificationIcon/>
                            <div
                                className={
                                    [
                                        "notificationDot",
                                        this.state.unreadNotificationCount === 0 ? "notificationDotRead" : ""
                                    ].join(" ")}>{
                                this.state.unreadNotificationCount > 0 &&
                                this.state.unreadNotificationCount > 9 ? "9+" : this.state.unreadNotificationCount

                            }</div>
                        </button>
                        <NavDropdown
                            className="adminDrop"
                            title={
                                this.props.user?.user_name
                                    ? this.props.user.user_name
                                    : "Admin"
                            }
                            id="basic-nav-dropdown"
                        >
                            {this.props.user.user_type === "2" ? (
                                <React.Fragment>
                                    <NavDropdown.Item href="/profile">
                                        <i class="far fa-user pr-2"></i>Profile
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="/update_credentials">
                                        <i class="fas fa-cog pr-2"></i>
                                        Credentials
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        onClick={this.onLogout}
                                        href="/"
                                    >
                                        <i class="fas fa-sign-out-alt pr-2"></i>
                                        Log out
                                    </NavDropdown.Item>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {/* <NavDropdown.Item href="/profile">
                    <i class="far fa-user pr-2"></i>Profile
                  </NavDropdown.Item> */}
                                    <NavDropdown.Item href="/update_credentials">
                                        <i class="fas fa-cog pr-2"></i>
                                        Credentials
                                    </NavDropdown.Item>

                                    <NavDropdown.Item
                                        onClick={this.onLogout}
                                        href="/"
                                    >
                                        <i class="fas fa-sign-out-alt pr-2"></i>
                                        Log out
                                    </NavDropdown.Item>
                                </React.Fragment>
                            )}
                        </NavDropdown>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
});
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({LogOut}, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
