import "../../style-sheets/messages.css"
import { Image, OverlayTrigger } from "react-bootstrap"
import resizeImage from "../../firebase/imageResizers"
import { useState } from "react"
import { useEffect } from "react"
import Resizer from "react-image-file-resizer";
import { convertImgToBase64URL } from "../../firebase/imageResizers"
import { Tooltip } from "react-bootstrap"
import { Dialog } from "@material-ui/core"
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { FileEarmarkImage as ImageIcon, MicFill as MicIcon, X as CloseIcon } from 'react-bootstrap-icons';
import ImageViewerFragment from "./ImageViewer"


export default function ImageViewerDialog({
    isViewingImage, setIsViewing,
    images, imageIndex
}) {

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={isViewingImage}
        >

            <div style={{
                height: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "5px 5px",
                background: "#F7ECF3",
                // marginBottom: "10px"

            }}
            >
                <h5
                    style={{
                        margin: "0px 5px"
                    }}
                >Images</h5>
                <CloseIcon className="chatButtons" onClick={() => { setIsViewing(false) }} />
            </div>
            <ImageViewerFragment
                style={{ paddingTop: "500px" }}
                images={images} initialIndex={imageIndex} />

        </Dialog>
    )
}