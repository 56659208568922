import React, { useState } from "react";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import axios from "axios";
import { DatePicker, Form, Select, Spin } from "antd";
import { Button, Col, Container, Row } from "react-bootstrap";
import { APILINK } from "../../Endpoint";
import invokeFileDownload from "../../utils/invoke-file-download";
import { useHistory } from "react-router-dom";

const axiosApiInstance = axios.create();

function ReportsPage() {
  const [generatingLoading, setGeneratingLoading] = useState(false);
  const [generatingError, setGeneratingError] = useState(false);
  const history = useHistory();
  const generateReport = async (data) => {
    setGeneratingError(false);
    setGeneratingLoading(true);

    const format = "YYYY-MM-DD";
    const rawDate = data.date ?? [];
    const dateRange = {
      from: rawDate[0]?.format(format),
      to: rawDate[1]?.format(format),
    };
    try {
      const res = await axiosApiInstance.get(APILINK + "/OrdersReport/", {
        responseType: "blob",
        params: {
          ...dateRange,
          order_type: data.order_type ?? "all",
        },
      });
      invokeFileDownload(res.data, "orders-report.xlsx");
    } catch (e) {
      setGeneratingError(true);
    }

    setGeneratingLoading(false);
  };

  return (
    <Container style={{}}>
      <div style={{ padding: "16px", marginInlineStart: "100px" }}>
        <Row>
          <Col className="mb-4" style={{ textAlign: "initial" }} sm={12}>
            <h2>
              <i
                style={{ cursor: "pointer" }}
                class="fas fa-chevron-left px-1"
                onClick={history.goBack}
              ></i>{" "}
              <i class="fas fa-file pr-2"></i> Reports
            </h2>
          </Col>
        </Row>
        <Form onFinish={generateReport} layout="vertical">
          <Row>
            <Col sm={12} md={6}>
              <Form.Item
                name="order_type"
                label="Order Type"
                rules={[
                  { required: true, message: "Please select report type" },
                ]}
              >
                <Select allowClear>
                  {[
                    { name: "All", value: "all" },
                    { name: "Supplier Orders", value: "supplier" },
                    {
                      name: "Courier Orders",
                      value: "courier",
                    },
                  ].map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col sm={12} md={6}>
              <Form.Item name="date" label="From and To">
                <DatePicker.RangePicker />
              </Form.Item>
            </Col>
          </Row>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className="error" style={{ color: "red" }}>
              {generatingError &&
                "There is no valid orders to create the report."}
            </p>
            <Button loading={true} type="submit" className="btn btn-primary">
              {generatingLoading && <Spin />}Generate Report
            </Button>
          </div>
        </Form>
      </div>
    </Container>
  );
}

export default ErrorHandler(ReportsPage, axiosApiInstance);
