import React, {useState, useRef, useEffect} from "react";

import {Container, Row, Col} from "react-bootstrap";

import "../../../style-sheets/products.css";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";
import {APILINK} from "../../../Endpoint";
import AddCourierEmirates from "./courier_services/EmiratesTable/AddCourierEmirates";
import {useHistory} from "react-router-dom";
import CommonCourierAction from "./CommonCourierAction";

const axiosApiInstance = axios.create();

function CreateCourier(props) {
    const request = async (data) => {
        return await axiosApiInstance
            .post(APILINK + `/courier`, data)
            .catch((err) => {
                const rawErrors = err.response.data ?? {};
                const errors = rawErrors.error ? rawErrors.error : Array.isArray(rawErrors) ? rawErrors[0] : rawErrors;
                console.log({errors})
                Object.keys(errors).forEach((it) => toast.error(`${it}: ${errors[it][0]}`));
            });
    };
    return (
        <div>
            <CommonCourierAction request={request}/>
        </div>
    );
}

export default ErrorHandler(CreateCourier, axiosApiInstance);
