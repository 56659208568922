import { Route } from "react-router-dom";
import ViewRequestDetails from "../components/agent-view/ViewRequestDetails";
import Requests from "../components/supplier-pages/Requests";

export default function RequestsRoutes() {
  return (
    <>
      <Route exact path="/requests" component={Requests} />

      <Route
        exact
        path="/view_request_details/:id"
        component={ViewRequestDetails}
      />
    </>
  );
}
