import React, {useEffect, useState} from "react";
import {APILINK} from "../../../Endpoint";
import {toast} from "react-toastify";
import CommonCourierAction from "./CommonCourierAction";
import axios from "axios";
import {useParams} from "react-router-dom";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";
import {Form} from "antd";

const axiosApiInstance = axios.create();

function EditCourier() {
    const params = useParams();
    const [form] = Form.useForm();

    const [initialValues, setInitialValues] = useState({});
    const fetchInitialValues = async () => {
        const result = await axiosApiInstance
            .get(APILINK + `/courier/${params.id}`)
            .catch((error) => console.log({error}));

        if (result) {
            console.log({result: result.data});
            setInitialValues({...result.data});
        }
    };

    const request = async (data) => {
        return await axiosApiInstance
            .put(APILINK + `/courier/${params.id}`, data)
            .catch((err) => {
                const errors = err.response.data ?? {};
                console.log({errors});
                Object.keys(errors).forEach((it) =>
                    toast.error(`${it}: ${errors[it]}`)
                );
            });
    };

    useEffect(() => {
        fetchInitialValues();
    }, []);
    return (
        <div>
            <CommonCourierAction
                request={request}
                form={form}
                initialValues={initialValues}
            />
        </div>
    );
}

export default ErrorHandler(EditCourier, axiosApiInstance);
