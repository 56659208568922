import "../../style-sheets/imageViewer.css"
import React from "react"
import { ButtonGroup, Image, Button } from "react-bootstrap"
import { CaretLeftFill as LiftArrow, CaretRightFill as RightArrow } from "react-bootstrap-icons"
import { useState } from "react"
import { useRef } from "react"
import { useEffect } from "react"
import AspectRatio from "react-aspect-ratio"
import Draggable from "react-draggable"
import { message, Spin } from "antd"
// import AspectRatio from "react-aspect-ratio"

// import 'react-image-viewer-zoom/dist/style.css'

export default function ImageViewerFragment({ images, initialIndex }) {

    const currentImageRef = useRef();
    const [currentIndex, setIndex] = useState(initialIndex);
    const [imageScale, setScale] = useState(1);
    const [imagePosition, setPosition] = useState("center");
    const [isLoading, setLoading] = useState(images?.[0]?.full);

    useEffect(() => {
        setLoading(true)
        currentImageRef.current.scrollIntoView({ smooth: true });
        window.scrollTo({
            top: 100,
            behavior: 'smooth'
        });
    }, [currentIndex]);


    const cursor = imageScale == 1 ? 'zoom-in' : 'grab';
    return (
        <div className="imageViewerContainer">


            {/* First Fragment */}
            <div className="currentImage">


                <Spin
                    className="loadingFragment"
                    hidden={!isLoading} tip="Loading..." />

                <Draggable
                    position={{ x: 0, y: 0 }}
                    disabled={imageScale <= 1}
                    bounds={{
                        top: -105, bottom: 105,
                        left: -250, right: 250
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            // background: "#efe"

                        }}>
                        <img
                            draggable="false"
                            className="viewedImage"
                            src={
                                // currentImage
                                images[currentIndex].full
                            }
                            onChange={() => { alert("change") }}
                            onLoad={() => { setLoading(false) }}
                            style={{
                                transform: `scale(${imageScale})`,
                                transformOrigin: `${imagePosition}`,
                                cursor: cursor
                            }}
                            onClick={imageScale == 1 ? () => { setScale(1.5) } : () => { }}
                        />
                    </div>
                </Draggable>


                {/* 
                */}

                <div>

                    <div>
                        <ButtonGroup
                            style={{
                                position: "absolute",
                                right: "30px",
                                top: "55px",
                                zIndex: "2"

                            }}>
                            <Button
                                className="zoomButtons"
                                disabled={imageScale >= 1.5}
                                onClick={() => {
                                    setScale(prev => prev + 0.5)
                                }}>
                                +
                            </Button>
                            <Button
                                className="zoomButtons"
                                disabled={imageScale <= 1}
                                onClick={() => {
                                    setScale(prev => prev - 0.5)
                                }}
                            >
                                -
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>

            </div>

            {/* Second Fragment: Images List */}
            <div className="imagesContainer">

                <Button
                    disabled={currentIndex <= 0}
                    variant="no-fill"
                    className="buttonsContainers"
                    onClick={() => {
                        setIndex(prev => prev - 1)
                    }}>
                    <LiftArrow className="scrollButtonsIcon" />
                </Button>

                <div className="imagesList">
                    {
                        images.map((img, i) => {
                            const isViewedImage = i == currentIndex
                            const imagePadding = isViewedImage ? "5px" : "2px"
                            return (
                                <img
                                    ref={isViewedImage ? currentImageRef : null}
                                    key={img.thumb}
                                    className="imageContainers"
                                    style={{
                                        padding: imagePadding,
                                        background: "#993B78"
                                    }}
                                    src={img.thumb} rounded
                                    onClick={() => {
                                        setIndex(i)
                                    }}
                                />
                            )
                        })
                    }
                </div>

                <Button
                    disabled={currentIndex >= images.length - 1}
                    variant="no-fill" className="buttonsContainers"
                    onClick={() => {
                        setIndex(prev => prev + 1)
                    }}>
                    <RightArrow className="scrollButtonsIcon" />
                </Button>

            </div>




        </div>
    );
}