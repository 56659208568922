import { Route } from "react-router-dom";
import AddCourierService from "../components/agent-view/courier/courier_services/AddCourierService";
import EditCourierService from "../components/agent-view/courier/courier_services/EditCourierService";
import ViewCourierServices from "../components/agent-view/courier/courier_services/ViewCourierServices";

export default function CourierServiceRoutes() {
    return (
        <>
            <Route
                exact
                path="/view_service/:id"
                component={ViewCourierServices}
            />

            <Route
                exact
                path="/add_service/:id"
                component={AddCourierService}
            />
            <Route
                exact
                path="/edit_service/:id"
                component={EditCourierService}
            />
        </>
    );
}
