import React from "react";
import { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import TestDetails from "../agent-view/TestDetails";
import OrderDetails from "../sections/OrderDetails";
import ChatFragment from "./chatFragment";
import ImageViewerFragment from "./ImageViewer";
import SupplierChat from "./SupplierChat";

const DETAILS_TAB_IDENTIFIER = "details";
const CHAT_TAB_IDENTIFIER = "chat";
const IMAGES_TAB_IDENTIFIER = "images";

export default function DetailsFragment({
  userType,
  changeOffer,
  details,
  getAll,
}) {
  const [selectedTab, setSelectedTab] = useState(DETAILS_TAB_IDENTIFIER);

  return (
    <>
      {details && (
        <div>
          <Tabs
            id="detailsTabs"
            activeKey={selectedTab}
            onSelect={(k) => setSelectedTab(k)}
          >
            <Tab eventKey={DETAILS_TAB_IDENTIFIER} title="Details">
              {userType == "supplier" ? (
                <OrderDetails
                  changeOffer={changeOffer}
                  details={details}
                  getAll={getAll}
                />
              ) : (
                <TestDetails details={details} />
              )}
            </Tab>

            <Tab eventKey={CHAT_TAB_IDENTIFIER} title="Chat">
              <SupplierChat details={details} />
            </Tab>

            {/* <Tab eventKey={IMAGES_TAB_IDENTIFIER} title="Images" >
                    <ImageViewerFragment />
                </Tab>
                 */}
          </Tabs>
        </div>
      )}
    </>
  );
}
