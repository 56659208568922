import React, { Component, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import {
    Container,
    Row,
    Col,
    Button,
    Tooltip,
    OverlayTrigger,
    Modal,
} from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import ErrorHandler from "../../../../ErrorHandler/ErrorHandler";
import { APILINK } from "../../../../Endpoint";
import { ToastContainer, toast } from "react-toastify";

const axiosApiInstance = axios.create();

function ViewCourierServices() {
    const params = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [services, setServices] = useState([]);
    const columns = [
        {
            name: "Vehicle",
            selector: "vehicle.name",
            sortable: true,
            right: true,
        },
        {
            name: "Delievery",
            selector: "delivery.name",
            sortable: true,
            right: true,
        },

        {
            name: "Delivery Time",
            selector: "last_time_submit",
            sortable: true,
            right: true,
        },
        {
            name: "Price",
            selector: "price",
            sortable: true,
            right: true,
        },
        {
            name: "Actions",
            button: true,
            cell: (row) => (
                <div className="courierTable">
                    <OverlayTrigger
                        key={"top"}
                        placement={"top"}
                        overlay={
                            <Tooltip id={`tooltip-${"top"}`}>
                                Edit service
                            </Tooltip>
                        }
                    >
                        <>
                            <Link to={`/edit_service/${row.id}`}>
                                {" "}
                                <i className="fas fa-edit"></i>
                            </Link>
                        </>
                    </OverlayTrigger>
                    <OverlayTrigger
                        key={"top"}
                        placement={"top"}
                        overlay={
                            <Tooltip id={`tooltip-${"top"}`}>
                                Delete service
                            </Tooltip>
                        }
                    >
                        <>
                            {" "}
                            <i
                                id={row.id}
                                onClick={() => deleteService(row.id)}
                                className="fas fa-trash"
                            ></i>
                        </>
                    </OverlayTrigger>
                </div>
            ),
        },
    ];
    const showWarning = (e) => {
        console.log(e);
        this.setState({ serviceID: e.target.id, show: true });
    };
    const close = (e) => {
        this.setState({ show: false });
    };

    const getAllServices = async () => {
        const result = await axiosApiInstance.get(
            APILINK + `/courier_service_by_courier/${params.id}`
        );
        if (result.data) {
            setServices(result.data);
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllServices();
    }, []);

    const deleteService = async (serviceId) => {
        const result = await axiosApiInstance.delete(
            APILINK + `/courier_service/${serviceId}`
        );
        if (result) {
            console.log(result);
            if (result.data === "") {
                toast.success("Service Deleted Successfully");
                await getAllServices();
            }
        }
    };

    return (
        <div>
            <Container
                style={{
                    marginTop: "100px",
                    // marginLeft: "350px"
                }}
                className="pt-3"
            >
                <Row className="my-4">
                    <Col className="" style={{ textAlign: "initial" }} sm={6}>
                        <h2>
                            {" "}
                            <i
                                style={{ cursor: "pointer" }}
                                class="fas fa-chevron-left px-1"
                                onClick={history.goBack}
                            ></i>{" "}
                            <i className="fas fa-truck pr-2"></i>services
                        </h2>
                    </Col>
                    <Col style={{ textAlign: "end" }} sm={6}>
                        <Link to={`/add_service/${params.id}`}>
                            <Button
                                // onClick={this.downLoadCatalog}
                                className="headBtn"
                            >
                                Add new service
                            </Button>
                        </Link>
                    </Col>{" "}
                </Row>

                <Row>
                    <Col sm={12}>
                        <DataTable
                            columns={columns}
                            data={services}
                            // onSelectedRowsChange={this.handleSelected}
                        />
                    </Col>
                </Row>
            </Container>
            <Modal show={show} onHide={close}>
                <Container className="p-4">
                    <Row>
                        <Col className="text-center" sm={12}>
                            {" "}
                            <h6>Are u sure u want to delete this Service?</h6>
                        </Col>
                        <Row style={{ margin: "auto" }}>
                            <Col md={12}>
                                <Button
                                    onClick={deleteService}
                                    className="forumBtn1 mx-3"
                                >
                                    Delete
                                </Button>
                                <Button className="forumBtn1" onClick={close}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Row>
                </Container>
            </Modal>
        </div>
    );
}

export default ErrorHandler(ViewCourierServices, axiosApiInstance);
