import React, {Component} from "react";
import {Container, Modal, Row, Col} from "react-bootstrap";
import {Form, Input, Button} from "antd";
import firebaseObject from "../firebase/firebaseObject";
//redux
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {userLogin, LogOut} from "../global-state/actions/authActions";
import logo from "../imgs/logo.png";
import axios from "axios";
import {APILINK} from "../Endpoint";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const axiosApiInstance = axios.create();

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            BeErr: "",
            open: false,
            BeErr2: "",
        };
    }

    handleName = (e) => {
        this.setState({email: e.target.value});
    };
    handlepass = (e) => {
        this.setState({password: e.target.value});
    };

    onLogin = async (e) => {
        let loginrequest = {};
        loginrequest.email = this.state.email;
        loginrequest.password = this.state.password;

        const userData = await this.props.userLogin(
            loginrequest,
            this.props.history
        );
        // console.log(userData);
        try {
            if (userData) {
                this.setState({BeErr: userData});

                const messaging = firebaseObject.messaging();

                console.log();
                messaging
                    .requestPermission()
                    .catch(err => {
                        console.log("Notification Permission Not Granted")
                    })
                    .then(() => messaging.getToken().catch(err => {
                        console.log("Notification Permission Not Granted")
                    })).then((token) => {
                    if (!token) return;
                    console.log({token});
                    console.log(`tokenfromLogin = ${token}`);

                    axiosApiInstance.defaults.headers.common["Authorization"] =
                        localStorage.getItem("accessToken");

                    axiosApiInstance
                        .post(`${APILINK}/devices`, {
                            registration_id: token,
                            type: "web",
                        })
                        .then((res) => console.log(`token sent: ${res}`))
                        .catch((err) => console.log(`error: ${err}`));

                    // localStorage.setItem("firebaseToken", token);
                });
            }
        } catch (e) {
            console.log("Notification Permission Not Granted")
        }
    };

    componentDidMount() {
        this.props.LogOut();
    }

    onFinish = (values) => {
        // console.log("Success:", values);
    };

    onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };
    openModal = (e) => {
        this.setState({open: true});
    };
    close = (e) => {
        this.setState({open: false});
    };
    forget = (e) => {
        let data = {email: this.state.email};
        axios
            .post(APILINK + `/request-reset-email/`, data)
            .then((res) => {
                console.log(res);
                this.setState({open: false});
                setTimeout(() => {
                    toast.info("Check yor Email");
                }, 500);
            })
            .catch((err) => {
                console.log(err.response);
                this.setState({BeErr2: err.response.data[0]});
            });
    };

    render() {
        return (
            <div>
                <Container
                    style={{
                        height: "100vh",
                        display: "grid",
                        placeItems: "center",
                    }}
                >
                    <Col
                        sm={12}
                        md={6}
                        styles={{
                            display: "grid",
                            placeItems: "end",
                        }}
                        className="mx-auto d-flex align-items-center  justify-content-center"
                    >
                        {/* <h3>Login page</h3> */}
                        <Form
                            name="basic"
                            initialValues={{remember: true}}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                            style={{
                                background: "white",
                                // margin: "30px auto 0px auto",
                                // padding: "50px 100px",
                                borderRadius: "20px",
                                // width: "50%",
                            }}
                        >
                            <img
                                style={{
                                    //  height: "16px",
                                    marginBottom: "20px",
                                }}
                                src={logo}
                                className="img-fluid"
                                alt="yanzo-logo"
                            />
                            <h1 style={{fontFamily: "Poppins, sansSerif"}}>
                                Login to Yanzo
                            </h1>
                            <Form.Item
                                name="Email"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Please input your User name or Email!",
                                    },
                                ]}
                            >
                                <Input
                                    className="loginInput"
                                    placeholder="User name or Email"
                                    onChange={this.handleName}
                                    type="text"
                                />
                            </Form.Item>

                            <Form.Item
                                // label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your password!",
                                    },
                                ]}
                            >
                                <Input.Password
                                    value={this.state.password}
                                    onChange={this.handlepass}
                                    placeholder="password"
                                    className="loginInput"
                                />
                            </Form.Item>

                            <p onClick={this.openModal} className="forget">
                                Forgot password?
                            </p>

                            <Form.Item>
                                <Button
                                    className="loginBtn"
                                    onClick={this.onLogin}
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Row>
                        <Col
                            style={{margin: "auto", textAlign: "center"}}
                            sm={12}
                        >
                            {this.state.BeErr !== "" ? (
                                <p style={{color: "red", fontWeight: "bold"}}>
                                    {this.state.BeErr}
                                </p>
                            ) : null}
                        </Col>
                    </Row>
                </Container>
                <Modal show={this.state.open} onHide={this.close}>
                    <Container className="p-4">
                        <Row>
                            <Col className="text-center" sm={12}>
                                {" "}
                                <h6>Password Reset</h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center" sm={12}>
                                <Form
                                    name="basic"
                                    initialValues={{remember: true}}
                                    onFinish={this.onFinish}
                                    onFinishFailed={this.onFinishFailed}
                                >
                                    <Form.Item
                                        name="Email"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please input your Email!",
                                            },
                                        ]}
                                    >
                                        <Input
                                            className="loginInput"
                                            placeholder=" Email"
                                            onChange={this.handleName}
                                            type="text"
                                        />
                                    </Form.Item>

                                    <Form.Item>
                                        <Button
                                            className="loginBtn"
                                            onClick={this.forget}
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            Submit
                                        </Button>
                                    </Form.Item>
                                    <Row>
                                        <Col
                                            style={{
                                                margin: "auto",
                                                textAlign: "center",
                                                display: "grid",
                                                placeItems: "start",
                                            }}
                                        >
                                            {this.state.BeErr2 !== "" ? (
                                                <p
                                                    style={{
                                                        color: "red",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {this.state.BeErr2}
                                                </p>
                                            ) : null}
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Modal>
                <ToastContainer position="bottom-right"/>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({userLogin, LogOut}, dispatch);
};
export default connect(null, mapDispatchToProps)(Login);
