import {Route} from "react-router-dom";
import AgentHome from "../components/agent-view/AgentHome";
import AllAgents from "../components/agent-view/AllAgents";
import CreateAgent from "../components/agent-view/CreateAgent";
import EditAgent from "../components/agent-view/EditAgent";
import EditingSupplier from "../components/agent-view/EditingSupplier";
import ReportsPage from "../components/agent-view/ReportsPage";
// import EditTest from "../components/agent-view/EditTest";

export default function AgentsRoutes() {
    return (
        <>
            <Route exact path="/agent_home" component={AgentHome}/>
            <Route exact path="/all_agents" component={AllAgents}/>
            <Route exact path="/add_agent" component={CreateAgent}/>
            <Route exact path="/edit_agent/:id" component={EditAgent}/>
            {/* <Route exact path="/edit_agent/:id" component={EditTest} /> */}
            <Route exact path="/edit_supplier/:id" component={EditingSupplier}/>
            <Route exact path="/reports" component={ReportsPage}/>

        </>
    );
}
