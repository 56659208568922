import { v4 as uuid } from "uuid";
import { resizeFile } from "./imageResizers";

export class ChatRepository {
  extraInformation = {};

  constructor(
    firebaseDataSource,
    chatCollectionName,
    messageCollectionName,
    sender,
    extraInformation
  ) {
    this.firebaseDataSource = firebaseDataSource;
    this.chatCollectionName = chatCollectionName;
    this.sender = sender;
    this.extraInformation = extraInformation ?? {};
    this.messageCollectionName = messageCollectionName;
  }

  populatedJsonTemplate(chatId, text, voiceRef, imagesRef, location) {
    console.log({
      sender: this.sender,
      text,
      imagesRef,
      voiceRef,
      lat: location?.lat ?? null,
      lng: location?.lng ?? null,
      sentAt: this.firebaseDataSource.firestore.FieldValue.serverTimestamp(),
      chatId: chatId,
      ...this.extraInformation,
    });
    return {
      sender: this.sender,
      text,
      imagesRef: imagesRef ? (imagesRef?.length > 0 ? imagesRef : null) : null,
      voiceRef,
      lat: location?.lat ?? null,
      lng: location?.lng ?? null,
      sentAt: this.firebaseDataSource.firestore.FieldValue.serverTimestamp(),
      chatId: chatId,
      ...this.extraInformation,
    };
  }

  sendMessageWithJSON(chatId, JSON) {
    return new Promise((resolve) => {
      this.firebaseDataSource
        .firestore()
        .collection(this.chatCollectionName)
        .doc(chatId)
        .collection(this.messageCollectionName)
        .add(JSON)
        .then(resolve());
    });
  }

  //TEXT & IMAGES
  sendImageMessage(chatId, files) {
    const storageRef = this.firebaseDataSource.storage().ref();

    return new Promise((resolve) => {
      const filesUrls = [];
      files.forEach((file, i) => {
        const filename = uuid();
        const fileRef = storageRef.child(
          `${chatId}/images/${filename}.${file.type.split("/")[1]}`
        );

        fileRef.put(file).then(() => {
          console.log(`${file.name} uploaded`);

          fileRef.getDownloadURL().then((fullImageUrl) => {
            resizeFile(file, 70).then((res) => {
              const thumbRef = storageRef.child(
                `${chatId}/images/thumb-${filename}.jpeg`
              );
              thumbRef.putString(res, "data_url").then((s) => {
                thumbRef.getDownloadURL().then((thumbImageUrl) => {
                  filesUrls.push({
                    thumb: thumbImageUrl,
                    full: fullImageUrl,
                  });

                  console.log(`${filename} uploaded.`);

                  if (files.length == filesUrls.length) {
                    resolve(filesUrls);
                  }
                });
              });
            });
          });
        });
      });
    });
  }

  sendNormalMessage(chatId, text, files) {
    return new Promise((resolve) => {
      if (files.length > 0) {
        this.sendImageMessage(chatId, files).then((links) => {
          const json = this.populatedJsonTemplate(
            chatId,
            text,
            null,
            links,
            null
          );
          this.sendMessageWithJSON(chatId, json);
          resolve();
        });
      } else {
        if (text.trim().length > 0) {
          const json = this.populatedJsonTemplate(chatId, text, null, [], null);
          this.sendMessageWithJSON(chatId, json);
        }
        resolve();
      }
    });
  }

  //VOICES
  sendVoice(chatId, voice) {
    return new Promise((resolve) => {
      const filename = uuid();
      const storageRef = this.firebaseDataSource.storage().ref();
      const fileRef = storageRef.child(`${chatId}/records/${filename}.mp3`);

      var metadata = {
        contentType: "audio/mpeg",
      };

      fileRef.put(voice.blob, metadata).then(() => {
        console.log(`${filename} uploaded`);
        fileRef.getDownloadURL().then((res) => {
          const json = this.populatedJsonTemplate(chatId, null, res, [], null);

          this.sendMessageWithJSON(chatId, json).then(() => resolve());
        });
      });
    });
  }

  sendLocation = (chatId) => {
    const locationSuccess = (pos, chatId) => {
      var crd = pos.coords;

      console.log("Your current position is:");
      console.log(`Latitude : ${crd.latitude}`);
      console.log(`Longitude: ${crd.longitude}`);
      console.log(`More or less ${crd.accuracy} meters.`);

      const json = this.populatedJsonTemplate(chatId, null, null, [], {
        lat: crd.latitude,
        lng: crd.longitude,
      });

      this.sendMessageWithJSON(chatId, json);
      // .then(() => {
      //     // setLoading(false)
      // })
    };

    const locationErrors = (err) => {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    };

    const locationOptions = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            console.log(result.state);

            navigator.geolocation.getCurrentPosition((pos) =>
              locationSuccess(pos, chatId)
            );
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition(
              (pos) => locationSuccess(pos, chatId),
              locationErrors,
              locationOptions
            );
          } else if (result.state === "denied") {
            alert("Enable location permession to use this featrue.");
          }
          result.onchange = function () {
            console.log(result.state);
          };
        });
    } else {
      alert("Location is not available!");
    }
  };
}
