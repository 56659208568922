import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Form, Input, Button } from "antd";
import logo from "../imgs/logo.png";
import axios from "axios";
import { APILINK } from "../Endpoint";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            BeErr: "",
            open: false,
            BeErr2: "",
        };
    }

    handlepass = (e) => {
        this.setState({ password: e.target.value });
    };

    onFinish = (values) => {
        // console.log("Success:", values);
    };

    onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };
    openModal = (e) => {
        this.setState({ open: true });
    };
    close = (e) => {
        this.setState({ open: false });
    };
    reset = (e) => {
        let data = { password: this.state.password };
        axios
            .post(
                APILINK +
                    `/password-reset/${this.props.match.params.token}/${this.props.match.params.id}/`,
                data
            )
            .then((res) => {
                console.log(res);
                if (res) {
                    if (res.data === "Password is updated") {
                        setTimeout(() => {
                            toast.info(
                                "Password has been updated Successfully"
                            );
                        }, 500);
                        setTimeout(() => {
                            this.props.history.push("/");
                        }, 3500);
                    }
                }
            })
            .catch((err) => {
                console.log(err.response);
                this.setState({ BeErr: err.response.data });
            });
    };
    render() {
        return (
            <div>
                <Container className="py-3">
                    {/* <h3>Login page</h3> */}
                    <Form
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        style={{
                            background: "white",
                            margin: "30px auto 0px auto",
                            padding: "50px 100px",
                            borderRadius: "20px",
                            width: "50%",
                        }}
                    >
                        <img
                            style={{ height: "160px", marginBottom: "20px" }}
                            src={logo}
                            alt="yanzo-logo"
                        />
                        <h1 style={{ fontFamily: "Poppins, sansSerif" }}>
                            Reset Password
                        </h1>

                        <Form.Item
                            // label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your password!",
                                },
                            ]}
                        >
                            <Input.Password
                                value={this.state.password}
                                onChange={this.handlepass}
                                placeholder="password"
                                className="loginInput"
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                className="loginBtn"
                                onClick={this.reset}
                                type="primary"
                                htmlType="submit"
                            >
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                    <Row>
                        <Col
                            style={{ margin: "auto", textAlign: "center" }}
                            sm={12}
                        >
                            {this.state.BeErr !== "" ? (
                                <p style={{ color: "red", fontWeight: "bold" }}>
                                    {this.state.BeErr}
                                </p>
                            ) : null}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default ResetPassword;
