import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import { Link } from "react-router-dom";
import "../../style-sheets/products.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import axios from "axios";
import {
    Button,
    Form,
    Select,
    Input,
    TimePicker,
    Checkbox,
    Upload,
    message,
    Spin,
} from "antd";

import moment from "moment";
// import GetCoordinates from "../GetCoordinates";
import ViewMap from "../ViewMap";
import { connect } from "react-redux";
import Geocode from "react-geocode";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import { APILINK } from "../../Endpoint";
import { UploadOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
const axiosApiInstance = axios.create();
Geocode.setApiKey("AIzaSyDGLV2zbFZNvPOeFtuePcisgwAbmVIsSH0");

function Profile(props) {
    const [data, setData] = useState("");
    const [cate, setCate] = useState([]);
    const [keys, setKeys] = useState([]);
    const [image, setImage] = useState("");
    const [fileList, setFileList] = useState([]);
    const [location, setLocation] = useState({ lat: "", lon: "" });
    const [workingFrom, setWorkFrom] = useState("");
    const [workingTo, setWorkTo] = useState("");
    const [deliverFrom, setDeliverFrom] = useState("");
    const [deliverTo, setDeliverTo] = useState("");
    const [loading, setLoading] = useState(false);
    const [prevCat, setPrevCat] = useState([]);
    const [form] = Form.useForm();
    const history = useHistory();
    const id = useSelector((state) => state.auth.user.id);

    const onFinish = async (values) => {
        values.latitude = location.lat;
        values.longitude = location.lon;
        values.working_hours_from = workingFrom;
        values.working_hours_to = workingTo;
        values.delivery_hours_from = deliverFrom;
        values.delivery_hours_to = deliverTo;
        var newID = id.replace(/-/g, "");

        if (fileList.length > 0) {
            console.log("here");
            const eventdata = new FormData();
            eventdata.append("image", fileList[0]);
            // values.image = eventdata;
            const result = await axiosApiInstance
                .put(APILINK + `/supplier/${newID}`, eventdata)
                .catch((err) => {
                    console.log(err.response);
                });
            if (result) {
                console.log(result);
                setTimeout(() => {
                    toast.info(`Supplier was updated successfully`);
                }, 500);
                setTimeout(() => {
                    props.history.push("/suppliers");
                }, 3500);
            }
        }

        console.log(values);
        const result = await axiosApiInstance
            .put(APILINK + `/supplier/${newID}`, values)
            .catch((err) => {
                console.log(err.response);
            });
        if (result) {
            setTimeout(() => {
                toast.info(`Supplier was updated successfully`);
            }, 500);
            setTimeout(() => {
                props.history.push("/suppliers");
            }, 3500);
        }
    };
    const getDetails = async () => {
        var newID = id.replace(/-/g, "");
        const result = await axiosApiInstance.get(
            APILINK + `/suppliers/?supplier=${newID}`
        );
        console.log(result);
        if (result) {
            setData(result.data[0]);
            form.setFieldsValue({
                name: result.data[0]?.name,
                // city: result.data[0]?.city,
                address: result.data[0]?.address,
                phone: result.data[0]?.phone,
                whatsapp: result.data[0]?.whatsapp,
                website: result.data[0]?.website,
                category: result.data[0]?.category.map((cat) => cat.id),
                keyword: result.data[0]?.keyword.map((key) => key.id),
                is_deliver: result.data[0]?.is_deliver,
            });
            setImage(result.data[0]?.image);
            setLocation({
                lat: result.data[0]?.location?.lat,
                lon: result.data[0]?.location?.lon,
            });
            setWorkFrom(result.data[0]?.working_hours_from);
            setWorkTo(result.data[0]?.working_hours_to);
            setDeliverFrom(result.data[0]?.delivery_hours_from);
            setDeliverTo(result.data[0]?.delivery_hours_to);
            setLoading(false);
        }
    };
    useEffect(() => {
        setLoading(true);
        getCategories();
        getKeys();
        getDetails();
    }, []);

    const getCategories = async () => {
        const result = await axiosApiInstance.get(
            APILINK + "/categories/?limit=10000"
        );

        setCate(result.data.results);
    };
    const getKeys = async () => {
        const result = await axiosApiInstance.get(
            APILINK + `/keywords/?limit=10000`
        );

        setKeys(result.data.results);
    };
    const uploadprops = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            console.log(file);
            setFileList([file]);
            return false;
        },
        fileList,
    };
    const handleWorkingFrom = (time, timeString) => {
        console.log(timeString);
        setWorkFrom(timeString);
    };

    const handleWorkingTo = (time, timeString) => {
        setWorkTo(timeString);
    };

    const handleDelieveryFrom = (time, timeString) => {
        setDeliverFrom(timeString);
    };
    const handleDelieveryTo = (time, timeString) => {
        setDeliverTo(timeString);
    };
    console.log(data?.category);
    return (
        <Container>
            <Row>
                <Col
                    className="p-4 mb-4"
                    style={{ textAlign: "initial" }}
                    sm={12}
                >
                    <h2>
                        {" "}
                        <i
                            style={{ cursor: "pointer" }}
                            class="fas fa-chevron-left px-1"
                            onClick={history.goBack}
                        ></i>
                        <i class="fas fa-shopping-basket pr-2"></i>Profile
                        Details{" "}
                    </h2>
                </Col>
            </Row>
            {loading ? (
                <Row>
                    <Col sm="12" className="text-center">
                        <Spin tip="loading....." />
                    </Col>
                </Row>
            ) : (
                <Form form={form} onFinish={onFinish} layout="vertical">
                    <Row>
                        <Col sm="12" md="6">
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col sm="12" md="6">
                            <Form.Item
                                label="City"
                                name="city"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required",
                                    },
                                ]}
                                initialValue={data?.city}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" md="6">
                            <Form.Item
                                label="Phone"
                                name="phone"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col sm="12" md="6">
                            <Form.Item label="Whats app" name="whatsapp">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" md="6">
                            <Form.Item
                                label="Categories"
                                name="category"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required",
                                    },
                                ]}
                                // initialValue={}
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Select Categories"
                                    style={{ width: "100%" }}
                                    // showSearch
                                >
                                    {cate?.map((item) => (
                                        <Select.Option
                                            value={item.id}
                                            name={item.name}
                                        >
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col sm="12" md="6">
                            <Form.Item
                                label="Keywords"
                                name="keyword"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required",
                                    },
                                ]}
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Select keywords"
                                    style={{ width: "100%" }}
                                    showSearch
                                    // defaultValue={data?.keyword}
                                >
                                    {keys?.map((item) => (
                                        <Select.Option value={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" md="6">
                            <Form.Item
                                label="Address"
                                name="address"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col sm="12" md="6">
                            <Form.Item label="Website" name="website">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" md="6">
                            <Form.Item label="Working hours from">
                                <TimePicker
                                    value={moment(workingFrom, "HH:mm:ss")}
                                    onChange={handleWorkingFrom}
                                />
                            </Form.Item>
                        </Col>
                        <Col sm="12" md="6">
                            <Form.Item label="Working hours to">
                                <TimePicker
                                    value={moment(workingTo, "HH:mm:ss")}
                                    onChange={handleWorkingTo}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" md="6">
                            <Form.Item label="Delivery hours from">
                                <TimePicker
                                    value={moment(deliverFrom, "HH:mm:ss")}
                                    onChange={handleDelieveryFrom}
                                />
                            </Form.Item>
                        </Col>
                        <Col sm="12" md="6">
                            <Form.Item label="Delivery hours to">
                                <TimePicker
                                    value={moment(deliverTo, "HH:mm:ss")}
                                    onChange={handleDelieveryTo}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" md="6">
                            <Form.Item label="Uploaded Image">
                                {image ? (
                                    <>
                                        {" "}
                                        <img
                                            width="250px"
                                            height="250px"
                                            src={`${APILINK}${image}`}
                                        ></img>
                                        <Upload
                                            {...uploadprops}
                                            multiple={false}
                                            maxCount={1}
                                        >
                                            <Button icon={<UploadOutlined />}>
                                                Click to Upload New
                                            </Button>
                                        </Upload>
                                    </>
                                ) : (
                                    <>
                                        {" "}
                                        <p>No image uploaded</p>
                                        <Upload
                                            {...uploadprops}
                                            multiple={false}
                                            maxCount={1}
                                        >
                                            <Button icon={<UploadOutlined />}>
                                                Click to Upload
                                            </Button>
                                        </Upload>
                                    </>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="mt-4" sm="12" md="6">
                            <Form.Item
                                valuePropName="checked"
                                label=""
                                name="is_deliver"
                            >
                                <Checkbox>
                                    {/* Is Deliver? */}
                                    Delivery available
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col sm="12" md="6">
                            <Button
                                color="primary"
                                htmlType="submit"
                                className="addBtn2"
                            >
                                Save Changes
                            </Button>
                        </Col>
                    </Row>
                </Form>
            )}
        </Container>
    );
}
export default ErrorHandler(Profile, axiosApiInstance);
