import { Route } from "react-router-dom";
import Orders from "../components/supplier-pages/Orders";

export default function OrdersRoutes() {
  return (
    <>
      <Route exact path="/orders" component={Orders} />
    </>
  );
}
