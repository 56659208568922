import { Route } from "react-router-dom";
import EditProduct from "../components/edit_view sections/EditProduct";
import ViewProduct from "../components/edit_view sections/ViewProduct";
import Add_product from "../components/supplier-pages/Add_product";
import Products from "../components/supplier-pages/Products";

export default function ProductsRoutes() {
  return (
    <>
      <Route exact path="/products" component={Products} />
      <Route exact path="/add_product" component={Add_product} />
      <Route exact path="/view_product/:id" component={ViewProduct} />
      <Route exact path="/edit_product/:id" component={EditProduct} />
    </>
  );
}
