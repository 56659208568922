import React, { useEffect, useState } from "react";
import styles from "./CourierServicesForm.module.css";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Select,
  Space,
  TimePicker,
} from "antd";
import ServicesListItem from "./ServicesListItem";

export default function CourierServicesForm({
  vehicles,
  delivery,
  initialValues,
  onChange,
  getVehicles,
  getService,
  emirates,
}) {
  const [services, setServices] = useState(
    initialValues ?? [
      {
        key: Math.random(),
        vehicle: undefined,
        delivery: undefined,
        pick_up_emirate: undefined,
        pick_up_area: null,
        drop_off_emirate: undefined,
        drop_off_area: null,
        price: undefined,
      },
    ]
  );
  const [isAddDisabled, setAddDisabled] = useState(true);

  function onAdd(e) {
    const lastItem = services[services.length - 1];
    if (lastItem.vehicle === undefined || lastItem.delivery === undefined)
      return;
    setServices([
      ...services,
      {
        key: Math.random(),
        vehicle: undefined,
        delivery: undefined,
        pick_up_emirate: undefined,
        pick_up_area: null,
        drop_off_emirate: undefined,
        drop_off_area: null,
        price: undefined,
      },
    ]);
  }

  function onItemChange(type, value, index) {
    const newService = [...services];
    newService[index][type] = value;
    console.log({ newService });
    setServices(newService);
  }

  function isDisabled() {
    const lastItem = { ...services[services.length - 1] };
    delete lastItem.area;
    return Object.keys(lastItem).reduce(
      (prev, cur) => (prev ? prev : lastItem[cur] == undefined),
      false
    );
  }

  function onItemDelete(i) {
    if (services.length <= 1) {
      setServices([
        {
          key: Math.random(),
          vehicle: undefined,
          delivery: undefined,
          pick_up_emirate: undefined,
          pick_up_area: null,
          drop_off_emirate: undefined,
          drop_off_area: null,
          price: undefined,
        },
      ]);
      return;
    }
    const newService = [...services];
    newService.splice(i, 1);
    setServices(newService);
  }

  useEffect(() => {
    setAddDisabled(isDisabled());
    console.log({ isDisabled: isDisabled() });
  }, [Object.values(services[services.length - 1])]);

  useEffect(() => {
    onChange(services);
  }, [services]);

  return (
    <div className={styles.container}>
      <div className={styles.common}>
        <Form.Item
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
          label="Last time to recieve order"
          name="last_time_submit"
        >
          <TimePicker format={"HH:mm"} />
        </Form.Item>
        <Form.Item valuePropName="checked" label="" name="provide_wallet">
          <Checkbox>Provide Wallet</Checkbox>
        </Form.Item>
        <Form.Item valuePropName="checked" label="" name="approval_need">
          <Checkbox>Approval Need</Checkbox>
        </Form.Item>
      </div>

      <div className={styles.serviceList}>
        <h5>Services</h5>
        {services.map((it, i) => (
          <ServicesListItem
            index={i}
            key={it.key}
            emirates={emirates}
            delivery={delivery}
            vehicles={vehicles}
            onDelete={() => onItemDelete(i)}
            deleteDisabled={i == 0}
            value={it}
            onChange={(type, value) => {
              console.log({ type, value });
              onItemChange(type, value, i);
            }}
            getVehicles={getVehicles}
            getService={getService}
          />
        ))}

        <Button disabled={isAddDisabled} className={`addBtn2`} onClick={onAdd}>
          <span>+</span> <span>Add Service</span>
        </Button>
      </div>
    </div>
  );
}
