import { Route } from "react-router-dom";
import EditBranch from "../components/edit_view sections/EditBranch";
import ViewBranch from "../components/edit_view sections/ViewBranch";
import Add_branch from "../components/supplier-pages/Add_branch";
import Branches from "../components/supplier-pages/Branches";

export default function BranchesRoutes() {
  return (
    <>
      <Route exact path="/view_branch/:id" component={ViewBranch} />
      <Route exact path="/edit_branch/:id" component={EditBranch} />
      <Route exact path="/branches" component={Branches} />
      <Route exact path="/add_branch" component={Add_branch} />
    </>
  );
}
