import React, { useState, useEffect } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import "../../style-sheets/products.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Button, Form, Select, Input, DatePicker } from "antd";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import { APILINK } from "../../Endpoint";
import Geocode from "react-geocode";
import ViewMap from "../ViewMap";
import moment from "moment";

const axiosApiInstance = axios.create();

function StandAloneOrders(props) {
  // const [emirates, setEmirates] = useState([]);
  // const [areas, setAreas] = useState([]);
  const [coSearchData, setCoSearchData] = useState();
  const [dropOffLocation, setDropOffLocation] = useState({});
  const [pickupLocation, setPickupLocation] = useState({});

  const [form] = Form.useForm();
  useEffect(() => {
    const coSearchData = JSON.parse(sessionStorage.getItem("CoSearchData"));
    console.log({ coSearchData });
    form.setFieldsValue({
      emirate_seller: coSearchData?.pickupEmirate.id,
      emirate_buyer: coSearchData?.dropOffEmirate.id,
      // emirate_buyer_name: coSearchData.dropOffEmirate.name,

      area_seller: coSearchData?.pickupArea.num,
      area_buyer: coSearchData?.dropOffArea.num,
      notes: coSearchData?.notes,
      scheduled_date: `${coSearchData?.scheduledDate?.split("T")[0]} at ${
        coSearchData?.selectedTime ?? ""
      }`,
    });
    setCoSearchData(coSearchData);
  }, []);

  const handleAddress = (e, setState) => {
    // Get latitude & longitude from address.
    Geocode.fromAddress(e.target.value).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setState({
          lat: lat,
          lon: lng,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const onFinish = async (values) => {
    const datePieces = values.scheduled_date?.split(" at ");
    if (datePieces.length === 2) {
      if (!datePieces[1]) datePieces[1] = "00:00";
    }
    const dateFinal = datePieces.join("T");
    let data = {
      orders: [
        {
          delivery_date: dateFinal,
          id: parseInt(values.order_number),
          product: {
            id: Math.floor(Math.random() * (1000000000 - 0 + 1)) + 0,
            name: values.name,
            price: values.price,
            weight: 0.1,
            volume: 0.1,
            sku: "0.1",
          },
          order_number: parseInt(values.order_number),
          quantity: 1,
          status: 8,
          payment_method: 0,
          cash_amount: values.price,
          shipping_fees: 0.1,
          notes_from_admin: values.notes,
        },
      ],
      buyer: {
        full_name: values.full_name_buyer,
        address: values.address_buyer,
        phone: values.phone_buyer,
        position: `${dropOffLocation.lat},${dropOffLocation.lon}`,
        area: values.area_buyer,
        emirate: coSearchData.dropOffEmirate.num,
      },
      seller: {
        full_name: values.full_name_seller,
        address: values.address_seller,
        phone: values.phone_seller,
        position: `${pickupLocation.lat},${pickupLocation.lon}`,
        area: values.area_seller,
        emirate: coSearchData.pickupEmirate.num,
      },
      ecommerce: {
        e_commerce_domain: "http://127.0.0.1:8000/",
      },
      shipper: {
        email_shipper: props.selectedCourierEmail,
        id: props.selectedCourier,
      },
      order_type: "2",
      courier_service: props.selectedCourierService,
    };
    const result = await axiosApiInstance
      .post(APILINK + `/courier_order`, data)
      .catch((err) => {
        if (err.response.status == 500) {
          setTimeout(() => {
            toast.error(`Server Error!`);
          }, 500);
        } else {
          console.log(err.response);
          setTimeout(() => {
            toast.error(err.response.data?.error);
          }, 500);
        }
      });
    console.log(result);
    if (result) {
      setTimeout(() => {
        toast.info(`Request has been sent successfully`);
      }, 200);
      props.closeorder();
      setTimeout(() => {
        props.history.push("/courier_orders");
      }, 3500);
    }
    // props.history.push("/courier_orders");
  };

  // const getEmirates = async () => {
  //     const result = await axiosApiInstance.get(APILINK + "/emirate");
  //     setEmirates(result.data.results);
  // };
  // const getAreas = async (e) => {
  //     const result = await axiosApiInstance.get(APILINK + `/areas/${e}`);
  //     console.log(result);
  //     setAreas(result.data);
  // };

  console.log(props);
  return (
    <Modal
      dialogClassName="my-modal"
      show={props.showorder}
      onHide={props.closeorder}
    >
      <Container className="p-4">
        <Row>
          <Col className="mb-4" style={{ textAlign: "initial" }} sm={12}>
            <h6>Create Order for Courier</h6>
          </Col>
        </Row>

        <Form onFinish={onFinish} form={form} layout="vertical">
          <Row>
            <Col sm="12" md="4">
              <Form.Item
                label="Order Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col sm="12" md="4">
              <Form.Item
                label="Ticket Number"
                name="order_number"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col sm="12" md="4">
              <Form.Item
                label="Order Price"
                name="price"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>{" "}
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="6">
              {" "}
              <Form.Item
                label="Drop off Name"
                name="full_name_buyer"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col sm="12" md="6">
              <Form.Item
                label="Pickup Name"
                name="full_name_seller"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="6">
              <Form.Item
                label="Drop off Phone"
                name="phone_buyer"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col sm="12" md="6">
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
                label="Pickup Phone"
                name="phone_seller"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="6">
              <Form.Item
                label="Drop off Address"
                name="address_buyer"
                onChange={(e) => handleAddress(e, setDropOffLocation)}
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col sm="12" md="6">
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
                label="Pickup Address"
                name="address_seller"
                onChange={(e) => handleAddress(e, setPickupLocation)}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col sm="12" md="6">
              <ViewMap location={dropOffLocation} />
            </Col>
            <Col sm="12" md="6">
              <ViewMap location={pickupLocation} />
            </Col>
          </Row>
          {coSearchData && (
            <>
              <Row>
                <Col sm="12" md="6">
                  <Form.Item
                    label="Drop off emirate"
                    name="emirate_buyer"
                    // initialValue={}
                  >
                    <Select
                      placeholder="Select Emirate"
                      style={{ width: "100%" }}
                      disabled={true}
                    >
                      <Select.Option
                        value={coSearchData.dropOffEmirate?.id}
                        name={coSearchData.dropOffEmirate?.name}
                      >
                        {coSearchData.dropOffEmirate?.name}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col sm="12" md="6">
                  <Form.Item label="Pickup emirate" name="emirate_seller">
                    <Select
                      placeholder="Select Emirate"
                      style={{ width: "100%" }}
                      showSearch
                      disabled={true}
                    >
                      <Select.Option
                        selected={true}
                        value={coSearchData.pickupEmirate?.id}
                        name={coSearchData.pickupEmirate?.name}
                      >
                        {coSearchData.pickupEmirate?.name}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col sm="12" md="6">
                  <Form.Item name="area_buyer" label="Drop off area">
                    <Select
                      placeholder="Select Area"
                      style={{ width: "100%" }}
                      disabled={true}
                      value={coSearchData.dropOffArea?.num}
                      // showSearch
                    >
                      <Select.Option
                        value={coSearchData.dropOffArea?.num}
                        name={coSearchData.dropOffArea?.num}
                      >
                        {coSearchData.dropOffArea?.name}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col sm="12" md="6">
                  <Form.Item label="Pickup area" name="area_seller">
                    <Select
                      placeholder="Select Area"
                      style={{ width: "100%" }}
                      showSearch
                      disabled={true}
                      value={coSearchData.pickupArea?.num}
                      // defaultValue={data?.keyword}
                    >
                      <Select.Option
                        value={coSearchData.pickupArea?.num}
                        name={coSearchData.pickupArea?.num}
                      >
                        {coSearchData.pickupArea?.name}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          <Row>
            {coSearchData?.notes && (
              <Col sm="12" md="6">
                <Form.Item
                  label="Notes"
                  name="notes"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input value={coSearchData.notes} disabled />
                </Form.Item>
              </Col>
            )}
            {coSearchData?.scheduledDate && (
              <Col sm="12" md="6">
                <Form.Item
                  label="Delivery Date"
                  name="scheduled_date"
                  rules={[
                    {
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input
                    value={`${coSearchData.scheduledDate} ${
                      coSearchData.selectedTime ?? ""
                    }`}
                    disabled
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row>
            <Col className="text-center" sm="12">
              <Button color="primary" htmlType="submit" className="addBtn2">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </Modal>
  );
}

export default ErrorHandler(StandAloneOrders, axiosApiInstance);
