import React, { useState } from "react";
import styles from "./CourierChatComponent.module.css";
import CourierChat from "../../../chat/CourierChat";

export default function CourierChatComponent({ details }) {
  const [open, setOpen] = useState(true);
  const [loaded, setLoaded] = useState(false);
  return (
    <div
      className={[styles.container, open && loaded ? styles.open : ""].join(
        " "
      )}
    >
      <header
        onClick={() => {
          setOpen((prev) => !prev);
        }}
      >
        <h5>{details.order_name} Chat</h5>
        <button
          style={{
            backgroundColor: "transparent",
            border: "none",
            borderRadius: "50%",
            marginInlineStart: "6px",
          }}
        >
          <i className=" fas fa-chevron-up"></i>
        </button>
      </header>
      <CourierChat
        details={details}
        onLoaded={(loaded) => {
          setOpen(loaded);
          setLoaded(loaded);
        }}
      />
    </div>
  );
}
