import React, {useEffect, useState} from "react";
import styles from "./EmiratesTable.module.css";
import ErrorHandler from "../../../../../ErrorHandler/ErrorHandler";
import {APILINK} from "../../../../../Endpoint";
import axios from "axios";

const axiosApiInstance = axios.create();

function AreasList({
                       emirateId, priceList, index, containPrices, emiratePrice,
                       onEmiratePriceChange, onAreasPriceChange, isWhole, onAddEmirate,
                   }) {


    const [areas, setAreas] = useState([]);
    const [areasPrice, setAreasPrice] = useState({});
    const [loading, setLoading] = useState(false);

    async function fetchAreas() {
        setLoading(true);
        const response = await axiosApiInstance.get(APILINK + `/areas/${emirateId}?limit=1000`)
            .catch((err) => console.log(err.response));
        if (response.data) setAreas(response.data);
        setLoading(false);
    }

    function setSelection() {

        if (!areas) return;
        if (!containPrices || !priceList) {
            setAreasPrice({});
            return;
        }

        /**
         * the set all areas as selected when user clicks on an area
         * checkbox in the other section.
         */
        if (isWhole && Object.keys(priceList.areas).length === 0) {

            let newPrices = {};
            areas.forEach(it => {
                newPrices[it.id] = priceList.areas[it.id] ?? 0
            })
            onAreasPriceChange(emirateId, newPrices)
        }


        setAreasPrice(priceList.areas);

    }

    // getAreas
    useEffect(() => {
        fetchAreas().then((_) => {
            if (isWhole)
                setSelection()
        })
    }, [emirateId, index]);

    useEffect(() => {
        if (areas)
            setSelection()
    }, [priceList, containPrices])


    function onAdd(checked, areaId) {
        let newPrices;
        if (checked) {
            newPrices = {
                ...areasPrice, [areaId]: null,
            }

        } else {
            newPrices = {...areasPrice}
            delete newPrices[areaId]

        }

        setAreasPrice(newPrices);
        onAreasPriceChange(emirateId, newPrices)

    }

    function onPriceChange(areaId, value) {
        const newPrices = {...areasPrice, [areaId]: +value}
        setAreasPrice(newPrices)
        onAreasPriceChange(emirateId, newPrices)
    }

    return (
        <div className={styles.areasList}>
            <div className={styles.emiratePrice}>
                <div>Whole Emirate</div>
                <input type="number" min="0" value={emiratePrice}
                       disabled={!isWhole}
                       onChange={(e) => {
                           onEmiratePriceChange(emirateId, e.target.value)
                       }}/>
                <input type="checkbox"
                       checked={containPrices && isWhole}
                       onChange={(e) => {
                           onAddEmirate(e.target.checked, emirateId)
                       }}/>
            </div>
            <hr/>
            <div>
                {
                    loading ? <div>...Loading</div> : areas.map((it, i) => {
                        return <AreasListItem
                            key={it.id}
                            value={
                                priceList?.areas?.[it.id] == 0 ? null :
                                    priceList?.areas[it.id]
                            }
                            area={it}
                            isSelected={priceList && it.id in priceList?.areas}
                            onPriceChange={(v) => onPriceChange(it.id, v)}
                            isWholeEmirate={isWhole}
                            onAdd={(checked) => onAdd(checked, it.id)}
                        />
                    })
                }
            </div>
        </div>
    )
}


function AreasListItem({area, isSelected, value, onPriceChange, onAdd, isWholeEmirate}) {
    return (
        <div className={`${styles.emirateListItem} ${isSelected ? styles.selected : ""}`}>
            <div>{area.name}</div>
            <input type="number" value={value} onChange={(e) => onPriceChange(e.target.value)}/>
            <input type="checkbox" checked={isSelected ?? false} onChange={(e) => {
                if (!isWholeEmirate) {
                    onAdd(e.target.checked)
                }
            }}/>
        </div>
    )
}

export default ErrorHandler(AreasList, axiosApiInstance);
