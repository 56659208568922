import { GET_AREAS } from "../actions/actionTypes";
const initialState = {
  areas: ""
};

export default function (state = initialState, action) {
  //  (action.payload);
  switch (action.type) {
    case GET_AREAS:
      return {
        ...state,
        areas: action.payload
      };

    default:
      return state;
  }
}
