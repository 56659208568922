import React, {Component} from "react";
import "../../style-sheets/sideMenu.css";
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {show: false};
    }

    showMenu = () => {
        this.setState({show: !this.state.show});
        console.log(this.state.show);
    };

    adminPages = () => {
        return (
            <ul className="side-list">
                {console.log("usertype : ", this.props.user.user_type)}
                {console.log("case : 1 ")}

                <li style={{paddingBottom: "50px"}}>
                    <NavLink
                        to="/agent_home"
                        exact
                        //   className="navitem mynavitem"
                        activeClassName="navitem-active"
                    >
                        <i className="fas fa-home pr-2"></i>Home
                    </NavLink>
                </li>

                {this.props.user.is_staff === true ? (
                    <li>
                        <NavLink
                            to="/all_agents"
                            exact
                            //   className="navitem mynavitem"
                            activeClassName="navitem-active"
                        >
                            <i className="fas fa-users pr-2"></i>All Agents
                        </NavLink>
                    </li>
                ) : null}

                <li>
                    {" "}
                    <NavLink
                        to="/suppliers"
                        exact
                        //   className="navitem mynavitem"
                        activeClassName="navitem-active"
                    >
                        <i className="fas fa-shopping-basket pr-2"></i>All Suppliers
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/view_all_courier"
                        exact
                        //   className="navitem mynavitem"
                        activeClassName="navitem-active"
                    >
                        <i className="fas fa-truck pr-2"></i>All Couriers
                    </NavLink>
                </li>

                <li>
                    <NavLink
                        to="/requests"
                        exact
                        //   className="navitem mynavitem"
                        activeClassName="navitem-active"
                    >
                        <i className="fas fa-clipboard-list pr-2"></i>Requests
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/orders"
                        exact
                        //   className="navitem mynavitem"
                        activeClassName="navitem-active"
                    >
                        <i className="fas fa-cart-arrow-down pr-2"></i>Orders
                    </NavLink>
                </li>
                {this.props.user.is_staff === true ? (
                    <li>
                        <NavLink
                            to="/reports"
                            exact
                            activeClassName="navitem-active"
                        >
                            <i className="fas fa-file pr-2"></i>Reports
                        </NavLink>
                    </li>
                ) : null}

                <li>
                    <NavLink
                        to="/categories"
                        exact
                        //   className="navitem mynavitem"
                        activeClassName="navitem-active"
                    >
                        <i className="fas fa-sitemap pr-2"></i>Categories
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/key"
                        exact
                        //   className="navitem mynavitem"
                        activeClassName="navitem-active"
                    >
                        <i className="fas fa-search-plus pr-2"></i>Keyword
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/courier_orders"
                        exact
                        //   className="navitem mynavitem"
                        activeClassName="navitem-active"
                    >
                        <i className="fas fa-shopping-cart pr-2"></i>Courier Orders
                    </NavLink>
                </li>
            </ul>
        );
    };

    supplierPages = () => {
        return (
            <ul className="side-list">
                {console.log("usertype : ", this.props.user.user_type)}
                {console.log("case :2 ")}
                <li style={{paddingBottom: "50px"}}>
                    <NavLink to="/home" exact activeClassName="navitem-active">
                        <i className="fas fa-home pr-2"></i>Home
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/employees" exact activeClassName="navitem-active">
                        <i className="fas fa-shopping-basket pr-2"></i>Employees
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/products" exact activeClassName="navitem-active">
                        <i className="fas fa-shopping-basket pr-2"></i>Products
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/key" exact activeClassName="navitem-active">
                        <i className="fas fa-search-plus pr-2"></i>Keyword
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/requests" exact activeClassName="navitem-active">
                        <i className="fas fa-clipboard-list pr-2"></i>Requests
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/orders" exact activeClassName="navitem-active">
                        <i className="fas fa-cart-arrow-down pr-2"></i>Orders
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/branches" exact activeClassName="navitem-active">
                        <i className="fas fa-building pr-2"></i>Branches
                    </NavLink>
                </li>
            </ul>
        );
    };

    employeePages = () => {
        return (
            <ul className="side-list">
                {console.log("usertype : ", this.props.user.user_type)}
                {console.log("case :3 ")}

                <li style={{paddingBottom: "50px"}}>
                    <NavLink to="/home" exact activeClassName="navitem-active">
                        <i className="fas fa-home pr-2"></i>Home
                    </NavLink>
                </li>

                <li>
                    <NavLink to="/products" exact activeClassName="navitem-active">
                        <i className="fas fa-shopping-basket pr-2"></i>Products
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/key" exact activeClassName="navitem-active">
                        <i className="fas fa-search-plus pr-2"></i>Keyword
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/requests" exact activeClassName="navitem-active">
                        <i className="fas fa-clipboard-list pr-2"></i>Requests
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/orders" exact activeClassName="navitem-active">
                        <i className="fas fa-cart-arrow-down pr-2"></i>Orders
                    </NavLink>
                </li>
            </ul>
        );
    };

    render() {
        return (
            <aside className={`my-menu ${this.state.show ? "hide" : " show"} `}>
                <button onClick={() => this.showMenu()} className="showMenuBtn">
                    {this.state.show ? (
                        <i class="fas fa-arrow-right"></i>
                    ) : (
                        <i class="fas fa-arrow-left"></i>
                    )}
                </button>
                {this.props.user.user_type === "1"
                    ? this.adminPages()
                    : this.props.user.user_type === "2"
                        ? this.supplierPages()
                        : this.employeePages()}
            </aside>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuth: state.auth.isAuth,
    user: state.auth.user,
});

export default connect(mapStateToProps, null)(Header);
