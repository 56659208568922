import React, { Component } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import "../../style-sheets/products.css";
import { connect } from "react-redux";
import { APILINK } from "../../Endpoint";
import { Spin } from "antd";
import { Link } from "react-router-dom";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

const axiosApiInstance = axios.create();
class Requests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tableData: [],
      keyWord: "",
      columns: [
        {
          name: "Request Name",
          selector: "pre_request.name",
          sortable: true,
          right: true
        },
        {
          name: "Quantity",
          selector: "pre_request.product_quantity",
          sortable: true,
          right: true
        },

        {
          name: "Status",
          button: true,
          cell: (row) => (
            <div className="courierTable">
              {row.offer_status === "2" || row.request_status === "2" ? (
                <p style={{ color: "red" }}>Rejected</p>
              ) : (
                <p style={{ color: "green" }}>In progress</p>
              )}
            </div>
          )
        },
        {
          name: "Actions",
          button: true,
          cell: (row) => (
            <div className="courierTable">
              <OverlayTrigger
                key={"top"}
                placement={"top"}
                overlay={
                  <Tooltip id={`tooltip-${"top"}`}>View Details</Tooltip>
                }
              >
                <Link to={`/view_request_details/${row.id}`}>
                  <i class="fas fa-eye"></i>{" "}
                </Link>
              </OverlayTrigger>
            </div>
          )
        }
      ],
      nextUrl: "",
      prevUrl: "",
      dis1: true,
      dis2: false
    };
  }

  getRequests = async () => {
    this.setState({ loading: true });
    // Request interceptor for API calls
    var newID =
      this.props.user.user_type === "3"
        ? this.props.user.supplier_id.replace(/-/g, "")
        : this.props.user.id.replace(/-/g, "");
    console.log(newID);
    if (
      this.props.user.user_type === "2" ||
      this.props.user.user_type === "3"
    ) {
      const result = await axiosApiInstance.get(
        APILINK + `/requests/?supplier=${newID}&request_type__in=1__2&limit=10`
      );
      if (result) {
        this.setState({ tableData: result.data.results, loading: false });

        if (result.data.next !== null) {
          this.setState({
            nextUrl: result.data.next
          });
        }

        if (result.data.previous !== null) {
          this.setState({
            prevUrl: result.data.previous
          });
        }
        if (result.data.next === null) {
          this.setState({ dis2: true });
        }
      }
    } else {
      const result = await axiosApiInstance.get(
        APILINK + `/requests/?agent=${newID}&request_type__in=1__2&limit=10`
      );
      if (result) {
        this.setState({ tableData: result.data.results, loading: false });

        if (result.data.next !== null) {
          this.setState({
            nextUrl: result.data.next
          });
        }

        if (result.data.previous !== null) {
          this.setState({
            prevUrl: result.data.previous
          });
        }
        if (result.data.next === null) {
          this.setState({ dis2: true });
        }
      }
    }
  };

  componentDidMount() {
    this.getRequests();
  }

  onSearch = (e) => {
    console.log(e.target);
    this.setState({ keyWord: e.target.value });
    let x = this.state.tableData.filter(
      (item) =>
        item.pre_request.name &&
        item.pre_request.name
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
    );
    console.log(x);
    this.setState({ tableData: x });
    if (e.target.value === "") {
      this.getRequests();
    }
  };

  getNext = async () => {
    console.log(this.state.nextUrl);

    const result = await axiosApiInstance.get(`${this.state.nextUrl}`);

    console.log("orderssss");

    console.log(result.data);
    this.setState({
      tableData: result.data.results
      // nextUrl: result.data.next,
      // prevUrl: result.data.previous,
    });
    if (result.data.next !== null) {
      this.setState({
        nextUrl: result.data.next
        // prevUrl: result.data.previous,
      });
    }
    if (result.data.previous !== null) {
      this.setState({
        prevUrl: result.data.previous,
        dis1: false
      });
    }
    if (result.data.next === null) {
      this.setState({ dis2: true, dis1: false });
    }
  };

  getPrev = async () => {
    const result = await axiosApiInstance.get(`${this.state.prevUrl}`);

    console.log("orderssss");
    console.log(result.data);
    this.setState({
      tableData: result.data.results
      // nextUrl: result.data.next,
      // prevUrl: result.data.previous,
    });
    if (result.data.next !== null) {
      this.setState({
        nextUrl: result.data.next,
        dis2: false

        // prevUrl: result.data.previous,
      });
    }
    if (result.data.previous !== null) {
      this.setState({
        prevUrl: result.data.previous
      });
    }
    if (result.data.previous === null) {
      this.setState({ dis1: true, dis2: false });
    }
  };

  render() {
    // console.log(this.state.startDate,this.state.endDate)
    return (
      <div>
        <Container
          style={{
            marginTop: "120px"
            //  marginLeft: "350px"
          }}
          className="py-3"
        >
          <Row>
            <Col className="mb-4" style={{ textAlign: "initial" }} sm={12}>
              <h2>
                <i
                  style={{ cursor: "pointer" }}
                  class="fas fa-chevron-left px-1"
                  onClick={this.props.history.goBack}
                ></i>{" "}
                <i class="fas fa-clipboard-list pr-2"></i> Requests
              </h2>
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              {this.state.loading === false ? (
                <DataTable
                  columns={this.state.columns}
                  data={this.state.tableData}
                  onSelectedRowsChange={this.handleSelected}
                />
              ) : (
                <Col className="text-center" sm={12}>
                  {this.state.loading ? (
                    <Spin tip="Loading..."></Spin>
                  ) : (
                    "No data to be displayed"
                  )}
                </Col>
              )}
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: "initial" }} sm={12}>
              {this.state.tableData.length === 0 ? null : (
                <div className="p-3">
                  {" "}
                  <Button
                    disabled={this.state.dis1}
                    onClick={this.getPrev}
                    className="nextBtn"
                  >
                    <i class="fas fa-angle-double-left"></i> Previous
                  </Button>
                  <Button
                    disabled={this.state.dis2}
                    onClick={this.getNext}
                    className="nextBtn"
                  >
                    Next<i class="fas fa-angle-double-right pl-1"></i>
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuth,
  user: state.auth.user
});

export default connect(
  mapStateToProps,
  null
)(ErrorHandler(Requests, axiosApiInstance));
