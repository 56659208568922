import React, { Component } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Container, Row, Col, Button } from "react-bootstrap";
import "../../style-sheets/products.css";
import { connect } from "react-redux";
import { APILINK } from "../../Endpoint";
import { Spin } from "antd";
import img from "../../imgs/no-img.png";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import MarketPrice from "../MarketPrice";

const axiosApiInstance = axios.create();

class ViewRequestDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      details: "",
      loading: false,
    };
  }

  getRequests = async () => {
    this.setState({ loading: true });
    let result = "";
    if (this.props.user.user_type == 2) {
      result = await axiosApiInstance.get(
        APILINK + `/requests/?id=${this.props.match.params.id}`
      );
    } else {
      result = await axiosApiInstance.get(
        APILINK + `/requests/?id=${this.props.match.params.id}`
      );
    }

    if (result) {
      this.setState({ details: result?.data[0], loading: false });
    }
  };

  componentDidMount() {
    this.getRequests();
  }

  render() {
    // console.log(this.state.startDate,this.state.endDate)
    return (
      <div>
        <Container
          style={{
            marginTop: "120px",
            // marginLeft: "350px"
          }}
          className="py-3"
        >
          <Row>
            <Col className="mb-4" style={{ textAlign: "initial" }} sm={12}>
              <h2>
                <i
                  style={{ cursor: "pointer" }}
                  class="fas fa-chevron-left px-1"
                  onClick={this.props.history.goBack}
                ></i>{" "}
                <i class="fas fa-clipboard-list pr-2"></i> Request Details
              </h2>
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: "center" }} sm="12">
              {this.state.loading ? <Spin tip="...loading" /> : null}
            </Col>
          </Row>

          {this.state.loading === false && this.state.details !== "" ? (
            <Row>
              <Col sm="6">
                <Row className="mb-1">
                  <Col sm={9}>
                    <p style={{ color: "grey", fontSize: "25px" }}>
                      {this.state.details.pre_request.name}{" "}
                    </p>
                  </Col>
                </Row>

                <Row className="my-1">
                  <Col sm={12} md={6}>
                    <p className="orderDetailsInfo1">
                      Supplier Name :{" "}
                      <span className="orderDetailsInfo2">
                        {this.state.details.supplier.name}
                      </span>
                    </p>
                    <p className="orderDetailsInfo1">
                      Order Code :{" "}
                      <span className="orderDetailsInfo2">
                        {this.state.details.pre_request.ticket_code}
                      </span>
                    </p>
                    <p className="orderDetailsInfo1">
                      Request Name :{" "}
                      <span className="orderDetailsInfo2">
                        {this.state.details.pre_request.name}
                      </span>
                    </p>

                    <p className="orderDetailsInfo1">
                      Yanzo Price :{" "}
                      <span className="orderDetailsInfo2">
                        {Object.keys(this.state.details.product).length !== 0 &&
                        Object.keys(this.state.details.offers).length === 0
                          ? this.state.details.product?.yanzo_price
                          : this.state.details.yanzo_price}
                        {/* {this.props.details.yanzo_price} */}
                      </span>
                    </p>
                    {this.state.details.offers &&
                    Object.keys(this.state.details.offers).length === 0 &&
                    Object.getPrototypeOf(this.state.details.offers) ===
                      Object.prototype ? (
                      <>
                        <p className="orderDetailsInfo1">
                          Product Code : {this.state.details.product?.code}{" "}
                        </p>
                        <p className="orderDetailsInfo1">
                          Product Name : {this.state.details.product?.name}{" "}
                        </p>
                        <p className="orderDetailsInfo1">
                          Market Price :{" "}
                          <MarketPrice row={this.state.details} />
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="orderDetailsInfo1">
                          Product Code :{" "}
                          {this.state.details.offers?.is_offer
                            ? this.state.details.offers.offer_data.code
                            : this.state.details.offers?.product?.code}{" "}
                        </p>
                        <p className="orderDetailsInfo1">
                          Product Name :{" "}
                          {this.state.details.offers?.is_offer
                            ? this.state.details.offers.offer_data.name
                            : this.state.details.offers?.product?.name}{" "}
                        </p>
                        {this.state.details.offers?.is_offer ? (
                          <>
                            <p className="orderDetailsInfo1">
                              Market Price :{" "}
                              <MarketPrice row={this.state.details} />
                            </p>
                            <p className="orderDetailsInfo1">
                              Offered Quantity :{" "}
                              {this.state.details.offers.offer_data.quantity}{" "}
                            </p>
                            <p className="orderDetailsInfo1">
                              Offered Price :{" "}
                              {this.state.details.offers.offer_data.price}{" "}
                            </p>
                          </>
                        ) : (
                          <p className="orderDetailsInfo1">
                            Market Price :{" "}
                            <MarketPrice row={this.state.details} />
                          </p>
                        )}
                      </>
                    )}

                    <p className="orderDetailsInfo1">
                      Admin's Notes :{" "}
                      <span className="orderDetailsInfo2">
                        {this.state.details.pre_request?.notes}
                      </span>{" "}
                    </p>
                    <p className="orderDetailsInfo1">
                      Supplier's Notes :{" "}
                      <span className="orderDetailsInfo2">
                        {this.state.details.note}
                      </span>{" "}
                    </p>

                    <p className="orderDetailsInfo1">
                      Product Quantity :{" "}
                      <span className="orderDetailsInfo2">
                        {this.state.details.pre_request.product_quantity}
                      </span>{" "}
                    </p>

                    <p className="orderDetailsInfo1">
                      Expected Delivery Date:{" "}
                      <span className="orderDetailsInfo2">
                        {this.state.details?.orders?.scheduled_date ??
                          this.state.details.pre_request.expected_delivery_date}
                      </span>{" "}
                    </p>
                    <p className="orderDetailsInfo1">
                      Client Address :{" "}
                      <span className="orderDetailsInfo3">
                        {this.state.details.pre_request.client_address}
                      </span>{" "}
                    </p>
                    <p className="orderDetailsInfo1">
                      Order Delivery Status :{" "}
                      {this.state.details.orders.order_status === "2" ? (
                        <span className="orderDetailsInfo2">
                          Ready for delivery
                        </span>
                      ) : this.state.details.orders.order_status === "3" ? (
                        <span className="orderDetailsInfo2">
                          Out for delivery
                        </span>
                      ) : this.state.details.orders.order_status === "4" ? (
                        <span className="orderDetailsInfo2">Delivered </span>
                      ) : null}{" "}
                    </p>
                    <p className="orderDetailsInfo1">
                      {/* Is Deliver? */}
                      Delivery available
                      {this.state.details.is_deliver === false ||
                      this.state.details.is_deliver === null ? (
                        <span className="orderDetailsInfo1 pl-2"> : No </span>
                      ) : (
                        <span className="orderDetailsInfo1"> : Yes </span>
                      )}
                    </p>
                    <p className="orderDetailsInfo1">
                      {/* Is Deliver? */}
                      Delivery Time:
                      {" " +
                        (this.state.details.deliver_time?.split("T")[0] ?? "")}
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col sm={12} md={4}>
                {this.state.details.pre_request.image === "" ? (
                  <img className="img-fluid" src={img} alt="product" />
                ) : (
                  <img
                    className="img-fluid"
                    src={APILINK + `${this.state.details.pre_request.image}`}
                    alt="product"
                  />
                )}
              </Col>
            </Row>
          ) : null}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuth,
  user: state.auth.user,
});

export default connect(
  mapStateToProps,
  null
)(ErrorHandler(ViewRequestDetails, axiosApiInstance));
