import { Route } from "react-router-dom";
import Add_KeyWord from "../components/edit_view sections/Add_KeyWord";
import Keywords from "../components/supplier-pages/Keywords";

export default function KeywordsRoutes() {
  return (
    <>
      <Route exact path="/key" component={Keywords} />
      <Route exact path="/add_key" component={Add_KeyWord} />
    </>
  );
}
