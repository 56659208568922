import React from "react";

const MarketPrice = ({row}) => {
  const isEmpty = (obj) => !(obj && Object.keys(obj).length > 0);

  console.log({ row });
  // if (!isEmpty(row.offers)) {
  //     return <span>{row.offers.is_offer
  //         ? row.offers.offer_data?.price
  //         : row.offers.product?.price
  //     }</span>
  // } else
  if (!isEmpty(row.product) && row.product.price)
    return <span>{row.product.price}</span>;
  else if (!isEmpty(row.orders) && row.orders.price)
    return <span>{row.orders.price}</span>;
  else return <span>-</span>;
}
export default MarketPrice;