import { Route } from "react-router-dom";
import AddDelieveryServices from "../components/agent-view/courier/delivery_services/AddDelieveryServices";
import EditDeliveryServices from "../components/agent-view/courier/delivery_services/EditDeliveryServices";
import ViewDeliveryServices from "../components/agent-view/courier/delivery_services/ViewDeliveryServices";

export default function DeliveryServiceRoutes() {
  return (
    <>
      <Route
        exact
        path="/view_delivery_service"
        component={ViewDeliveryServices}
      />
      <Route exact path="/add_service" component={AddDelieveryServices} />
      <Route
        exact
        path="/edit_delivery_service/:id"
        component={EditDeliveryServices}
      />
    </>
  );
}
