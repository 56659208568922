import { Route } from "react-router-dom";
import AddVehicle from "../components/agent-view/courier/vehicles/AddVehicle";
import EditVehicle from "../components/agent-view/courier/vehicles/EditVehicle";
import ViewVehicles from "../components/agent-view/courier/vehicles/ViewVehicles";

export default function VehicleRoutes() {
  return (
    <>
      <Route exact path="/add_Vehicle" component={AddVehicle} />
      <Route exact path="/view_Vehicle" component={ViewVehicles} />
      <Route exact path="/edit_vehicle/:id" component={EditVehicle} />
    </>
  );
}
