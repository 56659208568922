import "../../style-sheets/messages.css";
import "../../style-sheets/voicePlayer.css";
import { useState } from "react";
import { useEffect } from "react";
import ImageViewerDialog from "./ImageViewerDialog";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import ReactLinkify from "react-linkify";
import { GeoFill as MapIcon } from "react-bootstrap-icons";

function VoiceMessage({ voiceRef, style, voicePosition }) {
  const styleClass = `message voiceMessage ${style}`;

  return (
    <div style={{}}>
      {voiceRef ? (
        <div
          style={{
            display: "flex",
            justifyContent: voicePosition,
          }}
        >
          <AudioPlayer
            className={styleClass}
            src={voiceRef}
            type="video/webm"
            layout="horizontal-reverse"
            showJumpControls={false}
            customVolumeControls={[]}
            customAdditionalControls={[]}
            customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
          />
        </div>
      ) : null}
    </div>
  );
}

function ImagesMessage({ processedImages }) {
  const [isViewingImage, setIsViewing] = useState(false);
  const [imageIndex, setIndex] = useState(0);
  console.log({ processedImages });
  return (
    <div style={{ margin: "3px -5px" }}>
      {processedImages?.length > 0
        ? processedImages.map((element, i) => {
            return (
              <>
                <img
                  key={element}
                  className="imageContainers"
                  src={element.thumb ?? element.full}
                  onClick={() => {
                    setIndex(i);
                    setIsViewing(true);
                  }}
                  href={element}
                />
              </>
            );
          })
        : null}

      <ImageViewerDialog
        isViewingImage={isViewingImage}
        setIsViewing={setIsViewing}
        images={processedImages ?? []}
        imageIndex={imageIndex}
      />
    </div>
  );
}

function LocationMessage({ location, styleClass }) {
  return location.lat ? (
    <div className={styleClass}>
      <a
        href={`https://www.google.com/maps/?q=${location.lat},${location.lng}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <MapIcon
          style={{
            position: "relative",
            bottom: "2px",
          }}
        />{" "}
        Click to open maps
      </a>
    </div>
  ) : null;
}

function MessageItem({ value, style, voicePosition }) {
  const styleClass = `message ${style}`;

  const [processedImages, setImages] = useState([]);
  const messageDate = value?.sentAt?.toDate() ?? new Date();
  const hours = ((messageDate?.getHours() + 11) % 12) + 1;
  const formattedTime = `${hours}:${messageDate
    ?.getMinutes()
    .toString()
    .padStart(2, "0")}`;
  const suffix = messageDate?.getHours() >= 12 ? "PM" : "AM";
  const [isDateVisible, setDateVisibility] = useState(false);

  useEffect(() => {
    console.log({ images: value.imagesRef });
    if (value.imagesRef) setImages(value.imagesRef);
  }, [value]);

  return (
    <div
      title={value?.sentAt?.toDate().toString().split("GMT")[0]}
      style={{
        margin: "10px 0px",
      }}
      onClick={() => setDateVisibility(!isDateVisible)}
      onMouseLeave={() => setDateVisibility(false)}
    >
      <div className="secondaryText" style={{ fontWeight: "600" }}>
        {value.sender.name ?? " "}
      </div>

      <ImagesMessage processedImages={processedImages} />

      {
        // this.props.text.split(re)
        value.text ? (
          <div className={styleClass} style={{}}>
            <ReactLinkify>{value.text}</ReactLinkify>
          </div>
        ) : null
      }

      <VoiceMessage
        voiceRef={value?.voiceRef}
        style={style}
        voicePosition={voicePosition}
      />
      <LocationMessage
        location={{ lat: value?.lat, lng: value?.lng }}
        styleClass={styleClass}
      />

      {messageDate && isDateVisible ? (
        <div className="secondaryText">
          {formattedTime}
          <span style={{ fontSize: ".7em" }}>{suffix}</span>
        </div>
      ) : null}
    </div>
  );
}

export function ReceivedMessage({ message }) {
  return (
    <MessageItem
      value={message}
      style={"recievedMessage"}
      voicePosition={"flex-start"}
    />
  );
}

export function SentMessage({ message }) {
  return (
    <div style={{ textAlign: "right" }}>
      <MessageItem
        value={message}
        style={"sentMessage"}
        voicePosition={"flex-end"}
      />
    </div>
  );
}

// export function TypingIndecator({ from }) {
//     return (
//         <div>
//             <MessageItem value={{ from, text: `Is Typing...` }} style={"typingIndecator"} />
//         </div>
//     )
// }
