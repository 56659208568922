import React, { useState, useEffect } from "react";

import {
  Container,
  Row,
  Col,
  Tooltip,
  OverlayTrigger,
  Button
} from "react-bootstrap";
import "../../../../../style-sheets/products.css";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Pagination, Spin } from "antd";
import ErrorHandler from "../../../../../ErrorHandler/ErrorHandler";
import { APILINK } from "../../../../../Endpoint";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";

const axiosApiInstance = axios.create();

function AllAreas(props) {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [current, setCurrent] = useState(1);
  const [loading, setLoading] = useState(false);
  const params = useParams();

  let columns = [
    {
      name: "Area Name",
      selector: "name",
      sortable: true,
      right: true
    },

    {
      name: "Actions",
      button: true,
      width: "190px",
      cell: (row) => (
        <div className="courierTable">
          <OverlayTrigger
            key={"top"}
            placement={"top"}
            overlay={<Tooltip id={`tooltip-${"top"}`}>Edit Area</Tooltip>}
          >
            <Link to={`/edit_area/${params.id}/${row.id}`}>
              {" "}
              <i class="fas fa-edit"></i>
            </Link>
          </OverlayTrigger>
          <OverlayTrigger
            key={"top"}
            placement={"top"}
            overlay={<Tooltip id={`tooltip-${"top"}`}> Delete Area</Tooltip>}
          >
            <>
              <i
                id={row.id}
                onClick={() => deleteEmirate(row.id)}
                class="fas fa-trash"
              ></i>{" "}
            </>
          </OverlayTrigger>
        </div>
      )
    }
  ];

  const getAreas = async () => {
    // console.log({sews:props.params.match.id})
    // console.log("sews")
    // console.log("sews ss")
    setLoading(true);

    const result = await axiosApiInstance
      .get(APILINK + `/areas/${params.id}?page=${current}`)
      .catch((err) => {
        console.log(err.response);
      });
    if (result) {
      console.log({ result });
      setData(result.data);
      setCount(result.data.count);
      setLoading(false);
    }
  };

  const deleteEmirate = async (id) => {
    const result = await axiosApiInstance
      .delete(APILINK + `/area/${id}`)
      .catch((err) => {
        console.log(err.response);
      });
    getAreas();
  };
  useEffect(() => {
    getAreas();
  }, []);
  useEffect(() => {
    if (data.length > 0) {
      getAreas();
    }
  }, [current]);
  const handleChange = async (page) => {
    setCurrent(page);
  };
  return (
    <Container>
      <Row>
        <Col className="p-4 mb-4" style={{ textAlign: "initial" }} sm={12}>
          <h2>
            {" "}
            <i className="fas fa-truck pr-2"></i> All Areas
          </h2>
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: "end" }} sm={12}>
          <Link to={`/create_area/${params.id}`}>
            {" "}
            <Button className="headBtn"> Add Area</Button>
          </Link>
        </Col>
      </Row>
      {loading ? (
        <Spin tip="loading..." />
      ) : (
        <>
          {" "}
          <Row>
            <Col sm="12">
              <DataTable columns={columns} data={data} />
            </Col>
          </Row>
          <Row>
            <Col className="text-center mt-3" sm="12">
              {data?.length > 0 ? (
                <Pagination
                  onChange={handleChange}
                  total={count}
                  defaultCurrent={current}
                  current={current}
                />
              ) : null}
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

export default ErrorHandler(AllAreas, axiosApiInstance);
