import { Route } from "react-router-dom";
import CourierOrderDetails from "../components/agent-view/courier/CourierOrderDetails";
import CourierOrders from "../components/agent-view/courier/CourierOrders";

export default function CourierOrdersRoutes() {
  return (
    <>
      <Route exact path="/courier_orders" component={CourierOrders} />
      <Route
        exact
        path="/courier_order_details/:id"
        component={CourierOrderDetails}
      />
    </>
  );
}
