import React, {Component} from "react";
import axios from "axios";
import {Container, Row, Col, Button, Modal, Form} from "react-bootstrap";
// import { Link } from "react-router-dom";
import "../../style-sheets/keys.css";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import isEqual from "lodash/isEqual";
import {APILINK} from "../../Endpoint";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import {Spin} from "antd";

const axiosApiInstance = axios.create();

class Keywords extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            keywords: [],
            show: true,
            showEdit: false,
            key: "",
            disable: false,
            keyId: ""
        };
    }

    getKeys = async () => {
        this.setState({loading: true});
        // Request interceptor for API calls

        if (
            this.props.user.user_type === "2" ||
            this.props.user.user_type === "3"
        ) {
            const result = await axiosApiInstance.get(APILINK + `/supplier_keywords`);
            if (result) {
                this.setState({keywords: result.data.data.keyword, loading: false});
                // console.log("keys", result.data.data);
            }
        } else {
            const result = await axiosApiInstance.get(APILINK + `/keywords/`);
            if (result) {
                this.setState({keywords: result.data, loading: false});
                // console.log("keys", result);
            }
        }
    };

    componentDidMount() {
        this.getKeys();
    }

    toggleShow = (e) => {
        this.setState({show: !this.state.show});
    };

    hideme = (e) => {
        console.log(e.target.id);
    };
    deleteKeyword = async (e) => {
        this.setState({keyId: e.target.id}, async () => {
            const res = await axiosApiInstance
                .delete(APILINK + `/keyword/${this.state.keyId}`)
                .catch((err) => console.log(err));
            console.log(res);
            if (res) {
                if (res.data == "") {
                    this.getKeys();
                }
            }
        });
    };

    componentDidUpdate(prevProps, prevState) {
        if (!isEqual(prevState.keywords, this.state.keywords)) {
            this.getKeys();
        }
    }

    updateKey = async () => {
        const res = await axiosApiInstance
            .put(APILINK + `/keyword/${this.state.keyId}`, {name: this.state.key})
            .catch((err) => console.log(err));
        console.log(res);
        if (res) {
            this.setState({showEdit: false});
            this.getKeys();
        }
    };
    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value});

        if (e.target.value.match(/^[a-zA-Z][a-zA-Z0-9]*$/)) {
            this.setState({name: e.target.value});

            this.setState({errName: "", disable: false});
        }
        // this.setState({ name: e.target.value });
        else {
            this.setState({
                errName: "no special characters or space allowed",
                disable: true
            });
        }
    };

    render() {
        return (
            <div>
                <Container
                    style={{
                        marginTop: "120px"
                        // marginLeft: "350px"
                    }}
                    className="addP px-4"
                >
                    <Row>
                        <Col className="p-2 mb-4" style={{textAlign: "initial"}} sm={12}>
                            <h2>
                                {" "}
                                <i
                                    style={{cursor: "pointer"}}
                                    class="fas fa-chevron-left px-1"
                                    onClick={this.props.history.goBack}
                                ></i>{" "}
                                <i class="fas fa-search-plus pr-3"></i>Keywords
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{textAlign: "initial"}} sm={8}></Col>
                        <Col style={{textAlign: "end"}} sm={4}>
                            {this.props.user.user_type === "2" || this.props.user.user_type === "3" ? null :
                                <Link to={"/add_key"}>
                                    {" "}
                                    <Button className="headBtn"> Add Keyword</Button>
                                </Link>}
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col sm={12}>
                            <div className="keysHolder py-2">
                                {(this.state.keywords?.length !== 0) &
                                (this.state.loading === false) ? (
                                    this.state.keywords?.map((key) => {
                                        return (
                                            <Button id={key.id} className="keysBtn ml-1 my-2">
                                                {key.name}
                                                {this.props.user.user_type === "2" || this.props.user.user_type === "3" ? null :
                                                    <>
                                                        <i
                                                            onClick={() =>
                                                                this.setState({
                                                                    showEdit: true,
                                                                    key: key.name,
                                                                    keyId: key.id
                                                                })
                                                            }
                                                            className="fas fa-edit px-2"
                                                        />
                                                        <i
                                                            id={key.id}
                                                            onClick={this.deleteKeyword}
                                                            className="fas fa-times"
                                                        ></i>
                                                    </>
                                                }
                                            </Button>
                                        );
                                    })
                                ) : (
                                    <Col className="text-center" sm={12}>
                                        {this.state.loading ? (
                                            <Spin tip="Loading..."></Spin>
                                        ) : (
                                            "No data to be displayed"
                                        )}
                                    </Col>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Modal
                    show={this.state.showEdit}
                    onHide={() => this.setState({showEdit: false})}
                >
                    <Container className="p-4 modal2">
                        <Row>
                            <Col sm={12}>
                                <p style={{color: "#7c8798", fontSize: "20px"}}>
                                    Update keyword
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Form>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Keyword</Form.Label>
                                        <Form.Control
                                            value={this.state.key}
                                            name="key"
                                            onChange={this.handleInput}
                                            type="text"
                                            placeholder="update key"
                                        />
                                    </Form.Group>
                                    <p style={{color: "red", fontWeight: "bold"}}>
                                        {this.state.errName}
                                    </p>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textAlign: "right"}} sm={12}>
                                {" "}
                                <Button
                                    className="btn5"
                                    variant="secondary"
                                    onClick={() => this.setState({showEdit: false})}
                                >
                                    Close
                                </Button>
                                <Button
                                    className="btn3"
                                    variant="primary"
                                    onClick={this.updateKey}
                                    disabled={this.state.disable}
                                >
                                    Save changes
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuth: state.auth.isAuth,
    user: state.auth.user
});

export default connect(
    mapStateToProps,
    null
)(ErrorHandler(Keywords, axiosApiInstance));
