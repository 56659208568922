import React, { Component } from "react";
// routes handle
import { BrowserRouter as Router, Route } from "react-router-dom";
// styles
import "./App.css";
// state management
import { connect } from "react-redux";
import Header from "./components/layouts/Header";
import SideMenu from "./components/layouts/SideMenu";
import Footer from "./components/layouts/Footer";
import Login from "./components/Login";
import ResetPassword from "./components/ResetPassword";
import Credentials from "./components/supplier-pages/Credentials";
// All Pages Routes
import AgentsRoutes from "./pagesRoutes/AgentsRoutes";
import SupplierRoutes from "./pagesRoutes/SupplierRoutes";
import CourierRoutes from "./pagesRoutes/CourierRoutes";
import CourierServiceRoutes from "./pagesRoutes/CourierServiceRoutes";
import CourierOrdersRoutes from "./pagesRoutes/CourierOrdersRoutes";
import VehicleRoutes from "./pagesRoutes/VehicleRoutes";
import CategoryRoutes from "./pagesRoutes/CategoryRoutes";
import EmployeeRoutes from "./pagesRoutes/EmployeeRoutes";
import RequestsRoutes from "./pagesRoutes/RequestsRoutes";
import KeywordsRoutes from "./pagesRoutes/KeywordsRoutes";
import DeliveryServiceRoutes from "./pagesRoutes/DeliveryServiceRoutes";
import ProductsRoutes from "./pagesRoutes/ProductsRoutes";
import BranchesRoutes from "./pagesRoutes/BranchesRoutes";
import OrdersRoutes from "./pagesRoutes/OrdersRoutes";
import { WEBSOCKETS_LINK } from "./Endpoint";
import { toast, ToastContainer } from "react-toastify";
import { ReloadContext } from "./hooks/ReloadContext";
import notificationSound from "./sounds/notification.wav";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newNotification: undefined,
      reload: undefined,
      launchSound: true,
    };
  }

  connectToWebsocket = () => {
    console.log("Connecting To WebSocket");
    console.log(this.props);
    // if (this.props.user.id == prevProps.user.id) return;
    console.log({ id: this.props.user.id });
    const websocket = new WebSocket(
      `${WEBSOCKETS_LINK}/ws/notification/${this.props.user.id}/`
    );
    websocket.onmessage = async (e) => {
      console.log("message", e);
      // Access the notification data
      const notification = JSON.parse(e.data);
      console.log({ notification });
      const message = notification.message;
      console.log(notification);

      if (message.title && message.body) {
        this.setState({
          newNotification: {
            ...notification.message,
            id: notification.message.body,
          },
        });

        if (!notification.msg_id) this.setState({ reload: notification });

        if (this.state.launchSound) {
          console.log("here");
          this.setState({ launchSound: false });
          const audio = new Audio(notificationSound);
          audio.play().catch((e) => {
            window.addEventListener(
              "click",
              () => {
                audio.play();
              },
              { once: true }
            );
          });
          setTimeout(() => this.setState({ launchSound: true }), 600);
        }
        toast.info(
          <>
            <div>{message.title}</div>
            <div>{message.body}</div>
            {notification.ticket_code && (
              <div>Ticket: #{notification.ticket_code}</div>
            )}
          </>,
          {
            autoClose:
              notification.type === "notification_message" ? 3000 : false,
            onClick: () => window.location.reload(),
            toastId: notification.ticket_code ?? Math.random(),
          }
        );
        // new Notification(message.title, {
        //     body: message.body + " from websocket",
        // });
      }
      //$("body").append("<h3>"+e.data+"</h3>")
      // Can write any functionality based on your requirement
    };
    // Socket Connet Functionality
    websocket.onopen = function (e) {
      console.log("here");

      console.log("open", e);
    };

    // websocket Error Functionality
    websocket.onerror = function (e) {
      console.log("error", e);
    };

    // websocket close Functionality
    websocket.onclose = function (e) {
      console.log("closed", e);
    };
  };

  componentDidMount = () => {
    this.connectToWebsocket();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.user.id != prevProps.user.id) this.connectToWebsocket();
  };

  render() {
    return (
      <>
        <ReloadContext.Provider value={this.state.reload}>
          <ToastContainer position={"bottom-right"} />
          <Router>
            <div className="App">
              {this.props.isAuth === true ? (
                <>
                  <Header newNotification={this.state.newNotification} />
                  <SideMenu />
                  <div style={{ marginTop: "180px" }}>
                    <Route
                      exact
                      path="/update_credentials"
                      component={Credentials}
                    />
                    <AgentsRoutes />
                    <BranchesRoutes />
                    <CategoryRoutes />
                    <CourierOrdersRoutes />
                    <CourierRoutes />
                    <CourierServiceRoutes />
                    <DeliveryServiceRoutes />
                    <EmployeeRoutes />
                    <KeywordsRoutes />
                    <OrdersRoutes />
                    <ProductsRoutes />
                    <RequestsRoutes />
                    <SupplierRoutes />
                    <VehicleRoutes />
                  </div>
                  <Footer />
                </>
              ) : (
                <Route
                  exact
                  path="/password-reset/:token/:id"
                  component={ResetPassword}
                />
              )}
              <Route exact path="/" component={Login} />
            </div>
          </Router>
        </ReloadContext.Provider>
      </>
    );
  }
}

// redux state
const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuth,
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(App);
