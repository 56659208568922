import React, {useState, useEffect} from "react";
import {Container, Row, Col} from "react-bootstrap";
import "../../style-sheets/products.css";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {Button, Form, Input, Select, Upload} from "antd";
import {useHistory} from "react-router-dom";
import {APILINK} from "../../Endpoint";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

const axiosApiInstance = axios.create();

function Add_product(props) {
    const history = useHistory();
    const [keys, setKeys] = useState([]);
    const [categories, setCategories] = useState([]);
    const [image, setImage] = useState(undefined);

    const onFinish = async (values) => {
        console.log(values);

        const formData = new FormData();
        for (const key in values) {
            formData.append(key, values[key]);
        }

        // if (values.keyword) formData.set("keyword", `[${values.keyword}]`);
        if (image) formData.set("image", image);
        else formData.delete("image")

        const result = await axiosApiInstance
            .post(APILINK + `/product`, formData)
            .catch((err) => {
                console.log(err.response);
            });
        if (result) {
            console.log(result);
            setTimeout(() => {
                toast.info(`Product was added successfully`);
            }, 500);
            setTimeout(() => {
                props.history.push("/products");
            }, 3500);
        }
    };
    const getKeys = async () => {
        const result = await axiosApiInstance.get(APILINK + `/supplier_keywords`);
        setKeys(result.data.data.keyword);
    };

    useEffect(() => {
        getKeys();
    }, []);
    return (
        <div>
            <Container>
                <Row>
                    <Col className="p-4 mb-4" style={{textAlign: "initial"}} sm={12}>
                        <h2>
                            {" "}
                            <i
                                style={{cursor: "pointer"}}
                                class="fas fa-chevron-left px-1"
                                onClick={history.goBack}
                            ></i>
                            <i class="fas fa-shopping-basket pr-2"></i>Add Product{" "}
                        </h2>
                    </Col>
                </Row>
                <Form onFinish={onFinish} layout="vertical">
                    <Row>
                        <Col sm="12" md="6">
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required",
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col sm="12" md="6">
                            <Form.Item
                                label="Code"
                                name="code"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required",
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" md="6">
                            <Form.Item
                                label="Market Price"
                                name="price"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required",
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col sm="12" md="6">
                            <Form.Item
                                label="Quantity"
                                name="quantity"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required",
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" md="6">
                            <Form.Item
                                label="Keywords"
                                name="keyword"
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: "This field is required"
                                //   }
                                // ]}
                            >
                                <Select
                                    placeholder="Select keywords"
                                    mode="multiple"
                                    style={{width: "100%"}}
                                    showSearch
                                >
                                    {keys?.map((item) => (
                                        <Select.Option value={item.id}>{item.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col sm="12" md="6">
                            <Form.Item
                                label="Yanzo Price"
                                name="yanzo_price"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required",
                                    },
                                ]}
                            >
                                <Input type="number"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" md="6">
                            <Form.Item label="Image" name="image">
                                <Input
                                    type="file"
                                    value={image}
                                    onChange={(e) => setImage(e.target.files[0])}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="" sm="12">
                            <Button color="primary" htmlType="submit" className="addBtn2">
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </div>
    );
}

export default ErrorHandler(Add_product, axiosApiInstance);
