import {Route} from "react-router-dom";
import AllCouriers from "../components/agent-view/courier/AllCouriers";
import AddCourier from "../components/agent-view/courier/AddCourier";
import EditCourier from "../components/agent-view/courier/EditCourier";
import ViewCouriers from "../components/agent-view/courier/ViewCouriers";
import CreateCourier from "../components/agent-view/courier/CreateCourier";
import CreateEmirate from "../components/agent-view/courier/Emirates/CreateEmirate";
import AllEmirates from "../components/agent-view/courier/Emirates/AllEmirates";
import CreateArea from "../components/agent-view/courier/Emirates/CreateArea";
import EditEmirate from "../components/agent-view/courier/Emirates/EditEmirate";
import AllAreas from "../components/agent-view/courier/Emirates/Areas/AllAreas";
import EditArea from "../components/agent-view/courier/Emirates/Areas/EditArea";

export default function CourierRoutes() {
    return (
        <>
            <Route exact path="/view_couriers/:id" component={ViewCouriers}/>
            <Route exact path="/view_couriers" component={ViewCouriers}/>
            <Route exact path="/add_courier" component={AddCourier}/>
            <Route exact path="/view_all_courier" component={AllCouriers}/>
            <Route exact path="/edit_courier/:id" component={EditCourier}/>
            <Route exact path="/create_courier" component={CreateCourier}/>
            <Route exact path="/create_emirate" component={CreateEmirate}/>
            <Route exact path="/all_emirates" component={AllEmirates}/>
            <Route exact path="/all_areas/:id" component={AllAreas}/>
            <Route exact path="/create_area/:id" component={CreateArea}/>
            <Route exact path="/edit_area/:emirateId/:id" component={EditArea}/>
            <Route exact path="/edit_emirate/:id" component={EditEmirate}/>
        </>
    );
}
