import React, {Component, useEffect, useState} from "react";
import axios from "axios";
import {Row, Col} from "react-bootstrap";
import {TimePicker, Checkbox, Divider, Space, Input, Button} from "antd";
import "antd/dist/antd.css";
import ErrorHandler from "../../../../ErrorHandler/ErrorHandler";
import {APILINK} from "../../../../Endpoint";
import {useHistory, useParams} from "react-router-dom";
import {Form, Select} from "antd";
import {toast, ToastContainer} from "react-toastify";

const axiosApiInstance = axios.create();

function CommonCourierServiceAction({mode} = {mode: "create"}) {
    const history = useHistory();
    const [emirates, setEmirates] = useState([]);
    const [vehicles, setAllVehicles] = useState([]);
    const [delivery, setAllServices] = useState([]);
    const [pickupAreas, setPickupAreas] = useState([]);
    const [dropOffAreas, setDropOffAreas] = useState([]);
    const params = useParams();
    const [form] = Form.useForm();

    const usedMethod = {
        create: axiosApiInstance.post,
        update: axiosApiInstance.put,
    }[mode];

    const getVehicles = async () => {
        const result = await axiosApiInstance.get(
            APILINK + `/vehicle_types/?limit=10000`
        );

        if (result) {
            setAllVehicles(result.data.results);
        }
    };
    const getService = async () => {
        const result = await axiosApiInstance.get(
            APILINK + `/delivery_service?limit=10000`
        );
        if (result) {
            setAllServices(result.data.data);
        }
    };

    const getEmirates = async () => {
        const result = await axiosApiInstance.get(APILINK + `/emirate?limit=10000`);
        if (result) {
            setEmirates(result.data.results);
        }
    };

    async function getAreas(v, setAreas) {
        if (!v) {
            setAreas([]);
        } else {
            const res = await axiosApiInstance.get(APILINK + "/areas/" + v);
            form.setFieldsValue({area: ""});
            setAreas(res.data);
        }
    }

    async function fetchInitial() {
        if (mode !== "update") return;

        const res = await axiosApiInstance.get(
            APILINK + "/courier_service/" + params.id
        );

        await getAreas(res.data.pick_up_emirate?.id, setPickupAreas);
        await getAreas(res.data.drop_off_emirate?.id, setDropOffAreas);
        console.log({vehicles});
        const values = {
            pick_up_area: res.data.pick_up_area?.id,
            drop_off_area: res.data.drop_off_area?.id,
            pick_up_emirate: res.data.pick_up_emirate?.id,
            drop_off_emirate: res.data.drop_off_emirate?.id,
            delivery: res.data.delivery.id,
            vehicle: res.data.vehicle.id?.replace(/-/g, ""),
            price: +res.data.price,
        };
        form.setFieldsValue(values);
        console.log({res});
    }

    useEffect(() => {
        (async function () {
            await getVehicles();
            await getService();
            await getEmirates();
            await fetchInitial();
        })();
    }, []);

    async function onSubmit(values) {
        const data = mode === "create" ? {...values, courier: params.id} : values;

        try {
            const res = await usedMethod(
                APILINK + "/courier_service/" + params.id,
                data
            );

            if (res.data) {
                toast.success(
                    `Service ${mode === "create" ? "Created" : "Updated"} Successfully`
                );

                setTimeout(() => history.goBack(), 1300);
            }
        } catch (error) {
            toast.error("Pleas fill all the data.");
        }
    }

    return (
        <div style={{marginInline: "50px"}}>
            <Col className="pb-3" sm={6}>
                <h2>
                    {" "}
                    <i
                        style={{cursor: "pointer"}}
                        class="fas fa-chevron-left px-1"
                        onClick={history.goBack}
                    ></i>{" "}
                    <i className="fas fa-truck"></i>services
                </h2>
            </Col>
            <Form
                className={"formGrid"}
                style={{marginInlineStart: "20px"}}
                layout="vertical"
                form={form}
                onFinish={onSubmit}
            >
                <Form.Item
                    label="Select Pickup Emirate"
                    rules={[
                        {
                            required: true,
                            message: "This field is required",
                        },
                    ]}
                    name="pick_up_emirate"
                >
                    <Select
                        placeholder="Emirate"
                        onChange={(v) => getAreas(v, setPickupAreas)}
                    >
                        {emirates.map((it) => (
                            <option key={it.id} value={it.id}>
                                {it.name}
                            </option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="Pick up Area" name="pick_up_area">
                    <Select>
                        {pickupAreas.map((it) => (
                            <option key={it.id} value={it.id}>
                                {it.name}{" "}
                            </option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Select Drop Off Emirate"
                    rules={[
                        {
                            required: true,
                            message: "This field is required",
                        },
                    ]}
                    name="drop_off_emirate"
                >
                    <Select
                        placeholder="Emirate"
                        onChange={(v) => getAreas(v, setDropOffAreas)}
                    >
                        {emirates.map((it) => (
                            <option key={it.id} value={it.id}>
                                {it.name}
                            </option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="Drop Off Area" name="drop_off_area">
                    <Select>
                        {dropOffAreas.map((it) => (
                            <option key={it.id} value={it.id}>
                                {it.name}{" "}
                            </option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Select Vehicles"
                    name="vehicle"
                    rules={[
                        {
                            required: true,
                            message: "This field is required",
                        },
                    ]}
                >
                    <Select
                        placeholder="Select Vehicles"
                        style={{width: "100%"}}
                        showSearch
                    >
                        {vehicles.map((item) => (
                            <Select.Option key={item.id} id={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Delivery Service"
                    name="delivery"
                    rules={[
                        {
                            required: true,
                            message: "This field is required",
                        },
                    ]}
                >
                    <Select
                        placeholder="Select Services"
                        style={{width: "100%"}}
                        showSearch
                    >
                        {delivery.map((item) => (
                            <Select.Option key={item.id} id={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Price"
                    name="price"
                    rules={[
                        {
                            required: true,
                            message: "This field is required",
                        },
                    ]}
                >
                    <Input type={"number"} min={1}/>
                </Form.Item>
                <Row>
                    <Col sm="12" style={{textAlign: "end"}}>
                        <Button className="addBtn2" htmlType="submit">
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default ErrorHandler(CommonCourierServiceAction, axiosApiInstance);
