import { Route } from "react-router-dom";
import EditCategory from "../components/edit_view sections/EditCategory";
import Add_category from "../components/supplier-pages/Add_category";
import Categories from "../components/supplier-pages/Categories";

export default function CategoryRoutes() {
  return (
    <>
      <Route exact path="/edit_category/:id" component={EditCategory} />
      <Route exact path="/add_category" component={Add_category} />
      <Route exact path="/categories" component={Categories} />
    </>
  );
}
