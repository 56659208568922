import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/messaging";

firebase.initializeApp({
  apiKey: "AIzaSyB_71RUZ4j0fgySWecK3LjbOOY_33aoVN0",
  authDomain: "yanzo-39224.firebaseapp.com",
  projectId: "yanzo-39224",
  storageBucket: "yanzo-39224.appspot.com",
  messagingSenderId: "1048567178417",
  appId: "1:1048567178417:web:71ab612df5c830efbc6e90",
  measurementId: "G-CQ725TWZF8",
});

export default firebase;
