import React, { Component, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import "../../../style-sheets/products.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  courierType,
  quickUpTypes,
} from "../../../global-state/actions/checkCourierType";
import { APILINK } from "../../../Endpoint";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";
import { Spin, Input } from "antd";
import CourierChatComponent from "./CourierChatComponent";
import RescheduleOrder from "../../reschedule_order";

const axiosApiInstance = axios.create();

class CourierOrders extends Component {
  orderStates = {
    1: "Accepted",
    2: "Assigned to Delegate",
    3: "On Pick Up",
    4: "Picked Up",
    5: "On Delivery",
    6: "Delivered",
    7: "Canceled",
    8: "Pending",
  };

  getShowResc = () => {
    return this.state.showReschedule;
  };

  setShowRes = (val) => {
    this.setState({ showReschedule: val });
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tableData: [],
      tableData2: [],
      modalNotes: undefined,
      keyWord: "",
      nextUrl: null,
      prevUrl: null,
      dis1: true,
      dis2: false,
      columns: [
        {
          name: "Ticket Number",
          selector: "order_id",
          sortable: true,
          right: true,
        },
        {
          name: "Order Name",
          selector: "order_name",
          sortable: true,
          right: true,
        },
        {
          name: "Courier Name",
          selector: "courier.name",
          sortable: true,
          right: true,
        },
        {
          name: "Admin's Notes",
          selector: "notes_from_admin",
          sortable: true,
          right: true,
          cell: ({ notes_from_admin }) => (
            <div
              className="textOverflow"
              onClick={() => this.setState({ modalNotes: notes_from_admin })}
            >
              {notes_from_admin}
            </div>
          ),
        },
        {
          name: "Courier's Notes",
          selector: "notes_from_courier",
          cell: ({ notes_from_courier }) => (
            <div
              className="textOverflow"
              onClick={() => this.setState({ modalNotes: notes_from_courier })}
            >
              {notes_from_courier}
            </div>
          ),
          sortable: true,
          right: true,
        },
        {
          name: "Order Status",
          // selector: "order_status",
          sortable: true,
          right: true,
          cell: ({ order_status }) => (
            <div>{this.orderStates[order_status] ?? "Unspecified"}</div>
          ),
        },
        {
          name: "Created At",
          sortable: true,
          right: true,
          cell: ({ create_date }) => (
            <div>
              {create_date ? new Date(create_date).toLocaleString() : "-"}
            </div>
          ),
        },
        {
          name: "Actions",
          button: true,
          cell: (row) => {
            return (
              <div className="courierTable">
                {row.courier.name === "Quiqup" ? (
                  <Link to={`/courier_order_details/${row.courier_order_id}`}>
                    <i
                      onClick={() => {
                        this.props.courierType(true);
                        this.props.quickUpTypes(row.order_id);
                      }}
                      className="fas fa-eye"
                    ></i>{" "}
                  </Link>
                ) : (
                  <Link to={`/courier_order_details/${row.order_id}`}>
                    <i id={row.order_id} className="fas fa-eye"></i>{" "}
                  </Link>
                )}

                <button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    borderRadius: "50%",
                    marginInlineStart: "6px",
                  }}
                  onClick={() => {
                    this.setState({ selectedCourierDetails: row });
                  }}
                >
                  <i className="fas fa-comment"></i>
                </button>

                <button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    borderRadius: "50%",
                    marginInlineStart: "6px",
                  }}
                  onClick={() => {
                    this.setShowRes(row.order_id);
                  }}
                >
                  <i className="fas fa fa-retweet"></i>
                </button>
              </div>
            );
          },
        },
      ],

      columns2: [
        {
          name: "Ticket Number",
          selector: "pre_request.ticket_code",
          sortable: true,
          right: true,
        },
        {
          name: "Order Name",
          selector: "pre_request.name",
          sortable: true,
          right: true,
        },
        {
          name: "Courier Name",
          selector: "orders.courier_order.courier.name",
          sortable: true,
          right: true,
        },
        {
          name: "Order Status",
          sortable: true,
          right: true,
          cell: ({ order_status }) => (
            <div>{this.orderStates[order_status] ?? "Unspecified"}</div>
          ),
        },
        {
          name: "Created At",
          sortable: true,
          right: true,
          cell: ({ create_date }) => (
            <div>
              {new Date(this.orderStates[create_date]).toLocaleString() ?? "-"}
            </div>
          ),
        },
        {
          name: "Actions",
          button: true,
          cell: (row) => (
            <div className="courierTable">
              {row.orders.courier_order.courier.name === "Quiqup" ? (
                <Link
                  to={`/courier_order_details/${row.orders.courier_order.courier_order_id}`}
                >
                  <i
                    onClick={() => {
                      this.props.courierType(true);
                      this.props.quickUpTypes(
                        row.orders.courier_order.order_id
                      );
                    }}
                    class="fas fa-eye"
                  ></i>{" "}
                </Link>
              ) : (
                <Link
                  to={`/courier_order_details/${row.orders.courier_order.order_id}`}
                >
                  <i
                    id={row.orders.courier_order.order_id}
                    className="fas fa-eye"
                  ></i>{" "}
                </Link>
              )}
            </div>
          ),
        },
      ],

      is_stand_alone: true,
      searchTxt: "",
    };
  }

  handleFilter = (e) => {
    if (e.target.value === "2") {
      this.setState({
        is_stand_alone: true,
      });
    } else {
      this.setState({
        is_stand_alone: false,
      });
    }
  };
  getOrders = async () => {
    this.setState({ loading: true });
    if (this.state.is_stand_alone === true) {
      const result = await axiosApiInstance.get(
        APILINK + `/courier_orders/?order_type=2&limit=10`
      );
      if (result) {
        this.setState({
          tableData: result.data.results,
          loading: false,
        });
        if (result.data.next !== null) {
          this.setState({
            nextUrl: result.data.next,
            dis2: false,
          });
        }

        if (result.data.previous !== null) {
          this.setState({
            prevUrl: result.data.previous,
          });
        }
        if (result.data.next === null) {
          this.setState({ dis2: true });
        }
      }
    } else {
      const result = await axiosApiInstance.get(
        APILINK + `/requests/?courier_order__exists=true&limit=10`
      );
      if (result) {
        console.log("test2", result);
        this.setState({
          tableData2: result.data.results,
        });
        if (result.data.next !== null) {
          this.setState({
            nextUrl: result.data.next,
            dis2: false,
          });
        }

        if (result.data.previous !== null) {
          this.setState({
            prevUrl: result.data.previous,
          });
        }
        if (result.data.next === null) {
          this.setState({ dis2: true });
        }
      }
    }
  };

  componentDidMount() {
    this.getOrders();
    this.props.courierType(false);
    this.props.quickUpTypes("");
  }

  getNext = async () => {
    // let newID = this.props.user.id.replace(/-/g, "");
    // console.log(newID);
    const result = await axiosApiInstance.get(`${this.state.nextUrl}`);

    if (result) {
      console.log(result.data);
      this.setState({
        tableData: result.data.results,
        // nextUrl: result.data.next,
        // prevUrl: result.data.previous,
      });
      if (result.data.next !== null) {
        this.setState({
          nextUrl: result.data.next,
          // prevUrl: result.data.previous,
        });
      }
      if (result.data.previous !== null) {
        this.setState({
          prevUrl: result.data.previous,
          dis1: false,
        });
      }
      if (result.data.next === null) {
        this.setState({ dis2: true, dis1: false });
      }
    }
  };

  getPrev = async () => {
    // let newID = this.props.user.id.replace(/-/g, "");
    // console.log(newID);
    const result = await axiosApiInstance.get(`${this.state.prevUrl}`);
    if (result) {
      console.log("orderssss");
      console.log(result.data);
      this.setState({
        tableData: result.data.results,
      });
      if (result.data.next !== null) {
        this.setState({
          nextUrl: result.data.next,
          dis2: false,
        });
      }
      if (result.data.previous !== null) {
        this.setState({
          prevUrl: result.data.previous,
        });
      }
      if (result.data.previous === null) {
        this.setState({ dis1: true, dis2: false });
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.is_stand_alone !== this.state.is_stand_alone) {
      this.getOrders();
    }
  }

  handleSelected = (state) => {
    console.log(state.selectedRows);
    this.setState({ selectedRows: state.selectedRows });
  };

  onSearch = async () => {
    const result = await axiosApiInstance.get(
      APILINK + `/courier_orders/?search=${this.state.keyWord}`
    );
    if (result) {
      console.log(result);
      this.setState({
        tableData: result.data,
      });
    }
  };

  render() {
    return (
      <div>
        {this.state.selectedCourierDetails && (
          <CourierChatComponent details={this.state.selectedCourierDetails} />
        )}

        <Modal
          show={this.state.modalNotes}
          style={{ marginTop: "300px" }}
          onHide={() => this.setState({ modalNotes: undefined })}
        >
          <div style={{ padding: "24px" }}>
            <h4>Notes</h4>
            <hr />
            <div style={{ marginTop: "16px" }}>{this.state.modalNotes}</div>
          </div>
        </Modal>
        <Container
          style={{
            marginTop: "120px",

            //  marginLeft: "350px"
          }}
        >
          <Row>
            <Col className="mb-4" style={{ textAlign: "initial" }} sm={6}>
              <h2>
                <i
                  style={{ cursor: "pointer" }}
                  class="fas fa-chevron-left px-1"
                  onClick={this.props.history.goBack}
                ></i>{" "}
                <i className="fas fa-shopping-cart pr-2"></i>
                Courier Orders
              </h2>
            </Col>
            <Col sm={6}>
              <select
                onChange={this.handleFilter}
                style={{ marginLeft: "10px" }}
                id="cars"
              >
                {" "}
                <option value="2">Stand Alone Orders</option>
                <option value="1"> Other Orders</option>
              </select>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12}>
              <Row className="my-3">
                <Col>
                  <Input
                    placeholder="You can search by ticket number, order name, courier name and order status"
                    value={this.state.keyWord}
                    onChange={(e) => this.setState({ keyWord: e.target.value })}
                  />
                </Col>
                <Col sm="5">
                  <Button
                    disabled={this.state.keyWord == "" ? true : false}
                    onClick={this.onSearch}
                  >
                    Search
                  </Button>
                  <Button
                    onClick={() => {
                      this.getOrders();
                      this.setState({ keyWord: "" });
                    }}
                    disabled={this.state.keyWord == "" ? true : false}
                  >
                    Clear
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  {this.state.is_stand_alone === true ? (
                    this.state.tableData.length !== 0 ? (
                      <DataTable
                        columns={this.state.columns}
                        data={this.state.tableData}
                        onSelectedRowsChange={this.handleSelected}
                      />
                    ) : (
                      <Col className="text-center" sm={12}>
                        {this.state.loading ? (
                          <Spin tip="Loading..."></Spin>
                        ) : (
                          "No data to be displayed"
                        )}
                      </Col>
                    )
                  ) : this.state.tableData2.length !== 0 ? (
                    <DataTable
                      columns={this.state.columns2}
                      data={this.state.tableData2}
                      onSelectedRowsChange={this.handleSelected}
                    />
                  ) : null}
                </Col>
              </Row>
              <Row></Row>
              <Row>
                <Col sm={12}>
                  {this.state.tableData.length === 0 ? null : (
                    <div className="p-3">
                      {" "}
                      <Button
                        disabled={this.state.dis1}
                        onClick={this.getPrev}
                        className="nextBtn"
                      >
                        <i class="fas fa-angle-double-left"></i> Previous
                      </Button>
                      <Button
                        disabled={this.state.dis2}
                        onClick={this.getNext}
                        className="nextBtn"
                      >
                        Next
                        <i class="fas fa-angle-double-right pl-1"></i>
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
            <Col sm={12} md={6}></Col>
          </Row>
        </Container>
        <RescheduleOrder
          endpoint={"order-edit"}
          open={this.getShowResc()}
          onClose={() => {
            this.setShowRes(false);
          }}
          methodType="PUT"
          id={this.state.showReschedule}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuth,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ courierType, quickUpTypes }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorHandler(CourierOrders, axiosApiInstance));
