import React, { useState, useRef } from "react";

import { Container, Row, Col } from "react-bootstrap";
import "../../../../style-sheets/products.css";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Form, Input, Button } from "antd";
import ErrorHandler from "../../../../ErrorHandler/ErrorHandler";
import { APILINK } from "../../../../Endpoint";
import { ToastContainer, toast } from "react-toastify";

const axiosApiInstance = axios.create();
function CreateArea(props) {
    const onFinish = async (values) => {
        const result = await axiosApiInstance
            .post(APILINK + `/area`, {
                emirate: props.match.params.id,
                ...values,
            })
            .catch((err) => {
                console.log(err.response);
            });
        if (result) {
            console.log(result);
            setTimeout(() => {
                toast.info(`New area was added successfully`);
            }, 500);
            //   setTimeout(() => {
            //     props.history.push("/all_emirates");
            //   }, 3500);
        }
    };

    return (
        <Container>
            <Row>
                <Col
                    className="p-4 mb-4"
                    style={{ textAlign: "initial" }}
                    sm={12}
                >
                    <h2>
                        {" "}
                        <i className="fas fa-truck pr-2"></i> Create New Area
                    </h2>
                </Col>
            </Row>
            <Form onFinish={onFinish} layout="vertical">
                <Row>
                    <Col sm="12" md="6">
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col style={{ display: "flex", alignItems: "center" }}>
                        <Button className="addBtn2" htmlType="submit">
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
}
export default ErrorHandler(CreateArea, axiosApiInstance);
