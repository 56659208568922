import React, { useState, useRef, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";

import "../../../style-sheets/products.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Button } from "antd";
import {
    TimePicker,
    Checkbox,
    Select,
    Form,
    Input,
    Divider,
    Space,
} from "antd";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";
import { APILINK } from "../../../Endpoint";
import { useHistory } from "react-router-dom";
import CourierServicesForm from "./courier_services/CourierServicesForm";
import moment from "moment";

const axiosApiInstance = axios.create();

function CommonCourier({ initialValues, ...props }) {
    const history = useHistory();
    const [allVehicles, setAllVehicles] = useState([]);
    const [allServices, setAllServices] = useState([]);
    const [days, setDays] = useState([]);
    const [emirates, setEmirates] = useState([]);
    const [currentEmirate, setCurrentEmirate] = useState("");
    const [areas, setAreas] = useState([]);
    // const [emiratesPrice, setEmiratesPrice] = useState([]);
    const [emiratesError, setEmirateError] = useState({
        state: false,
        isTried: false,
    });
    const [courierServices, setCourierServices] = useState([]);

    const [form] = Form.useForm();

    // useEffect(() => {
    //     if (emiratesError.isTried) {
    //         setEmirateError({
    //             ...emiratesError,
    //             state: emiratesPrice.areas?.length === 0,
    //         });
    //     }
    // }, [emiratesPrice]);

    useEffect(() => {
        getVehicles();
        getService();
    }, []);

    const onFinish = async (values) => {
        // if (emiratesPrice.areas?.length === 0) {
        //     setEmirateError({ isTried: true, state: true });
        //     return;
        // }
        let data = {
            courier: {
                username: values.username,
                name: values.name,
                email: values.email,
                new_password1: values.new_password1,
                new_password2: values.new_password2,
                phone: values.phone,
                address: values.address,
                submit_data: values.submit_data,
                days: values.days,
                approval_need: values.approval_need ?? false,
                provide_wallet: values.provide_wallet ?? false,
                last_time_submit: values.last_time_submit?.format("HH:mm"),
                // ...emiratesPrice,
            },
            services: initialValues
                ? undefined
                : Array.from(new Set(courierServices)).map((unFiltered) => {
                      const it = { ...unFiltered };
                      delete it.key;
                      return it;
                  }),
        };
        console.log(data);
        const result = await props.request(data);
        if (result) {
            console.log(result);
            toast.success(
                `Courier ${initialValues ? "Updated" : "Created"} Successfully`
            );
            setTimeout(() => history.push("/view_all_courier"), 1200);
        }
    };
    const getVehicles = async () => {
        const result = await axiosApiInstance.get(
            APILINK + `/vehicle_types/?limit=10000`
        );
        if (result) {
            setAllVehicles(result.data.results);
        }
    };
    const getService = async () => {
        const result = await axiosApiInstance.get(
            APILINK + `/delivery_service?limit=10000`
        );
        if (result) {
            setAllServices(result.data.data);
        }
    };

    const getEmirates = async () => {
        if (emirates.length > 0) return;
        const result = await axiosApiInstance
            .get(APILINK + `/emirate?limit=10000`)
            .catch((err) => console.log(err.response));

        console.log({ result });
        if (result) setEmirates(result.data.results);
    };

    const getDays = async () => {
        const result = await axiosApiInstance
            .get(APILINK + "/working_days")
            .catch((err) => console.log(err.response));

        console.log({ result });
        setDays(result.data.results);
    };
    useEffect(() => {
        getDays();
        getEmirates();
    }, []);

    useEffect(() => {
        if (initialValues) {
            const lastTimeSubmit = moment();
            const lastTimeSubmitParts = initialValues.last_time_submit
                ? initialValues.last_time_submit.split(":")
                : undefined;
            if (lastTimeSubmitParts)
                lastTimeSubmit.set({
                    hour: +lastTimeSubmitParts[0],
                    minute: +lastTimeSubmitParts[1],
                });
            const values = {
                ...initialValues,
                days: initialValues?.days?.map((it) => it.id) ?? [],
                last_time_submit: lastTimeSubmit,
            };
            form.setFieldsValue(values);
        }
    }, [initialValues]);
    return (
        <Container>
            <Row>
                <Col
                    className="p-4 mb-4"
                    style={{ textAlign: "initial" }}
                    sm={12}
                >
                    <h2>
                        {" "}
                        <i className="fas fa-truck pr-2"></i>{" "}
                        {initialValues ? "Edit Courier" : "Create Courier"}
                    </h2>
                </Col>
            </Row>
            <Form onFinish={onFinish} form={form} layout="vertical">
                <Row>
                    <Col sm="12" md="6">
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col sm="12" md="6">
                        <Form.Item
                            label="User Name"
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md="6">
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col sm="12" md="6">
                        <Form.Item
                            label="Phone"
                            name="phone"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                {!initialValues && (
                    <Row>
                        <Col sm="12" md="6">
                            <Form.Item
                                label="Password"
                                name="new_password1"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required",
                                    },
                                ]}
                            >
                                <Input.Password className="" />
                            </Form.Item>
                        </Col>
                        <Col sm="12" md="6">
                            <Form.Item
                                label="Confirm Password"
                                name="new_password2"
                                dependencies={["new_password1"]}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Please confirm your password!",
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (
                                                !value ||
                                                getFieldValue(
                                                    "new_password1"
                                                ) === value
                                            ) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    "The two passwords that you entered do not match!"
                                                )
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password className="" />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col sm="12" md="6">
                        <Form.Item
                            label="Address"
                            name="address"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    {/*<Col sm="12" md="6">*/}
                    {/*  <Form.Item*/}
                    {/*    label="How to submit order"*/}
                    {/*    name="submit_data"*/}
                    {/*    rules={[*/}
                    {/*      {*/}
                    {/*        required: true,*/}
                    {/*        message: "This field is required",*/}
                    {/*      },*/}
                    {/*    ]}*/}
                    {/*  >*/}
                    {/*    <Select*/}
                    {/*      placeholder="How to submit order"*/}
                    {/*      style={{ width: "100%" }}*/}
                    {/*    >*/}
                    {/*      <Select.Option key={1} value={"1"}>*/}
                    {/*        API*/}
                    {/*      </Select.Option>*/}
                    {/*      <Select.Option key={2} value={"2"}>*/}
                    {/*        Dashboard*/}
                    {/*      </Select.Option>*/}
                    {/*    </Select>*/}
                    {/*  </Form.Item>*/}
                    {/*</Col>*/}
                    <Col sm={12} md={6}>
                        <Form.Item
                            label="Working Days"
                            name="days"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required",
                                },
                            ]}
                        >
                            <Select
                                mode="multiple"
                                placeholder="Working Days"
                                style={{ width: "100%" }}
                            >
                                {days.map((day) => {
                                    return (
                                        <Select.Option
                                            value={day.id}
                                            key={day.id}
                                        >
                                            {day.day_name ?? day.name}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {/*<Row className="mb-3">*/}
                {/*  */}
                {/*</Row>*/}

                {/*<Row>*/}
                {/*    <Col sm="12">*/}
                {/*        <hr />*/}
                {/*        <p style={{ color: "#993b78", fontSize: "20px" }}>*/}
                {/*            Emirates Prices*/}
                {/*        </p>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                {/**/}
                {/*<Row className="mb-3">*/}
                {/*    <Col>*/}
                {/*        <AddCourierEmirates*/}
                {/*            emirates={emirates}*/}
                {/*            onChange={setEmiratesPrice}*/}
                {/*        />*/}
                {/*        {emiratesError.state && (*/}
                {/*            <div style={{ color: "red" }}>*/}
                {/*                This field is required!*/}
                {/*            </div>*/}
                {/*        )}*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                {!initialValues && (
                    <>
                        <Row>
                            <Col sm="12">
                                <hr />
                                <p
                                    style={{
                                        color: "#993b78",
                                        fontSize: "20px",
                                    }}
                                >
                                    Service Info
                                </p>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <CourierServicesForm
                                    emirates={emirates}
                                    delivery={allServices}
                                    vehicles={allVehicles}
                                    initialValues={initialValues?.services}
                                    onChange={(v) => setCourierServices(v)}
                                    getVehicles={getVehicles}
                                    getService={getService}
                                />
                                {emiratesError.state && (
                                    <div style={{ color: "red" }}>
                                        This field is required!
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </>
                )}
                <Row>
                    <Col sm="12">
                        <Button className="addBtn2" htmlType="submit">
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
}

export default ErrorHandler(CommonCourier, axiosApiInstance);
