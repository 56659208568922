import { Route } from "react-router-dom";
import AllEmployees from "../components/supplier-pages/AllEmployees";
import CreateEmployee from "../components/supplier-pages/CreateEmployee";
import EditEmployee from "../components/supplier-pages/EditEmployee";

export default function EmployeeRoutes() {
  return (
    <>
      <Route exact path="/add_employee" component={CreateEmployee} />
      <Route exact path="/employees" component={AllEmployees} />
      <Route exact path="/edit_employee/:id" component={EditEmployee} />
    </>
  );
}
