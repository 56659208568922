import React, { Component } from "react";
import axios from "axios";
import { Container, Row, Col, Button, Form, Modal } from "react-bootstrap";
// import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { TimePicker, Checkbox } from "antd";
import moment from "moment";
import "antd/dist/antd.css";
import { Select } from "antd";
import ErrorHandler from "../../../../ErrorHandler/ErrorHandler";
import { APILINK } from "../../../../Endpoint";
import CommonCourierServiceAction from "./CommonCourierServiceAction";

const axiosApiInstance = axios.create();

function AddCourierService() {
    return <CommonCourierServiceAction mode={"create"} />;
}

export default ErrorHandler(AddCourierService, axiosApiInstance);
