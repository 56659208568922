import React, { Component } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import {
    Container,
    Row,
    Col,
    Button,
    Modal,
    OverlayTrigger,
    Tooltip,
    Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../style-sheets/products.css";
import { connect } from "react-redux";
import { APILINK } from "../../Endpoint";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import { Spin } from "antd";
import { ToastContainer, toast } from "react-toastify";

const axiosApiInstance = axios.create();

class AllSuppliers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            tableData: [],
            keyWord: "",
            showMsg: false,
            showResetModel: false,
            columns: [
                {
                    name: "supplier name",
                    selector: "name",
                    sortable: true,
                    right: true,
                },
                {
                    name: "supplier phone",
                    selector: "phone",
                    sortable: true,
                    right: true,
                },
                {
                    name: "Working hours from",
                    selector: "working_hours_from",
                    sortable: true,
                    right: true,
                },
                {
                    name: "Working hours to",
                    selector: "working_hours_to",
                    sortable: true,
                    right: true,
                },
                {
                    name: "supplier city",
                    selector: "city",
                    sortable: true,
                    right: true,
                },

                {
                    name: "Actions",
                    button: true,
                    cell: (row) => (
                        <div className="courierTable">
                            <OverlayTrigger
                                key={"1"}
                                placement={"top"}
                                overlay={
                                    <Tooltip id={`tooltip-${"top"}`}>
                                        Reset Password
                                    </Tooltip>
                                }
                            >
                                <i
                                    onClick={this.showReset}
                                    id={row.id}
                                    className="fas fa fa-tools pr-2"
                                ></i>
                            </OverlayTrigger>
                            <Link to={`/edit_supplier/${row.id}`}>
                                {" "}
                                <i class="fas fa-edit"></i>
                            </Link>
                            <i
                                id={row.id}
                                onClick={this.show}
                                class="fas fa-trash px-2"
                            ></i>{" "}
                        </div>
                    ),
                },
            ],
            proId: "",
            fileData: [],
            showSpinner: false,
            check: false,
            nextUrl: "",
            prevUrl: "",
            dis1: true,
            dis2: false,
            suppID: "",
        };
    }
    // let history = useHistory();

    showReset = (e) => {
        this.setState({ showResetModel: true, suppID: e.target.id });
    };
    closeReset = (e) => {
        this.setState({ showResetModel: false });
    };
    getAll = async () => {
        this.setState({ loading: true });

        const result = await axiosApiInstance.get(
            APILINK + `/suppliers/?limit=10`
        );
        if (result) {
            this.setState({ tableData: result.data.results, loading: false });
            if (result.data.next !== null) {
                this.setState({
                    nextUrl: result.data.next,
                });
            }

            if (result.data.previous !== null) {
                this.setState({
                    prevUrl: result.data.previous,
                });
            }
            if (result.data.next === null) {
                this.setState({ dis2: true });
            }
        }
    };

    componentDidMount() {
        console.log("this", this.props.history);
        this.getAll();

        let x = [
            {
                email: "",
                password: "",
                name: "",
                user_name: "",
                city: "",
                address: "",
                phone: "",
                whatsapp: "",
                is_deliver: "",
                working_hours_from: "",
                working_hours_to: "",
                delivery_hours_from: "",
                delivery_hours_to: "",
            },
        ];
        this.setState({ fileData: x });
    }

    downLoadCatalog = (e) => {
        this.downloadCSV2(this.state.fileData);
    };

    convertArrayOfObjectsToCSV2 = (array) => {
        let result;

        const columnDelimiter = ",";
        const lineDelimiter = "\n";
        const keys = Object.keys(this.state.fileData[0]);

        result = "";
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach((item) => {
            let ctr = 0;
            keys.forEach((key) => {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    };

    downloadCSV2 = (array) => {
        const link = document.createElement("a");
        let csv = this.convertArrayOfObjectsToCSV2(array);
        if (csv == null) return;

        const filename = "export.csv";

        if (!csv.match(/^data:text\/csv/i)) {
            this.state.fileData = this.state.fileData;
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute("href", encodeURI(csv));
        link.setAttribute("download", filename);
        link.click();
    };

    onSearch = (e) => {
        this.setState({ keyWord: e.target.value });
        let x = this.state.tableData.filter(
            (item) =>
                item.name &&
                item.name.toLowerCase().includes(e.target.value.toLowerCase())
        );
        this.setState({ tableData: x });
        if (e.target.value === "") {
            this.getAll();
        }
    };

    handleSelected = (state) => {
        this.setState({ selectedRows: state.selectedRows });
    };

    onUpload = async (event) => {
        this.setState({ showSpinner: true });

        this.setState({ selectedFile: event.target.files[0] });

        const eventdata = new FormData();
        if (event.target.files[0]) {
            eventdata.append("file", event.target.files[0]);
        }

        const result = await axiosApiInstance.post(
            APILINK + `/supplier_upload_file`,
            eventdata
        );
        if (result.data.data === "done") {
            this.setState({ showSpinner: false, check: true });
            setTimeout(() => {
                this.setState({ showSpinner: false, check: false });
                this.getAll();
            }, 2000);
        }
    };

    getNext = async () => {
        const result = await axiosApiInstance.get(`${this.state.nextUrl}`);

        console.log("orderssss");

        console.log(result.data);
        this.setState({
            tableData: result.data.results,
            // nextUrl: result.data.next,
            // prevUrl: result.data.previous,
        });
        if (result.data.next !== null) {
            this.setState({
                nextUrl: result.data.next,
                // prevUrl: result.data.previous,
            });
        }
        if (result.data.previous !== null) {
            this.setState({
                prevUrl: result.data.previous,
                dis1: false,
            });
        }
        if (result.data.next === null) {
            this.setState({ dis2: true, dis1: false });
        }
    };

    getPrev = async () => {
        const result = await axiosApiInstance.get(`${this.state.prevUrl}`);

        console.log("orderssss");
        console.log(result.data);
        this.setState({
            tableData: result.data.results,
            // nextUrl: result.data.next,
            // prevUrl: result.data.previous,
        });
        if (result.data.next !== null) {
            this.setState({
                nextUrl: result.data.next,
                dis2: false,

                // prevUrl: result.data.previous,
            });
        }
        if (result.data.previous !== null) {
            this.setState({
                prevUrl: result.data.previous,
            });
        }
        if (result.data.previous === null) {
            this.setState({ dis1: true, dis2: false });
        }
    };

    show = (e) => {
        this.setState({ showMsg: true, suppID: e.target.id });
    };
    hide = (e) => {
        this.setState({ showMsg: false });
    };

    deleteSupplier = async () => {
        const result = await axiosApiInstance
            .delete(APILINK + `/supplier/${this.state.suppID}`)
            .then((res) =>
                this.setState((state, props) => {
                    return { showMsg: false };
                })
            )
            .catch((err) => console.log(err));
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.showMsg !== this.state.showMsg) {
            this.getAll();
        }
    }
    onHandleInput = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    reset = async () => {
        let data = {
            password: this.state.password,
        };
        const result = await axiosApiInstance
            .put(APILINK + `/reset_password/${this.state.suppID}`, data)

            .catch((err) => console.log(err));

        if (result) {
            this.setState({ showResetModel: false, password: "" });

            setTimeout(() => {
                toast.info(`Password has been reset successfully`);
            }, 200);
        }
    };
    render() {
        return (
            <div>
                <Container
                    style={{
                        marginTop: "80px",
                        //  marginLeft: "300px"
                    }}
                    className="py-3"
                >
                    <Row>
                        <Col
                            className="p-4 mb-1"
                            style={{ textAlign: "initial" }}
                            sm={12}
                        >
                            <h2>
                                {" "}
                                <i
                                    style={{ cursor: "pointer" }}
                                    class="fas fa-chevron-left px-1"
                                    onClick={this.props.history.goBack}
                                ></i>{" "}
                                <i class="fas fa-shopping-basket"></i> Suppliers
                            </h2>
                        </Col>
                    </Row>
                    <Row className="my-1">
                        <Col style={{ textAlign: "initial" }} sm={8}>
                            <Button
                                onClick={this.downLoadCatalog}
                                className="headBtn"
                            >
                                Download supplier template
                            </Button>
                            <input
                                onChange={this.onUpload}
                                type="file"
                                className="customBtn"
                            />

                            {this.state.showSpinner === true ? (
                                <Spin tip="Loading..."></Spin>
                            ) : null}
                            {this.state.check === true ? (
                                <i className="fas fa-check-circle checkk"></i>
                            ) : null}
                            {/* </form> */}
                        </Col>
                        <Col style={{ textAlign: "end" }} sm={4}>
                            <Link to="/add_supplier">
                                {" "}
                                <Button className="headBtn">
                                    Add Supplier
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            {this.state.loading === false ? (
                                <DataTable
                                    columns={this.state.columns}
                                    data={this.state.tableData}
                                    // selectableRows
                                    onSelectedRowsChange={this.handleSelected}
                                    subHeader
                                    subHeaderComponent={
                                        <React.Fragment>
                                            <Col
                                                style={{
                                                    textAlign: "initial",
                                                    color: "#7c8798",
                                                }}
                                                sm={12}
                                                md={6}
                                            ></Col>
                                            <Col
                                                style={{ textAlign: "right" }}
                                                sm={12}
                                                md={6}
                                            >
                                                <label
                                                    style={{ color: "#7c8798" }}
                                                >
                                                    Search:
                                                </label>
                                                <input
                                                    id="search"
                                                    type="text"
                                                    placeholder=""
                                                    aria-label="Search Input"
                                                    className="seachInput"
                                                    value={this.state.keyWord}
                                                    onChange={this.onSearch}
                                                />
                                            </Col>
                                        </React.Fragment>
                                    }
                                />
                            ) : (
                                <Col className="text-center" sm={12}>
                                    {this.state.loading ? (
                                        <Spin tip="Loading..."></Spin>
                                    ) : (
                                        "No data to be displayed"
                                    )}
                                </Col>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ textAlign: "initial" }} sm={12}>
                            {this.state.tableData.length === 0 ? null : (
                                <div className="p-3">
                                    {" "}
                                    <Button
                                        disabled={this.state.dis1}
                                        onClick={this.getPrev}
                                        className="nextBtn"
                                    >
                                        <i class="fas fa-angle-double-left"></i>{" "}
                                        Previous
                                    </Button>
                                    <Button
                                        disabled={this.state.dis2}
                                        onClick={this.getNext}
                                        className="nextBtn"
                                    >
                                        Next
                                        <i class="fas fa-angle-double-right pl-1"></i>
                                    </Button>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
                <Modal
                    show={this.state.showResetModel}
                    onHide={this.closeReset}
                >
                    <Container className="p-4">
                        <Row>
                            <Col className="text-center" sm={12}>
                                {" "}
                                <h6>Reset Password for this supplier</h6>
                            </Col>
                            <Col sm={12}>
                                <Form.Label>Enter New Password</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter New Password"
                                    value={this.state.password}
                                    name="password"
                                    onChange={this.onHandleInput}
                                    required
                                />
                            </Col>
                            <Row style={{ margin: "auto" }}>
                                <Col md={12}>
                                    <Button
                                        className="headBtn"
                                        onClick={this.closeReset}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={this.reset}
                                        className="headBtn"
                                    >
                                        Reset
                                    </Button>
                                </Col>
                            </Row>
                        </Row>
                    </Container>
                </Modal>
                <Modal show={this.state.showMsg} onHide={this.hide}>
                    <Container className="p-4">
                        <Row>
                            <Col className="text-center" sm={12}>
                                {" "}
                                <h6>
                                    Are you sure you want to delete this
                                    supplier?
                                </h6>
                            </Col>
                            <Row style={{ margin: "auto" }}>
                                <Col md={12}>
                                    <Button
                                        onClick={this.deleteSupplier}
                                        className="headBtn"
                                    >
                                        Delete
                                    </Button>
                                    <Button
                                        className="headBtn"
                                        onClick={this.hide}
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                        </Row>
                    </Container>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuth: state.auth.isAuth,
    user: state.auth.user,
});

export default connect(
    mapStateToProps,
    null
)(ErrorHandler(AllSuppliers, axiosApiInstance));
