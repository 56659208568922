import { Button, Modal } from "react-bootstrap";
import { DatePicker, Form } from "antd";
import React, { useEffect, useState } from "react";
import { APILINK } from "../../Endpoint";
import { toast } from "react-toastify";
import axios from "axios";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

const axiosApiInstance = axios.create();

function RescheduleOrder({ open, onClose, endpoint, id, methodType }) {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const rescheduleOrderSubmit = async (data) => {
    const method =
      methodType === "PUT" ? axiosApiInstance.put : axiosApiInstance.post;
    setLoading(true);
    const res = await method(APILINK + `/${endpoint}/${id}`, {
      scheduled_date: data.scheduled_date.format("YYYY-MM-DD"),
    }).catch((err) => {
      setLoading(false);
      setError(
        err.response.data?.scheduled_date?.[0] ??
          err.response.data?.detail ??
          "Something went wrong"
      );
    });

    if (res) {
      if (res.data) {
        onClose();
        setTimeout(() => {
          toast.success(`Order Rescheduled successfully`);
        }, 500);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    console.log(open);
  }, [open]);
  return (
    <Modal
      show={open}
      style={{
        marginTop: "10%",
      }}
      onHide={onClose}
    >
      <div
        style={{
          padding: "20px",
        }}
      >
        <h4
          style={{
            marginBottom: "16px",
          }}
        >
          Reschedule Order
        </h4>
        <Form layout="vertical" onFinish={rescheduleOrderSubmit}>
          <Form.Item
            label="Scheduled Data"
            name="scheduled_date"
            rules={[
              {
                required: true,
                message: "Please select date",
              },
            ]}
          >
            <DatePicker placeholder="Select Date" format="DD-MM-YYYY" />
          </Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "16px",
              flexDirection: "row",
            }}
          >
            <div>{error && <p style={{ color: "red" }}>{error}</p>}</div>
            <Button
              type="submit"
              className="btn1 px-4"
              style={{
                marginInlineStart: "auto",
              }}
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default ErrorHandler(RescheduleOrder, axiosApiInstance);
