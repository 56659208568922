import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import { Link } from "react-router-dom";
import "../../style-sheets/products.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {
  Button,
  Form,
  Select,
  Input,
  TimePicker,
  Checkbox,
  Upload,
  message,
  Spin
} from "antd";

import moment from "moment";
// import GetCoordinates from "../GetCoordinates";
import ViewMap from "../ViewMap";
import { connect } from "react-redux";
import Geocode from "react-geocode";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import { APILINK } from "../../Endpoint";
import { UploadOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
const axiosApiInstance = axios.create();
Geocode.setApiKey("AIzaSyDGLV2zbFZNvPOeFtuePcisgwAbmVIsSH0");

function CreateSupplier(props) {
  const [data, setData] = useState("");
  const [cate, setCate] = useState([]);
  const [keys, setKeys] = useState([]);
  const [image, setImage] = useState("");
  const [fileList, setFileList] = useState([]);
  const [location, setLocation] = useState({
    lat: "0",
    lon: "0"
  });
  const [workingFrom, setWorkFrom] = useState("12:00");
  const [workingTo, setWorkTo] = useState("12:00");
  const [deliverFrom, setDeliverFrom] = useState("12:00");
  const [deliverTo, setDeliverTo] = useState("12:00");
  const [address, setAddress] = useState("");

  const [form] = Form.useForm();
  const history = useHistory();
  const onFinish = async (values) => {
    values.latitude = location.lat;
    values.longitude = location.lon;
    values.working_hours_from = workingFrom;
    values.working_hours_to = workingTo;
    values.delivery_hours_from = deliverFrom;
    values.delivery_hours_to = deliverTo;

    if (fileList.length > 0) {
      console.log("here file listtttttttttttttttttt");
      const eventdata = new FormData();
      eventdata.append("image", fileList[0]);
      // values.image = eventdata;
      const result = await axiosApiInstance
        .post(APILINK + `/supplier`, eventdata)
        .catch((err) => {
          console.log(err.response);
          if (err.response.status == 500) {
            setTimeout(() => {
              toast.error(`Server Error!`);
            }, 500);
          }
        });
      if (result) {
        console.log(result);
        setTimeout(() => {
          toast.info(`Supplier was created successfully`);
        }, 500);
        setTimeout(() => {
          props.history.push("/suppliers");
        }, 3500);
      }
    }

    console.log(values);
    const result = await axiosApiInstance
      .post(APILINK + `/supplier`, values)
      .catch((err) => {
        console.log(err.response);
        if (err.response.status == 500) {
          setTimeout(() => {
            toast.error(`Server Error!`);
          }, 500);
        } else {
          for (const error in err.response.data.error) {
            console.log(err.response.data.error);

            setTimeout(() => {
              toast.error(
                `${
                  err.response.data.error[error][0].charAt(0).toUpperCase() +
                  err.response.data.error[error][0].slice(1)
                }`
              );
            }, 500);
          }
        }
      });
    if (result) {
      setTimeout(() => {
        toast.info(`Supplier was updated successfully`);
      }, 500);
      setTimeout(() => {
        props.history.push("/suppliers");
      }, 3500);
    }
  };

  useEffect(() => {
    getCategories();
    getKeys();
  }, []);

  const getCategories = async () => {
    const result = await axiosApiInstance.get(
      APILINK + "/categories/?limit=10000"
    );

    setCate(result.data.results);
  };
  const getKeys = async () => {
    const result = await axiosApiInstance.get(
      APILINK + `/keywords/?limit=10000`
    );

    setKeys(result.data.results);
  };
  const uploadprops = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      console.log(file);
      setFileList([file]);
      return false;
    },
    fileList
  };
  const handleWorkingFrom = (time, timeString) => {
    console.log(timeString);
    setWorkFrom(timeString);
  };

  const handleWorkingTo = (time, timeString) => {
    setWorkTo(timeString);
  };

  const handleDelieveryFrom = (time, timeString) => {
    setDeliverFrom(timeString);
  };
  const handleDelieveryTo = (time, timeString) => {
    setDeliverTo(timeString);
  };
  const handleAddress = (e) => {
    setAddress(e.target.value);
    Geocode.fromAddress(e.target.value).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setLocation({ lat: lat, lon: lng });
        console.log(lat, lng, response.results[0].geometry.location);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  return (
    <Container>
      <Row>
        <Col className="p-4 mb-4" style={{ textAlign: "initial" }} sm={12}>
          <h2>
            {" "}
            <i
              style={{ cursor: "pointer" }}
              class="fas fa-chevron-left px-1"
              onClick={history.goBack}
            ></i>
            <i class="fas fa-shopping-basket pr-2"></i>Create Supplier{" "}
          </h2>
        </Col>
      </Row>

      <Form form={form} onFinish={onFinish} layout="vertical">
        <Row>
          <Col sm="12" md="6">
            <Form.Item
              label="User Name"
              name="user_name"
              rules={[
                {
                  required: true,
                  message: "This field is required"
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col sm="12" md="6">
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "This field is required"
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="6">
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "This field is required"
                }
              ]}
            >
              <Input />
            </Form.Item>{" "}
          </Col>
          <Col sm="12" md="6">
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "This field is required"
                }
              ]}
            >
              <Input.Password className="" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="6">
            {" "}
            <Form.Item
              label="Address"
              name="address"
              rules={[
                {
                  required: true,
                  message: "This field is required"
                }
              ]}
            >
              <Input value={address} onChange={handleAddress} />
            </Form.Item>
          </Col>
          <Col sm="12" md="6">
            <Form.Item
              label="City"
              name="city"
              rules={[
                {
                  required: true,
                  message: "This field is required"
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="6">
            <Form.Item
              label="Phone"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "This field is required"
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col sm="12" md="6">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "This field is required"
                }
              ]}
              label="Whats app"
              name="whatsapp"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="6">
            <Form.Item
              label="Categories"
              name="category"
              rules={[
                {
                  required: true,
                  message: "This field is required"
                }
              ]}
              // initialValue={}
            >
              <Select
                mode="multiple"
                placeholder="Select Categories"
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                // showSearch
              >
                {cate?.map((item) => (
                  <Select.Option value={item.id} name={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col sm="12" md="6">
            <Form.Item
              label="Keywords"
              name="keyword"
              rules={[
                {
                  required: true,
                  message: "This field is required"
                }
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Select keywords"
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {keys?.map((item) => (
                  <Select.Option value={item.id}>{item.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col sm="12" md="6">
            <Form.Item label="Working hours from">
              <TimePicker
                value={moment(workingFrom, "HH:mm:ss")}
                onChange={handleWorkingFrom}
              />
            </Form.Item>
          </Col>
          <Col sm="12" md="6">
            <Form.Item label="Working hours to">
              <TimePicker
                value={moment(workingTo, "HH:mm:ss")}
                onChange={handleWorkingTo}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="6">
            <Form.Item label="Delivery hours from">
              <TimePicker
                value={moment(deliverFrom, "HH:mm:ss")}
                onChange={handleDelieveryFrom}
              />
            </Form.Item>
          </Col>
          <Col sm="12" md="6">
            <Form.Item label="Delivery hours to">
              <TimePicker
                value={moment(deliverTo, "HH:mm:ss")}
                onChange={handleDelieveryTo}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="6">
            <Form.Item label="Website" name="website">
              <Input />
            </Form.Item>
          </Col>
          <Col className="mt-4" sm="12" md="6">
            <Form.Item valuePropName="checked" label="" name="is_deliver">
              <Checkbox>
                {/* Is Deliver? */}
                Delivery available
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="6">
            <ViewMap location={location} />
          </Col>
          <Col sm="12" md="6">
            <Form.Item label="Uploaded Image">
              {" "}
              <Upload {...uploadprops} multiple={false} maxCount={1}>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col className="text-center" sm="12">
            <Button color="primary" htmlType="submit" className="addBtn2">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
export default ErrorHandler(CreateSupplier, axiosApiInstance);
