import React, { useEffect, useMemo } from "react";
import ChatFragment from "./chatFragment";
import firebaseObject from "../../firebase/firebaseObject";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { useSelector } from "react-redux";
import { ChatRepository } from "../../firebase/templates";

export default function CourierChat({ details, onLoaded } = { details: {} }) {
  const chatId =
    details.courier?.email && details.order_id
      ? `${details.order_id}__${details.courier.email}`
      : "";
  const fcmToken = localStorage.getItem("firebaseToken");

  console.log({ chatId });
  const chatIdRef = firebaseObject
    .firestore()
    .collection("courier_chats")
    .doc(chatId);

  const [currentChat, chatLoading] = useDocumentData(chatIdRef);

  const messagesRef = chatIdRef
    .collection("courier_messages")
    .orderBy("sentAt");
  // .limitToLast(messagesLimit)
  const [initialMessages, messagesLoading] = useCollectionData(messagesRef, {
    idField: "messageId",
  });

  useEffect(() => {
    if (!chatLoading && !currentChat) {
      chatIdRef.set(
        {
          courier: {
            id: details.courier.id,
            name: details.courier.name,
            email: details.courier.email,
          },
          courier_order: details.order_id,
        },
        { merge: true }
      );
    }

    onLoaded(!chatLoading);
    console.log({ currentChat });
  }, [chatLoading]);

  const user = useSelector((state) => state.auth.user);
  const chattingWithName = currentChat?.courier?.name ?? "";

  const chatRepository = useMemo(() => {
    const sender = {
      email: user.id,
      name: user.user_name,
      userType: "admin",
      fcmToken,
    };
    const extraInformation = {
      courier_order: details.order_id,
    };
    return new ChatRepository(
      firebaseObject,
      "courier_chats",
      "courier_messages",
      sender,
      extraInformation
    );
  }, [details]);

  return (
    <ChatFragment
      chatId={chatId}
      initialMessages={initialMessages}
      messagesLoading={messagesLoading}
      chatLoading={chatLoading}
      currentChat={currentChat}
      chattingWithName={chattingWithName}
      chatSubTitle={`Order: #${details.order_id}`}
      chatRepository={chatRepository}
      checkSenderIsMe={(messageItem) =>
        messageItem.sender.userType !== "courier"
      }
    />
  );
}
