import React, { Component } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import "../../style-sheets/home.css";
import img from "../../imgs/no-img.png";
import SupplierConfirmModal from "../yanzo-Modals/SupplierConfirmModal";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ViewMap from "../ViewMap";
import { APILINK } from "../../Endpoint";
import { connect } from "react-redux";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import SupplierCreateOffer from "../yanzo-Modals/SupplierCreateOffer";
import MarketPrice from "../MarketPrice";

const axiosApiInstance = axios.create();

class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openConfirm: false,
      opencreate: false,
      showRej: false,
      hide: false,
      lastConfirm: false,
      stillValidDone: false,
    };
  }

  confirm = (e) => {
    this.setState({ openConfirm: true });
  };
  closeConfirm = (e) => {
    this.setState({ openConfirm: e });
  };

  create = (e) => {
    this.setState({ opencreate: true });
  };
  closeCreate = (e) => {
    this.setState({ opencreate: e });
  };

  openRej = (e) => {
    this.setState({ showRej: true });
  };
  closeRej = (e) => {
    this.setState({ showRej: false });
  };

  Rejectoffer = async () => {
    let data = {
      request: {
        request_type: this.props.details.request_type,
        request_status: "2",
      },
    };

    console.log(data);
    const result = await axiosApiInstance
      .put(APILINK + `/status_request/${this.props.details.id}`, data)
      .then((res) => {
        console.log(res.data);
        if (res.data.data.id) {
          // this.setState({ showmsg: true, msg: "order has been rejected" });
          setTimeout(() => {
            toast.info(`Offer has been rejected`);
          }, 500);
          setTimeout(() => {
            this.setState({
              showmsg: false,
              cost: "",
              time: "",
              price: "",
              notes: "",
            });
            this.closeRej();
          }, 1500);
        }
      });

    this.setState({ isOfferFinished: true });
  };

  secConfirm = async () => {
    let data = {
      request: {
        offer_status: "1",
      },
    };

    console.log(data);
    const result = await axiosApiInstance.put(
      APILINK + `/status_offer/${this.props.details.id}`,
      data
    );
    if (result) {
      if (result.data.data.id) {
        this.props.changeOffer(true);
        setTimeout(() => {
          toast.info(`Offer Confirmed successfully`);
        }, 500);
        this.props.getAll();
        this.setState({ lastConfirm: true });

        this.setState({ hide: true });
      }
    }
  };

  secReject = async () => {
    let data = {
      request: {
        offer_status: "2",
      },
    };

    console.log(data);
    const result = await axiosApiInstance.put(
      APILINK + `/status_offer/${this.props.details.id}`,
      data
    );
    if (result) {
      if (result.data.data.id) {
        setTimeout(() => {
          toast.dark(`Offer has been Rejected`);
        }, 500);
        this.setState({ lastConfirm: true });
        this.props.getAll();
        this.setState({ hide: true });
      }
    }
  };

  checkValidity = async () => {
    console.log({ blablablabla: this.props.details.id });
    const result = await axiosApiInstance.get(
      APILINK + `/create_validate/${this.props.details.id}`
    );
    console.log(result);
    if (result) {
      if (result.data.data.id) {
        this.setState({ stillValidDone: true });
        setTimeout(() => {
          toast.info(`Your request has been sent`);
        }, 500);
      }
    }
  };

  render() {
    console.log(this.props);
    let nowDate = new Date();
    let utcDate = nowDate.toISOString();
    console.log({ utcDate });
    let last_element;
    if (this.props.details.RequestsValidate.length !== 0) {
      last_element =
        this.props.details.RequestsValidate[
          this.props.details.RequestsValidate.length - 1
        ];
    }
    return (
      <div style={{ borderLeft: "solid 1px yellow" }}>
        <Container className="pt-3">
          <Row>
            <Col sm={6}>
              <h4>Order Details</h4>
            </Col>
            <Col sm={6}>
              {this.props.details.pre_request.taken ? (
                <h6 style={{ color: "red", fontWeight: "bold" }}>Closed</h6>
              ) : null}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={12}>
              <p style={{ color: "grey" }}>
                {this.props.details.pre_request.name}
              </p>
            </Col>
          </Row>
          <Row>
            <Col style={{ background: "#993b78", color: "white" }} sm={12}>
              <p style={{ marginBottom: "0rem" }}>
                Ticket Number {this.props.details.pre_request.ticket_code}
              </p>
            </Col>
          </Row>
          <Row className="my-4">
            <Col sm={12} md={6}>
              <p className="orderDetailsInfo1">
                Request Name :{" "}
                <span className="orderDetailsInfo2">
                  {this.props.details.pre_request.name}
                </span>
              </p>
              <p className="orderDetailsInfo1">
                Product Quantity :{" "}
                <span className="orderDetailsInfo2">
                  {this.props.details.pre_request.product_quantity}
                </span>{" "}
              </p>

              <p className="orderDetailsInfo1">
                Expected Delivery Date:{" "}
                <span className="orderDetailsInfo2">
                  {this.props.details?.orders?.scheduled_date ??
                    this.props.details.pre_request.expected_delivery_date}
                </span>{" "}
              </p>
              <p className="orderDetailsInfo1">
                Client Address :{" "}
                <span className="orderDetailsInfo2">
                  {this.props.details.pre_request.client_address}
                </span>{" "}
              </p>
              <p className="orderDetailsInfo1">
                Yanzo Price :{" "}
                <strong>
                  {" "}
                  {Object.keys(this.props.details.product).length !== 0 &&
                  Object.keys(this.props.details.offers).length === 0
                    ? this.props.details.product?.yanzo_price
                    : this.props.details.yanzo_price}
                </strong>
              </p>
              {this.props.details.offers &&
              Object.keys(this.props.details.offers).length === 0 &&
              Object.getPrototypeOf(this.props.details.offers) ===
                Object.prototype ? (
                <>
                  <p className="orderDetailsInfo1">
                    Product Code : {this.props.details.product?.code}{" "}
                  </p>
                  <p className="orderDetailsInfo1">
                    Product Name : {this.props.details.product?.name}{" "}
                  </p>
                  <p className="orderDetailsInfo1">
                    Market Price : <MarketPrice row={this.props.details} />
                  </p>
                </>
              ) : (
                <>
                  <p className="orderDetailsInfo1">
                    Product Code :{" "}
                    {this.props.details.offers?.is_offer
                      ? this.props.details.offers.offer_data.code
                      : this.props.details.offers?.product?.code}{" "}
                  </p>
                  <p className="orderDetailsInfo1">
                    Product Name :{" "}
                    {this.props.details.offers?.is_offer
                      ? this.props.details.offers.offer_data.name
                      : this.props.details.offers?.product?.name}{" "}
                  </p>
                  <p className="orderDetailsInfo1">
                    Market Price : <MarketPrice row={this.props.details} />
                  </p>
                  {this.props.details.offers?.is_offer ? (
                    <>
                      <p className="orderDetailsInfo1">
                        Offered Quantity :{" "}
                        <strong>
                          {this.props.details.offers.offer_data.quantity ?? ""}
                        </strong>
                      </p>
                      <p className="orderDetailsInfo1">
                        Offered Price :{" "}
                        {this.props.details.offers.offer_data.price}{" "}
                      </p>
                    </>
                  ) : (
                    <p className="orderDetailsInfo1">
                      Market Price : <MarketPrice row={this.props.details} />
                    </p>
                  )}
                </>
              )}
              <p className="orderDetailsInfo1">
                Admin's Notes :{" "}
                <span className="orderDetailsInfo2">
                  {this.props.details.pre_request?.notes}
                </span>{" "}
              </p>
              <p className="orderDetailsInfo1">
                Supplier's Notes :{" "}
                <span className="orderDetailsInfo2">
                  {this.props.details.note}
                </span>{" "}
              </p>
              {this.props.details.offer_status === "2" ||
              this.props.details.request_status === "2" ? (
                <p style={{ color: "red", fontWeight: "bold" }}>Rejected</p>
              ) : null}

              <p className="orderDetailsInfo1">
                {/* Is Deliver? */}
                Delivery available
                {this.props.details.is_deliver === false ||
                this.props.details.is_deliver === null ? (
                  <span
                    style={{
                      fontWeight: "400",
                      fontSize: "13px",
                      color: "grey",
                      paddingLeft: "10px",
                    }}
                  >
                    No{" "}
                  </span>
                ) : (
                  <React.Fragment>
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "13px",
                        color: "grey",
                        marginLeft: "5px",
                      }}
                    >
                      Yes
                    </span>
                    <p className="orderDetailsInfo1">
                      Delivery Cost :{" "}
                      <span className="orderDetailsInfo2">
                        {this.props.details.deliver_cost}
                      </span>{" "}
                    </p>
                    <p className="orderDetailsInfo1">
                      Delivery Date :{" "}
                      <span className="orderDetailsInfo2">
                        {this.props.details?.deliver_time?.split("T")[0]}
                      </span>{" "}
                    </p>
                    <p className="orderDetailsInfo1">
                      Admin's Notes :{" "}
                      <span className="orderDetailsInfo2">
                        {this.props.details.pre_request?.notes}
                      </span>{" "}
                    </p>
                    <p className="orderDetailsInfo1">
                      Supplier's Notes :{" "}
                      <span className="orderDetailsInfo2">
                        {this.props.details.note}
                      </span>{" "}
                    </p>
                  </React.Fragment>
                )}
              </p>
            </Col>
            <Col sm={12} md={6}>
              {this.props.details.pre_request.image === "" ? (
                <img className="img-fluid" src={img} alt="product" />
              ) : (
                <img
                  className="img-fluid"
                  src={APILINK + `${this.props.details.pre_request.image}`}
                  alt="product"
                />
              )}
            </Col>
          </Row>

          <Row className="mb-3">
            <Col sm={12} md={12}>
              <ViewMap location={this.props.details.pre_request.location} />
            </Col>
          </Row>

          {this.props.details.request_type === "2" &&
          (this.props.details.offer_status === "3" ||
            this.props.details.offer_status === "4") &&
          !this.state.lastConfirm ? (
            this.props.details.Validate_date > utcDate ? (
              <Row className="my-3">
                <Col sm={12}>
                  <Button onClick={this.secConfirm} className="btn1 px-4">
                    Confirm
                  </Button>
                  <Button onClick={this.secReject} className="btn2 px-4">
                    Reject
                  </Button>
                </Col>
              </Row>
            ) : (
              <Row className="my-3">
                <Col sm={12}>
                  {this.props.details.RequestsValidate.length === 0 &&
                  !this.state.stillValidDone ? (
                    <Button onClick={this.checkValidity} className="btn3 px-4">
                      Still Valid?
                    </Button>
                  ) : null}
                </Col>
              </Row>
            )
          ) : null}

          {!this.state.isOfferFinished &&
          this.props.details.request_status === null &&
          this.props.details.request_type === "1" &&
          !this.props.details.pre_request.taken ? (
            this.props.details.Validate_date > utcDate ? (
              <Row className="my-3">
                <Col sm={12}>
                  <Button onClick={this.confirm} className="btn1 px-4">
                    Confirm
                  </Button>
                  <Button onClick={this.openRej} className="btn2 px-4">
                    Reject
                  </Button>
                  <Button onClick={this.create} className="btn4 px-4">
                    Create offer
                  </Button>
                </Col>
              </Row>
            ) : (
              <Row className="my-3">
                <Col sm={12}>
                  {this.props.details.RequestsValidate.length !== 0 ? (
                    last_element.validate_status === "1" ? (
                      <p className="orderDetailsInfo2">
                        Check Availability Status:{" "}
                        <span className="orderDetailsInfo2">
                          Waiting for agent's response...
                        </span>{" "}
                      </p>
                    ) : last_element.validate_status === "3" ? (
                      <p className="orderDetailsInfo2">
                        Check Availability Status:{" "}
                        <span className="orderDetailsInfo2">Rejected</span>{" "}
                      </p>
                    ) : null
                  ) : null}

                  {this.props.details.RequestsValidate.length === 0 &&
                  !this.props.details.pre_request.taken &&
                  !this.state.stillValidDone ? (
                    <Button onClick={this.checkValidity} className="btn3 px-4">
                      Still Valid?
                    </Button>
                  ) : null}
                </Col>
              </Row>
            )
          ) : null}

          <SupplierConfirmModal
            id={this.props.details.id}
            show={this.state.openConfirm}
            // show={true}
            type={this.props.details.request_type}
            closeForm={this.closeConfirm}
            onConfirmed={() => {
              this.setState({ isOfferFinished: true });
            }}
            date={this.props.details.pre_request?.expected_delivery_date}
            yanzoPrice={this.props.details.product?.yanzo_price}
            chosenProduct={
              Object.keys(this.props.details.product).length !== 0
                ? this.props.details.product
                : null
            }
          />

          <SupplierCreateOffer
            show={this.state.opencreate}
            hide={this.closeCreate}
            id={this.props.details.id}
            onConfirmed={() => {
              this.setState({ isOfferFinished: true });
            }}
            type={this.props.details.request_type}
            cat={this.props.details.pre_request.category}
            keys={this.props.details.pre_request.keyword}
            // yanzoPrice={this.props.details.product?.yanzo_price}
            chosenProduct={
              Object.keys(this.props.details.product).length !== 0
                ? this.props.details.product
                : null
            }
            user={this.props.user}
          />

          <Modal show={this.state.showRej} onHide={this.closeRej}>
            <Container className="p-4">
              <Row>
                <Col sm={12}>
                  <p>Are you sure you want to reject this request?</p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Button
                    className="btn5"
                    variant="secondary"
                    onClick={this.closeRej}
                  >
                    Close
                  </Button>
                  <Button
                    className="btn3"
                    variant="primary"
                    onClick={this.Rejectoffer}
                  >
                    Sure
                  </Button>
                </Col>
                <Col sm={6}></Col>
              </Row>
            </Container>
          </Modal>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuth,
  email: state.auth.user.email,
  user: state.auth.user,
});
export default connect(
  mapStateToProps,
  null
)(ErrorHandler(OrderDetails, axiosApiInstance));
