import "../../style-sheets/chatFragment.css";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import sendIcon from "../../imgs/send.svg";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import {
  FileEarmarkImage as ImageIcon,
  FileEarmarkXFill as ImageCancelIcon,
  MicFill as MicIcon,
  MicMuteFill as MicCancelIcon,
  GeoAltFill as LocationIcon,
  CursorFill as SendIcon,
} from "react-bootstrap-icons";
import { Image } from "react-bootstrap";
import { message, Spin } from "antd";
import Microm from "microm";
import { resizeFile } from "../../firebase/imageResizers";

var microm = new Microm();

export default function InputFragment({ chatId, chatRepository }) {
  //Refrences
  const messageRef = useRef();

  //Redux

  //States
  const [files, setFiles] = useState([]);
  const [isRecording, setRecording] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [previewImage, setPreviewImages] = useState([]);
  const [stream, setStream] = useState();

  //Effects
  useEffect(() => {
    const promises = files.map((element) => {
      return resizeFile(element, 80);
    });

    Promise.all(promises).then((preview) => {
      setPreviewImages(preview);
    });
  }, [files]);

  //Functions
  function onSendClicked(e) {
    e.preventDefault();
    setLoading(files.length > 0);

    const messageText = messageRef.current
      ? messageRef.current.value.toString().trim()
      : "";
    chatRepository.sendNormalMessage(chatId, messageText, files).then(() => {
      setFiles([]);
      setLoading(false);
      if (messageRef.current) messageRef.current.value = "";
    });
  }

  function onFileChange(e) {
    onVoiceCancel(e);
    setFiles(Array.from(e.target.files));
  }

  //VOICE
  function onVoiceRecord(e) {
    e.preventDefault();
    setFiles([]);
    microm.record().then((s) => {
      setStream(s);
      setRecording(true);
    });
  }

  function onVoiceSend(e) {
    e.preventDefault();

    setLoading(true);

    microm.stop().then((voice) => {
      chatRepository.sendVoice(chatId, voice).then(() => {
        setLoading(false);
        setRecording(false);
        stream.getAudioTracks().forEach((track) => track.stop());
      });
    });
  }

  function onVoiceCancel(e) {
    e.preventDefault();

    if (isRecording) {
      microm.stop().then(() => {
        setRecording(false);
        stream.getAudioTracks().forEach((track) => track.stop());
      });
    }
  }

  //Location

  function onLocationClicked(e) {
    e.preventDefault();
    chatRepository.sendLocation(chatId);
  }

  const height = files.length > 0 ? "110px" : "0px";
  return (
    <>
      <div
        style={{
          display: "flex",
          overflow: "auto",
          minHeight: height,
          transition: "min-height 0.15s ease-out",
          paddingTop: "2px",
        }}
      >
        {previewImage.map((file) => {
          return (
            <img key={file.name} className="previewImages" src={file} rounded />
          );
        })}
      </div>

      <div>
        <Form
          style={{ width: "100%", overflow: "hidden" }}
          onSubmit={onSendClicked}
        >
          <div className="inputFragment">
            <div className="chatButtonsContainer">
              {files.length > 0 ? (
                <ImageCancelIcon
                  className="chatButtons"
                  color={"#FDC16A"}
                  onClick={() => {
                    setFiles([]);
                    setPreviewImages([]);
                  }}
                />
              ) : (
                <label
                  style={{
                    cursor: "pointer",
                    height: "100%",
                  }}
                  for="upload"
                >
                  <ImageIcon
                    style={{ position: "relative", top: "4px" }}
                    className="chatButtons"
                  />
                </label>
              )}
            </div>

            <div className="chatButtonsContainer">
              {isRecording ? (
                <MicCancelIcon
                  className="chatButtons"
                  onClick={onVoiceCancel}
                  color={"#FDC16A"}
                />
              ) : (
                <MicIcon className="chatButtons" onClick={onVoiceRecord} />
              )}
            </div>

            <div className="chatButtonsContainer">
              <LocationIcon
                className="chatButtons"
                onClick={onLocationClicked}
              />
            </div>

            {isRecording ? (
              <div className="messageTextField" style={{ color: "#7C8798" }}>
                <p
                  style={{
                    position: "relative",
                    bottom: "2px",
                    left: "10px",
                    cursor: "default",
                  }}
                >
                  {isLoading ? "Sending..." : "Recording..."}
                </p>
              </div>
            ) : (
              <>
                <input
                  ref={messageRef}
                  className="messageTextField"
                  placeholder="Type your message here..."
                  readOnly={isLoading}
                />
              </>
            )}

            {isLoading ? (
              <Spin style={{ margin: "3px", cursor: "not-allowed" }} />
            ) : (
              <div className="chatButtonsContainer">
                <Image
                  className="chatButtons noSelection"
                  src={sendIcon}
                  style={{
                    position: "relative",
                    left: "1.7px",
                    transform: "scale(1.07)",
                  }}
                  onClick={isRecording ? onVoiceSend : onSendClicked}
                />
              </div>
            )}

            <input
              id="upload"
              type="file"
              accept="image/*"
              multiple
              onChange={onFileChange}
              style={{
                visibility: "hidden",
                background: "#fff",
                maxWidth: "1px",
              }}
            />
          </div>
        </Form>
      </div>
    </>
  );
}
