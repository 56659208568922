export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const AGENT_REQUEST = "AGENT_REQUEST";

export const SUPPLIER_RES = "SUPPLIER_RES";
export const AGENT_CO_REQUEST = " AGENT_CO_REQUEST";
export const REQUEST_DATA = "REQUEST_DATA";
export const ORDER_TYPE = "ORDER_TYPE";
export const IS_QUICKUP = "IS_QUICKUP"
export const GET_AREAS = "GET_AREAS"

export const IS_DEMAND='IS_DEMAND'