import "../../style-sheets/chatFragment.css";
import React from "react";
import { ReceivedMessage, SentMessage } from "./Messages";
import { useState } from "react";
import firebaseObject from "../../firebase/firebaseObject";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { useEffect } from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import InputFragment from "./InputFragment";
import { Spin } from "antd";

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return Array.from(map, ([date, messages]) => ({ date, messages }));
}

function ChatConversation({
  chatId,
  chattingWithName,
  chatTitle,
  messages,
  chatSubTitle,
  chatRepository,
  checkSenderIsMe,
}) {
  const lastMessageRef = useRef();
  const authState = useSelector((state) => state.auth);
  const [isLoadingMore, setLoadingMore] = useState(false);
  console.log(authState);

  const datedMessages = groupBy(messages, (message) => {
    return message?.sentAt?.toDate().toLocaleDateString();
  });

  useEffect(() => {
    window.scrollTo(0, 100);
  }, []);

  useEffect(() => {
    if (isLoadingMore) {
      setTimeout(() => setLoadingMore(false), 1000);
    } else {
      if (lastMessageRef.current) {
        lastMessageRef.current.scrollIntoView({ smooth: true });
        window.scrollTo(0, 100);
      }
    }
  }, [lastMessageRef.current, messages]);

  return (
    <div className="chatContainer">
      <div className="chatHeader">
        {chatTitle && <h4>{chatTitle}</h4>}
        <p
          style={{
            color: "grey",
            fontSize: "16px",
            fontWeight: "normal",
          }}
        >
          {`${chattingWithName}`}
        </p>
        <p
          style={{
            color: "grey",
            fontSize: "16px",
            fontWeight: "normal",
          }}
        >
          {chatSubTitle}
        </p>
      </div>

      {messages.length > 0 ? (
        <div className="messagesList">
          <div>
            {datedMessages.map((val, i) => {
              const date = new Date(val.date);
              return (
                <div key={i}>
                  <div className="noSelection groupingDate">
                    {date instanceof Date && !isNaN(date)
                      ? date.toDateString()
                      : null}
                  </div>
                  {val.messages.map((messageItem) => {
                    const isFromMe = checkSenderIsMe(messageItem);

                    return isFromMe ? (
                      <SentMessage
                        key={messageItem.messageId}
                        message={messageItem}
                      />
                    ) : (
                      <ReceivedMessage
                        key={messageItem.messageId}
                        message={messageItem}
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div ref={lastMessageRef} />
        </div>
      ) : (
        <div className="noMessagesAlert">
          <p
            style={{
              padding: "10px 20px",
              background: "#E3D5E6",
              borderRadius: "20px",
              position: "relative",
              bottom: "5px",
            }}
          >
            {isLoadingMore ? "Loading...." : "No messages yet!"}
          </p>
        </div>
      )}

      <InputFragment chatId={chatId} chatRepository={chatRepository} />
    </div>
  );
}

// const INITIAL_MESSAGES_LIMIT = 100

export default function ChatFragment({
  chatId,
  messagesLoading,
  initialMessages,
  currentChat,
  chatLoading,
  chattingWithName,
  chatTitle,
  chatSubTitle,
  chatRepository,
  checkSenderIsMe,
}) {
  return (
    <div style={{ height: "100%" }}>
      {!chatLoading && currentChat ? (
        <ChatConversation
          chatId={chatId}
          chattingWithName={chattingWithName}
          chatTitle={chatTitle}
          messages={!messagesLoading && initialMessages ? initialMessages : []}
          setMessagesLimit={() => {}}
          chatSubTitle={chatSubTitle}
          chatRepository={chatRepository}
          checkSenderIsMe={checkSenderIsMe}
        />
      ) : (
        <div style={{ textAlign: "center", margin: "50px" }}>
          <Spin tip="Loading..." />
        </div>
      )}
    </div>
  );
}
