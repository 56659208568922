import React, { useState, useRef } from "react";

import { Container, Row, Col } from "react-bootstrap";
import "../../../../../style-sheets/products.css";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Form, Input, Button } from "antd";
import ErrorHandler from "../../../../../ErrorHandler/ErrorHandler";
import { APILINK } from "../../../../../Endpoint";
import { ToastContainer, toast } from "react-toastify";

const axiosApiInstance = axios.create();
function EditArea(props) {

    const onFinish = async (values) => {
        // return;
        let data = {
            name: values.name
        };
        Object.keys(data).forEach(key => {if (!data[key]) delete data[key]})
        const result = await axiosApiInstance
            .put(APILINK + `/area/${props.match.params.id}`, data)
            .catch((err) => {
                console.log(err.response);
            });
        console.log({ result })
        if (result) {
            setTimeout(() => {
                toast.info(`Area Edited Successfully`);
            }, 500);
            setTimeout(() => {
                props.history.push(`/all_areas/${props.match.params.emirateId}`);
            }, 3500);
        }
    };

    return (
        <Container>
            <Row>
                <Col className="p-4 mb-4" style={{ textAlign: "initial" }} sm={12}>
                    <h2>
                        {" "}
                        <i className="fas fa-truck pr-2"></i> Edit Emirate
                    </h2>
                </Col>
            </Row>
            <Form onFinish={onFinish} layout="vertical">
                <Row>
                    <Col sm="12" md="6">
                        <Form.Item
                            label="Name"
                            name="name"

                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col style={{ display: "flex", alignItems: "center" }}>
                        <Button className="addBtn2" htmlType="submit">
                            Submit
                        </Button>
                    </Col>
                </Row>


            </Form>
            <ToastContainer position="bottom-right" />
        </Container>
    );
}
export default ErrorHandler(EditArea, axiosApiInstance);
